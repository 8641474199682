import { useGetTermsAgreement, useGetTermsGroup } from '../../../react-query'
import { useEffect, useMemo, useState } from 'react'
import {
  selectOptionalMarketingTermsStatus,
  setOptionalMarketingTermsStatus,
} from '../../../store/settings'
import { useSelector } from 'react-redux'
import { store } from '../../../store'
import { DateTime } from 'luxon'

export type OptionalMarketingTermsItem = {
  termsCode: string,
  updateDate: string,
  count: number,
}

interface Props {
  isCarLifeTermsAgreed: boolean
}
function useOptionalTerms(props: Props) {
  const { isCarLifeTermsAgreed } = props

  const optionalMarketingTermsStatus = useSelector(selectOptionalMarketingTermsStatus)

  const [optionalTermsCode, setOptionalTermsCode] = useState<string>()

  const {
    data: optionalTermsAgreement,
    refetch: refetchOptionalTermsAgreement,
    error: optionalTermsAgreementError,
  } = useGetTermsAgreement({
    termsType: 'UBS01',
    termsAllowCode: optionalTermsCode || '',
    enabled: isCarLifeTermsAgreed && !!optionalTermsCode,
  })
  const { data: optionalTermsGroupData } = useGetTermsGroup({
    groupAppCode: 'TMAP',
    termsGroupCode: 'TMAP_TSCORE_OPTIONAL',
    useErrorBoundary: false,
  })

  const optionalTermsList: OptionalMarketingTermsItem[] = useMemo(() => {
    return optionalMarketingTermsStatus ? JSON.parse(optionalMarketingTermsStatus) : []
  }, [optionalMarketingTermsStatus])

  const optionalTerms = useMemo(() => {
    return optionalTermsList.find((item) => item.termsCode === optionalTermsCode)
  }, [optionalTermsList, optionalTermsCode])

  useEffect(() => {
    const targetCode = optionalTermsGroupData?.termsAgreeList.filter((item) => !item.parentTermsCode)[0]?.termsCode
    setOptionalTermsCode(targetCode)
  }, [optionalTermsGroupData])

  // 로컬스토리지에 저장된 업데이트 날짜(updateDate)보다 어드민에 설정한 개정일(targetRevisionDate)이 앞선 일자인 경우 해당 약관 로컬스토리지에서 초기화
  // targetRevisionDate 가 미래 날짜인 경우 초기화 되지 않도록 처리(TMAPWEB-2804)
  useEffect(() => {
    const now = DateTime.now().toFormat('yyyyMMdd')
    const { targetRevisionDate } = JSON.parse(optionalTermsGroupData?.additionalData || '{}')

    if (now < targetRevisionDate) return
    if (optionalTerms && (optionalTerms.updateDate < targetRevisionDate)) {
      const termsList = optionalTermsList.filter((item) => item.termsCode !== optionalTerms.termsCode)
      store.dispatch(setOptionalMarketingTermsStatus(JSON.stringify(termsList)))
    }
  }, [optionalTermsGroupData, optionalTermsList, optionalTerms])

  return {
    optionalTermsAgreement,
    refetchOptionalTermsAgreement,
    optionalTermsAgreementError,
    optionalTermsGroupData,
    optionalTermsCode,
    optionalTermsList,
    optionalTerms,
  }
}

export { useOptionalTerms }
