import React, { useCallback } from 'react'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'
import { SectionCard, ButtonTapHighlight, Trip } from '../../components'
import { useSelector } from 'react-redux'
import { selectSettingsEuk, selectSettingsIsCarLifeTermsAgreed } from '../../store/settings'
import { useOpenServiceUrl } from '../../hooks'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { TripsListTripType } from '@tmap-web-lib/remote-api-client/frontman'

interface Props {
  data: TripsListTripType | null
  eventLog: () => TmapLogBuilder
}

function MainRecentDriving(props: Props) {
  const { data, eventLog} = props

  const euk = useSelector(selectSettingsEuk)
  const isCarLifeTermsAgreed = useSelector(selectSettingsIsCarLifeTermsAgreed)
  const openServiceUrl = useOpenServiceUrl()

  const handleClickDetail = useCallback((tripInitSessionId: string) => {
    eventLog().set('action_id', 'tap.view_driving').send()
    TmapApp.recordEvent({ name: 'drivingscore_view_driving', json: { euk: euk } })
    openServiceUrl(`tmap://life?pageid=driving_history_detail&extra=%7B%22seq%22%3A%22${tripInitSessionId}%22%7D`)
  }, [openServiceUrl, eventLog, euk])

  const handleClickMore = useCallback(() => {
    eventLog().set('action_id', 'tap.view_alldriving').send()
    TmapApp.recordEvent({ name: 'drivingscore_view_alldriving', json: { euk: euk } })
    openServiceUrl('tmap://life?pageid=driving_history')
  }, [openServiceUrl, eventLog, euk])

  if (!data) return null

  return (
    <SectionCard
      title={'최근운전'}
      handleClickMore={handleClickMore}
      isLine
      isMore
    >
      {data && (
        <ButtonTapHighlight
          borderRadius={0}
          disabled={!isCarLifeTermsAgreed}
          onClick={() => handleClickDetail(data?.tripInitSessionId)}
        >
          <Trip
            tripSafeDrivingInfo={data}
            simple
          />
        </ButtonTapHighlight>
      )}
    </SectionCard>
  )
}

export { MainRecentDriving }
