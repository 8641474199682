import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { getSearchParams } from '@tmap-web-lib/utils'
import { useCallback } from 'react'
import Url from 'url-parse'
import { gte } from 'semver'
import { DialogOkCancel } from '../components'
import { ResponsiveLineBreak } from '../components/util'
import { useDialogManager } from '@tmap-web-lib-close/dialog-manager/react-router'
import { useLogBox } from './logbox'
import { APP_UPDATE_LOG_PRESET } from './logbox/preset'
import { Dictionary } from '@tmap-web-lib/utils/src/types'

function useOpenService() {
  const dialogManager = useDialogManager()
  const [eventLog, exposeLog] = useLogBox(['event', 'expose'], {
    attributesPreset: APP_UPDATE_LOG_PRESET.ATTRIBUTES,
    customFieldsPreset: APP_UPDATE_LOG_PRESET.CUSTOM_FIELDS,
    withEuk: true
  })

  return useCallback((srcUrl: string) => {
    if (!srcUrl) {
      return
    }

    const urlObject = new Url(srcUrl)
    const scheme = urlObject.protocol
    const serviceName = urlObject.hostname
    if (scheme.startsWith('tmap')) {
      if (srcUrl.includes('tmap://webview')) {
        if (gte(TmapApp.env.appVersion, '10.5.1')) {
          const searchParams = getSearchParams(urlObject.query || '')
          const params = new URLSearchParams(searchParams.params)
          const obj: Dictionary = {}

          for (const [key, value] of Array.from(params.entries())) {
            obj[key] = value
          }

          if (searchParams.service && searchParams.id) {
            TmapApp.openInAppBrowser({
              service: searchParams.service,
              id: searchParams.id,
              params: obj,
            })
          }
        } else {
          //  업데이트 팝업
          exposeLog().send()
          dialogManager.showDialog({
            component: DialogOkCancel,
            props: {
              title: ResponsiveLineBreak({ text: '서비스 이용을 위해\n앱을 업데이트 해주세요.' }),
              okText: '업데이트하기',
              cancelText: '닫기',
              btnBorderRadius: '0',
              gap: '0',
              onOk() {
                eventLog().set('action_id', 'tap.confirm_update').send()
                if (TmapApp.env.isAndroid) {
                  TmapApp.openBrowser({url: "https://play.google.com/store/apps/details?id=com.skt.tmap.ku"})
                } else {
                  TmapApp.openBrowser({url: "https://itunes.apple.com/kr/app/t-map-for-kt-lgu+/id473143250?mt=8" })
                }
              },
              onCancel() {
                eventLog().set('action_id', 'tap.close_update').send()
                return true
              }
            }
          })
        }
      } else {
        // 대리 9.19부터 openServiceByName 사용 가능. 버전 분기 처리 하지 않는 것으로 논의
        const params = getSearchParams(urlObject.query || '')
        TmapApp.openServiceByName({ serviceName, jsonData: params })
      }
    } else {
      TmapApp.openBrowser({ url: srcUrl })
    }
  }, [dialogManager, eventLog, exposeLog])
}

export { useOpenService }
