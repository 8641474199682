import { Point } from '@vsm/vsm'
import proj4 from 'proj4'

function define(code: string, wkt: string, force: boolean = false): boolean {
  if (!force && isDefined(code)) {
    return true
  }

  try {
    proj4.defs(code, wkt)
    return true
  } catch (e) {
    return false
  }
}

function isDefined(code: string): boolean {
  try {
    proj4.Proj(code)
    return true
  } catch (e) {
    return false
  }
}

function prepareTransform(sourceCode: string, targetCode: string) {
  const sourceProj = proj4.Proj(sourceCode)
  const targetProj = proj4.Proj(targetCode)

  return (coordinate: Point): Point => {
    const { x, y } = proj4.transform(sourceProj, targetProj, [coordinate.x, coordinate.y])
    return { x, y }
  }
}

const SKT_WKT = `GEOGCS["Korean 1985",
DATUM["Korean_Datum_1985",
    SPHEROID["Bessel 1841",6377397.155,299.1528128,
        AUTHORITY["EPSG","7004"]],
    TOWGS84[-115.80,474.99,674.11,1.16,-2.31,-1.63,6.43],
    AUTHORITY["EPSG","6162"]],
PRIMEM["Greenwich",0,
    AUTHORITY["EPSG","8901"]],
UNIT["degree",0.0174532925199433,
    AUTHORITY["EPSG","9122"]],
AUTHORITY["EPSG","4162"]]`

define('EPSG:4162', SKT_WKT)

export const epsg4162ToEpsg4326 = prepareTransform('EPSG:4162', 'EPSG:4326')

// export const convertSKTtoWGS = (point: Point) => {
//   const converted = proj4(SKT_WKT, 'EPSG:4326', [point.x, point.y])
//   return { x: converted[0], y: converted[1] }
// }
