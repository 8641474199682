import { formatCommaNumber } from '@tmap-web-lib/utils'

function formatIntegerKilometers(value: number) {
  return formatCommaNumber(Number(Math.floor(value / 1000)))
}

function formatFloorKilometers(value: number) {
  if (value >= 1000) {
    return `${formatCommaNumber(Number((Math.floor(value / 1000 * 10) / 10).toFixed(1)))}km`
  } else {
    return `${value.toFixed(0)}m`
  }
}

function formatNumericFloorKilometers(value: number) {
  if (value < 1000) return 0
  return Number((Math.floor(value / 1000 * 10) / 10).toFixed(1))
}

function formatMileage(value: number) {
  if (value >= 10000) {
    return `${formatCommaNumber(Number((Math.floor(value / 10000 * 100) / 100).toFixed(1)))}만km`
  } else {
    return `${formatCommaNumber(value)}km`
  }
}

export {
  formatFloorKilometers,
  formatIntegerKilometers,
  formatNumericFloorKilometers,
  formatMileage
}
