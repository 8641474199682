import { useMutation, useQueryClient } from '@tanstack/react-query'
import { registerUsedFavoriteRoute, UsedFavoriteRouteInfo, EscApiBody } from '@tmap-web-lib/remote-api-client/frontman'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'

function useRegisterUsedFavoriteRoute() {
  const queryClient = useQueryClient();

  return useMutation<EscApiBody, AxiosError, UsedFavoriteRouteInfo[]>(
    (data) => registerUsedFavoriteRoute(data).then(({data}) => data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([KEYS.TRIP_DRIVING_DETAIL_INFO])
      }
    }
  )
}

export { useRegisterUsedFavoriteRoute }
