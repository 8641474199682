import { HTMLAttributes, PropsWithChildren, useCallback, useEffect, useRef } from 'react'
import { UAParser } from 'ua-parser-js'
import { compare } from 'compare-versions'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLCanvasElement>>{
  score: number
  width: number
  height: number
  stroke: number
  isReady: boolean
  isAnimation?: boolean
}
function ScoreChart(props: Props) {
  const { score, width, height, stroke, isReady, isAnimation, ...attr } = props

  const ref = useRef<HTMLCanvasElement | null>(null)

  const draw = useCallback(() => {
    if (!ref.current || !isReady) return
    const parser = new UAParser()
    const parserResults = parser.getResult()
    let isMinusBrowser = false

    if (
      (parserResults.browser.name?.match('Chrome WebView') && (parserResults.browser.version && compare(parserResults.browser.version, '99.0.0', '<')))
      || (parserResults.os.name?.match('iOS') && (parserResults.os.version && compare(parserResults.os.version, '16.3.1', '<')))
    ) {
      isMinusBrowser = true
    }

    // createConicGradient 적용 시 첫번째 색상이 마지막에 한 더 있어야 자연스럽게 그라데이션 적용 됨.
    const COLORS = ["rgb(237, 78, 240, 1)", "rgb(10, 202, 202, 1)", "rgb(62, 110, 255, 1)", "rgb(151, 108, 251, 1)", "rgb(237, 78, 240, 1)", ]
    const x = width,
      y = height,
      lineWidth = stroke,
      radius = x - (lineWidth / 2), // 가로 또는 세로 넓이에서 lineWidth 굵기 / 2를 뺀 값
      angleStart = 135,
      angleEnd = 45,
      size = x * 2, // 디바이스 해상도 대응
      scoreAngleEnd = ((Math.PI / 180) * (angleStart + ((angleStart * 2) * (score / 100))))
    const canvas = ref.current!
    const ctx = canvas.getContext("2d")!
    const dpr = window.devicePixelRatio
    const gradient = isMinusBrowser ? null : ctx.createConicGradient(0.7, x, y)
    let drawAngleEnd = (Math.PI / 180) * angleStart
    let currentAngle
    let timer

    canvas.style.width = `${size}px`
    canvas.style.height = `${size}px`
    canvas.width =  size * dpr
    canvas.height = size * dpr

    // 그라데이션 색상 적용
    if (gradient) {
      for (let i = 0; i < COLORS.length; ++i) {
        gradient.addColorStop(i / (COLORS.length - 1), COLORS[i])
      }
    }

    // 바탕 테두리
    ctx.scale(dpr, dpr)
    ctx.beginPath()
    ctx.arc(x, y, radius, (Math.PI / 180) * angleStart, (Math.PI / 180) * angleEnd, false)
    ctx.lineWidth = lineWidth
    ctx.lineCap = 'round'
    ctx.strokeStyle = 'rgba(224, 226, 232, 1)'
    ctx.stroke()
    ctx.closePath()

    const drawAnimate = () => {
      ctx.beginPath()
      ctx.arc(x, y, radius, (Math.PI / 180) * angleStart, isAnimation? drawAngleEnd : scoreAngleEnd, false)
      ctx.strokeStyle = gradient ?? 'rgb(0, 100, 255, 1)'
      ctx.stroke()
      currentAngle = ((scoreAngleEnd - drawAngleEnd) * 0.1).toFixed(3)
      drawAngleEnd += Number(currentAngle)

      if (!isAnimation) return
      timer = requestAnimationFrame(drawAnimate)
      // Math.PI로 계산하면 소숫점 자리수 문제로 세 자리까지만 적용
      if (currentAngle === '0.000') {
        cancelAnimationFrame(timer)
      }
    }
    if (score > 0) {
      drawAnimate()
    }
  }, [score, width, height, stroke, isReady, isAnimation])

  useEffect(() => {
    draw()
  }, [draw])

  return (
    <canvas ref={ref} {...attr} />
  )
}

export { ScoreChart }
