import { CSSTransition } from 'react-transition-group'
import { ButtonTapHighlight } from '../../components'
import { Player } from '@lottiefiles/react-lottie-player'
import BenefitJson from '../../assets/images/lottie/ani_score_benefit.json'
import React, { useMemo, useRef } from 'react'
import { DateTime } from 'luxon'
import { random } from 'lodash-es'
import { RedddotListData } from '@tmap-web-lib/remote-api-client/frontman'
import { tmapDateStringToDateTime } from '../../utils'
import IconNew from '../../assets/images/NewBadge.svg'

interface Props {
  data: RedddotListData | undefined;
  handleClick: () => void;
}

function MainBenefitPopular({ data, handleClick }: Props) {
  const ref = useRef(null)

  const benefitTooltip = useMemo(() => {
    const tooltips = data?.data.badges.filter((data) => {
      if (data.item === 'BENEFIT_TOOLTIP' && data.displayType === 'TOOLTIP') {
        const { startDateTime, expiredDateTime } = data
        const now = DateTime.now()
        if ((now >= tmapDateStringToDateTime(startDateTime)) && (now <= tmapDateStringToDateTime(expiredDateTime))) {
          return data
        }
      }
      return null
    })
    if (tooltips && tooltips.length > 0) {
      const num = random(tooltips.length - 1)
      return tooltips[num]
    }
  }, [data])

  return (
    <CSSTransition
      classNames={'bottom-full-slide-up'}
      unmountOnExit={true}
      timeout={300}
      in={true}
      nodeRef={ref}
      appear
    >
      <div
        className="main_benefit_popular"
        ref={ref}
      >
        <ButtonTapHighlight
          className="main_benefit_popular_link"
          isBeforePseudoElement
          onClick={handleClick}
        >
          <Player
            className="main_benefit_popular_ico"
            src={BenefitJson}
            loop={true}
            autoplay
          />
          운전점수 인기 혜택
        </ButtonTapHighlight>
        {benefitTooltip && (
          <em className="main_benefit_popular_tooltip">
            {benefitTooltip.message}
            <IconNew />
          </em>
        )}
      </div>
    </CSSTransition>
  )
}

export { MainBenefitPopular }
