import { useQuery } from '@tanstack/react-query'
import { BenefitCategories,getBenefitCategories } from '@tmap-web-lib/remote-api-client/frontman'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'

interface Options {
  useErrorBoundary?: boolean
}

function useGetBenefitCategories(options?: Options) {
  return useQuery<BenefitCategories[], AxiosError>([KEYS.BENEFIT_CATEGORIES()],
    () => getBenefitCategories().then(({ data }) => data),
    {
      useErrorBoundary: options?.useErrorBoundary ?? true
    }
  )
}

export { useGetBenefitCategories }
