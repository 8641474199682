import React, { useEffect, useMemo, useState } from 'react'
import defer from 'lodash-es/defer'

export interface DiscountAnimateProps {
  targetNumber: string;
  index: number;
}

function MainUserStatusDiscountAnimate(props: DiscountAnimateProps) {
  const { targetNumber, index } = props

  const numberList = useMemo(() => {
    const num = Number(targetNumber), len = 10, list = []
    let cnt = len
    while(cnt--) list.push(cnt ? (num - cnt + len)%10 : num)
    return list
  }, [targetNumber])

  const [animateTop, setAnimateTop] = useState(0)

  useEffect(() => {
    defer(() => {
      setAnimateTop(9 * -52)
    })
  }, [])

  return (
    <span className="main_score_rolling">
      <span
        className="main_score_num"
        style={{ transitionDuration: (2.2 - (index * 0.2)) + 's', top: animateTop + 'px' }}
      >
        {numberList.map((item) => (
          <React.Fragment key={item}>
            {item}<br/>
          </React.Fragment>
        ))}
      </span>
    </span>
  )
}

export { MainUserStatusDiscountAnimate }
