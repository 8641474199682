import { useEffect, useState } from 'react'
import { LoginMethod, TmapApp } from '@tmap-web-lib/tmap-app-interface'

function useGetLoginMethod() {
  const [loginMethod, setLoginMethod] = useState<LoginMethod | null>(null)

  useEffect(() => {
    TmapApp.getLoginMethod().then((loginMethod) => {
      setLoginMethod(loginMethod)
    })

  }, [])

  return { loginMethod }
}

export { useGetLoginMethod }