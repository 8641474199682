import { useCallback } from 'react'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { useOpenShareLink } from './use-open-share-link'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'
import { useShortenerUrl } from '../react-query/hooks/use-shortener-url'

interface Props {
  title: string
  body?: string
  img: string
  pageId: string
  thumbTitle?: string
  actionId?: string
  eventLog: () => TmapLogBuilder
}

function useShare() {
  const openShareLink = useOpenShareLink()
  const { mutate } = useShortenerUrl()

  return useCallback((props: Props) => {
    const {
      title,
      body,
      img,
      pageId,
      thumbTitle,
      actionId,
      eventLog
    } = props
    const extra = {
      is_first_history: true,
      logbox: {
        type: 'share',
        origin: 'share'
      }
    }
    const schemeHost = `?scheme=tmap&host=life&pageid=${pageId}&extra=${encodeURIComponent(JSON.stringify(extra))}`;
    const shareTitle = `&shareTitle=${encodeURIComponent(thumbTitle ?? title)}`
    const shareImage = `&shareImage=${encodeURIComponent(`${process.env.REACT_APP_CDN_URL}/life-tab/image/${img}`)}`

    mutate({
      apiKey: `${process.env.REACT_APP_SHORTENER_API_KEY}`,
      routeCd: `${process.env.REACT_APP_SHORTENER_ROUTE_CD}`,
      orgUrl: `${process.env.REACT_APP_BRIDGE_URL}tmap2/mobile/tmap.jsp${schemeHost}${shareTitle}${shareImage}`
    }, {
      onSuccess: (data) => {
        eventLog().set('action_id', actionId ?? 'tap.share').send()
        if (data.successYN === 'Y') {
          openShareLink({
            url: data.shortUrl,
            title: title,
            body: body || '',
            imageUrl: img,
          }, { useBridge: false })
        } else {
          TmapApp.makeToast({ msg: '오류가 발생했습니다.\n잠시 후 다시 시도해주세요.' })
        }
      },
      onError: () => {
        TmapApp.makeToast({ msg: '오류가 발생했습니다.\n잠시 후 다시 시도해주세요.' })
      }
    })
  }, [mutate, openShareLink])
}

export { useShare }