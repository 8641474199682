import { CoreConfig, RequestConfig } from './common'
import { AxiosConfig, initAxios, mockHost } from './axios'
import { AxiosError, AxiosInstance } from 'axios'
import { isUndefined } from 'lodash-es'

type DrivingLifeHeaders = {
  'service'?: string
  'authorization'?: string
  'deviceId'?: string
  'os'?: string
  'appversion'?: string
}

type ClientConfig = Omit<CoreConfig, 'axiosList' | 'onAccessKeyExpired' | 'onAccessKeyChanged'> & AxiosConfig & {
  headers?: DrivingLifeHeaders
  onAccessKeyChanged: (newAccessKey: string) => Promise<any> | void
  onAccessKeyExpired?: (error: AxiosError, axios: AxiosInstance) => Promise<void> | void
  onError?: (error: AxiosError, axios: AxiosInstance) => Promise<any> | void
}

function initMockClient({
  onAccessKeyChanged,
  onAccessKeyExpired,
  onRequest = async (config) => config,
  onResponse,
  onError,
  ...envConfig
}: ClientConfig) {
  initAxios(envConfig)

  mockHost.interceptors.request.use((config) => {
    config.headers = config.headers || {}
    return onRequest(config as RequestConfig, mockHost)
  })

  mockHost.interceptors.response.use(async (response) => {
    const { headers } = response
    const newAccessKey = headers['ak-refresh']
    if (newAccessKey) {
      await onAccessKeyChanged(newAccessKey)
    }
    return onResponse ? onResponse(response, mockHost) : response
  }, async (error) => {
    if (onAccessKeyExpired && error.response?.status === 401) {
      const result = await onAccessKeyExpired(error, mockHost)
      return isUndefined(result) ? Promise.reject(error) : result
    }

    if (onError) {
      const result = await onError(error, mockHost)
      return isUndefined(result) ? Promise.reject(error) : result
    }
    return Promise.reject(error)
  })
}

export { initMockClient }
