import { RoutePath } from '../../routes'
import { PageId, PageIdInfo } from './types'

export const pageIdInfos: { [Key in PageId]: PageIdInfo<Key>; } = Object.freeze({
  main: {
    pageId: 'main',
    url: RoutePath.Home,
    name: '운전점수 메인',
  },
  benefit: {
    pageId: 'benefit',
    url: RoutePath.Benefit,
    name: '혜택',
  },
  benefit_popular: {
    pageId: 'benefit_popular',
    url: RoutePath.Benefit_popular,
    name: '인기혜택',
  },
  benefit_detail: {
    pageId: 'benefit_detail',
    url: RoutePath.Benefit_detail,
    name: '혜택 상세(보험)',
  },
  insur_vehicle_all: {
    pageId: 'insur_vehicle_all',
    url: RoutePath.Benefit,
    name: '자동차 보험',
  },
  driving_analysis: {
    pageId: 'driving_analysis',
    url: RoutePath.Driving_analysis,
    name: '운전분석'
  },
  driving_history: {
    pageId: 'driving_history',
    url: RoutePath.History,
    name: '운전이력'
  },
  driving_history_detail: {
    pageId: 'driving_history_detail',
    url: RoutePath.History_detail,
    name: '운전이력상세'
  },
  vehicle: {
    pageId: 'vehicle',
    url: RoutePath.Vehicle,
    name: '내 차 리포트'
  },
  vehicle_setting: {
    pageId: 'vehicle_setting',
    url: RoutePath.Vehicle_setting,
    name: '내 차 선택'
  },
  vehicle_detail: {
    pageId: 'vehicle_detail',
    url: RoutePath.Vehicle_detail,
    name: '내 차 리포트 상세'
  },
  etc_insurer_contact: {
    pageId: 'etc_insurer_contact',
    url: RoutePath.Etc_insurer_contact,
    name: '보험사 연락처'
  },
  etc_emergency_call: {
    pageId: 'etc_emergency_call',
    url: RoutePath.Etc_emergency_call,
    name: '긴급 호출'
  },
  etc_terms: {
    pageId: 'etc_terms',
    url: RoutePath.Etc_terms,
    name: '운전점수 약관'
  },
  settings: {
    pageId: 'settings',
    url: RoutePath.Settings,
    name: '운전점수 셋팅 페이지'
  },
  settings_otp: {
    pageId: 'settings_otp',
    url: RoutePath.Settings_otp,
    name: 'OTP'
  },
  settings_withdrawal: {
    pageId: 'settings_withdrawal',
    url: RoutePath.Settings_withdrawal,
    name: '운전점수 탈퇴 페이지'
  },
  unpaid_toll: {
    pageId: 'unpaid_toll',
    url: RoutePath.Unpaid_toll,
    name: '미납통행료 내역'
  },
  unpaid_toll_bridge: {
    pageId: 'unpaid_toll_bridge',
    url: RoutePath.unpaid_toll_bridge,
    name: '미납통행료 유비페이 브릿지'
  },
  unpaid_toll_result : {
    pageId: 'unpaid_toll_result',
    url: RoutePath.unpaid_toll_result,
    name: '미납통행료 내역'
  }
})
