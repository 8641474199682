import React, { useCallback, useRef, useState } from 'react'
import { DialogBackground, DialogBottomSheet } from './Elements'
import { DialogBaseProps } from './types'
import { CSSTransition } from 'react-transition-group'
import { ButtonTapHighlight } from '../Button'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import checkOn from '../../assets/images/check_icon_on.svg'

export type SelectListType = {
  id: string
  label: string
  selected: boolean
}

interface Props extends DialogBaseProps {
  title?: string
  selectList: SelectListType[]
  onSelect(date: string): void
}
function DrivingSelect(props: Props) {
  const { title, selectList, onSelect } = props

  const ref = useRef(null)
  const backgroundRef = useRef(null)
  const [isOpen, setOpen] = useState(true)

  const handleSelect = useCallback((id: string) => {
    onSelect(id)
    setOpen(false)
  }, [onSelect])

  return (
    <CSSTransition
      classNames="dimmed-fade-in"
      unmountOnExit={true}
      timeout={300}
      in={isOpen}
      nodeRef={backgroundRef}
      appear
    >
      <DialogBackground ref={backgroundRef}>
        <CSSTransition
          classNames="bottom-slide-up"
          unmountOnExit={true}
          timeout={200}
          in={isOpen}
          nodeRef={ref}
          appear
        >
          <DialogBottomSheet
            ref={ref}
            title={title}
          >
            <Select>
              {selectList.map((option) => {
                return (
                  <SelectOption
                    key={option.id}
                    active={option.selected}
                  >
                    <ButtonTapHighlight
                      isBeforePseudoElement={true}
                      className="modal_select_option_btn"
                      onClick={() => handleSelect(option.id)}
                    >
                      {option.label}
                    </ButtonTapHighlight>
                  </SelectOption>
                )
              })}
            </Select>
          </DialogBottomSheet>
        </CSSTransition>
      </DialogBackground>
    </CSSTransition>
  )
}

export default DrivingSelect
export { DrivingSelect }

const Select = styled.ul`
  overflow-y: auto;
`

const SelectOption = styled.li<{ active: boolean }>`
  font-size: var(--font-size-18);
  
  & > button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    min-height: 56px;
    padding: 0 20px;
    
    ${({ active }) => active && css`
      color: var(--C-primary-500);
      font-weight: var(--font-weight-medium);
      
      &::after {
        content: '';
        width: 28px;
        height: 28px;
        background: url(${checkOn}) no-repeat 0 0 / 100% 100%;
      }
    `
  }
`
