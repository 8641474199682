import React, { useCallback, useEffect, useRef, useState } from 'react'
import { DialogBackground, DialogCancelButton, DialogContent, DialogFooter, DialogOkButton } from './Elements'
import { DialogWithAnimationProps } from './types'
import { classNames } from '@tmap-web-lib/utils'
import { CSSTransition } from 'react-transition-group'
import { useLogBox, useOpenService } from '../../hooks'
import { DRIVING_ANALYSIS_LOG_PRESET } from '../../hooks/logbox/preset'
import { CS_FAQ_URL } from '../../utils'

function DialogDrivingGuide(props: DialogWithAnimationProps) {
  const {
    wrapperClassName,
    title,
    onCancel,
    onOk
  } = props

  const openService = useOpenService()
  const [eventLog] = useLogBox(['event'], {
    attributesPreset: DRIVING_ANALYSIS_LOG_PRESET.ATTRIBUTES,
    customFieldsPreset: DRIVING_ANALYSIS_LOG_PRESET.CUSTOM_FIELDS,
    withEuk: true,
  })

  const ref = useRef<HTMLDivElement>(null)
  const backgroundRef = useRef(null)
  const [modalIsOpen, setModalIsOpen] = useState(true)
  const [hasOverflow, setOverflow] = useState(false)

  const handleFaq = useCallback(() => {
    eventLog().set('action_id', 'tap.faq_help').send()
    openService(CS_FAQ_URL)
    onCancel?.()
  }, [eventLog, openService, onCancel])

  const handleConfirm = useCallback(() => {
    setModalIsOpen(false)
    onOk?.()
  }, [onOk])

  useEffect(() => {
    /*
      - 팝업 또는 모달에 내부 스크롤이 있을 경우 body 스크롤을 막지 못하는 이슈 스크립트 처리
      - 추후 공통으로 팝업이 열릴 경우 body overflow: hidden, position: fixed와 스크롤된 값으로 처리
      - 티켓 TMAPWEB-1245 참고
   */
    if (!ref.current) return
    const intervalHeight = 96 // 상단 최소 여백
    const popupHeight = ref.current.offsetHeight
    const bodyHeight = document.body.offsetHeight

    setOverflow(bodyHeight <= Math.ceil(intervalHeight + popupHeight))
  }, [])

  return (
    <CSSTransition
      classNames="dimmed-fade-in"
      unmountOnExit={true}
      timeout={300}
      in={modalIsOpen}
      nodeRef={backgroundRef}
      appear
    >
      <DialogBackground className={wrapperClassName} ref={backgroundRef}>
        <CSSTransition
          classNames="bottom-slide-up"
          unmountOnExit={true}
          timeout={200}
          in={modalIsOpen}
          nodeRef={ref}
          appear
        >
          <div ref={ref} className={classNames('popup_wrap', 'popup_bottom', 'popup_guide')}>
            <div className={classNames('popup_cont')} style={{ overflowY: hasOverflow ? 'auto' : 'hidden'}}>
              <DialogContent
                title={title}
                content={
                  <div className="guide">
                    <p className="guide_desc">목적지 설정 후 길안내 받은 운전데이터를 기반으로 운전에 대해서 분석해드립니다. 길안내 중 경로 이탈이나 중간에 운행을 종료하더라도 GPS 신호가 잡힌 상태라면 운전데이터가 기록되니 안심하고 이용하세요.</p>
                    <dl className="guide_list">
                      <dt className="guide_list_tit">운전점수</dt>
                      <dd className="guide_list_desc">최근 주행(3,000km)에 대한 운전을 분석하여 운전점수를 계산해요. 과속, 급감/가속을 주요 감점요인으로, 목적지로의 길안내가 종료되면 운전점수가 실시간으로 반영됩니다.</dd>
                      <dt className="guide_list_tit">운전 데이터 기준</dt>
                      <dd className="guide_list_desc">과속 : 제한속도 15km/h 초과<br />급가속 : 3초 이내 30km/h 이상의 가속<br />급감속 : 3초 이내 30km/h 이상의 감속</dd>
                    </dl>
                  </div>
                }
              />
            </div>
            <DialogFooter
              cancelButton={<DialogCancelButton onClick={handleFaq}>자주하는 질문</DialogCancelButton>}
              okButton={<DialogOkButton gap={'8px'} onClick={handleConfirm} />}
            />
          </div>
        </CSSTransition>
      </DialogBackground>
    </CSSTransition>
  )
}

export default DialogDrivingGuide
export { DialogDrivingGuide }
