import React, { Suspense, useLayoutEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import {
  RoutePath,
  BenefitPageList,
  DrivingPageList,
  VehicleSubPageList,
  HistoryPageList, EtcPageList, SettingsPageList,
} from './paths'
import { SwitchBenefitPages } from './SwitchBenefitPages'
import { SwitchVehicleSubPages } from './SwitchVehiclePages'
import { lazyRetry } from '../utils/lazy-retry'
import { store } from '../store'
import { setIsSkeleton } from '../store/settings'
import { SwitchHistoryPages } from './SwitchHistoryPages'
import { SwitchEtcPages } from './SwitchEtcPages'
import {SwitchSettingsPages} from './SwitchSettingsPages'

const Main = lazyRetry(() => import('../pages/Main/Main'))
const Driving = lazyRetry(() => import('../pages/Driving/Driving'))

function SwitchPages() {
  useLayoutEffect(() => {
    if (window.location.pathname === '/web') {
      store.dispatch(setIsSkeleton(true))
    }
  },[])

  return (
    <Suspense fallback="">
      <Switch>
        <Route path={RoutePath.Home} exact>
          <Main/>
        </Route>
        <Route path={EtcPageList} exact>
          <SwitchEtcPages/>
        </Route>
        <Route path={BenefitPageList} exact>
          <SwitchBenefitPages/>
        </Route>
        <Route path={DrivingPageList} exact>
          <Driving/>
        </Route>
        <Route path={VehicleSubPageList} exact>
          <SwitchVehicleSubPages/>
        </Route>
        <Route path={HistoryPageList} exact>
          <SwitchHistoryPages/>
        </Route>
        <Route path={SettingsPageList} exact>
          <SwitchSettingsPages />
        </Route>
        <Redirect to={RoutePath.Home} from="*"/>
      </Switch>
    </Suspense>
  )
}

export { SwitchPages }
