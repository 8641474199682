import { useInfiniteQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'
import { getTrips, TripsListReqParams, TripsListResData } from '@tmap-web-lib/remote-api-client/frontman'
import { mockHost } from '../../api'
import { useSelector } from 'react-redux'
import { selectSettingsMockEnabled } from '../../store/settings'

interface Props extends TripsListReqParams {
  enabled?: boolean
}

function useInfiniteTripSafeDrivingInfo(props: Props) {
  const isMockEnabled = useSelector(selectSettingsMockEnabled)

  return useInfiniteQuery<TripsListResData, AxiosError>(
    [KEYS.TRIP_SAFE_DRIVING_INFO(props.createdAt, props.lastMonth, props.nextToken)],
    async ({ pageParam = '' }) => {
      if (JSON.parse(isMockEnabled as string)) {
        const { data } = await mockHost.get<TripsListResData>('/ubds/trip/v1/trips')
        return data
      } else {
        const { data } = await getTrips({ ...props, nextToken: encodeURIComponent(pageParam) })
        return data
      }
    },
    {
      enabled: props.enabled,
      getNextPageParam: (lastPage) => {
        if (!lastPage) return undefined
        if (lastPage.page.nextToken) {
          return lastPage.page.nextToken
        }
        return undefined
      },
    },
  )
}

export { useInfiniteTripSafeDrivingInfo }
