import React, { ButtonHTMLAttributes, PropsWithChildren } from 'react'
import { Button } from '../../Button'

interface Props extends PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> {
  borderRadius?: string
}

function DialogCancelButton(props: Props) {
  const { borderRadius, children, ...restProps } = props
  return (
    <Button
      {...restProps}
      variant="default"
      styleHeight={52}
      borderRadius={borderRadius}
    >
      {children ?? '취소'}
    </Button>
  )
}

export { DialogCancelButton }
