import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'
import {
  MonthlyReportsResponseData,
  getMonthlyReports,
} from '@tmap-web-lib/remote-api-client/frontman'
import { mockHost } from '../../api'
import { useSelector } from 'react-redux'
import { selectSettingsMockEnabled } from '../../store/settings'

interface Params {
  lastMonth?: number
}

function useGetDrivingScoreMonthlyReports(params?: Params) {
  const isMockEnabled = useSelector(selectSettingsMockEnabled)

  return useQuery<MonthlyReportsResponseData, AxiosError>([KEYS.DRIVING_SCORE_MONTHLY_REPORTS()],
    async () => {
      if (JSON.parse(isMockEnabled as string)) {
        const { data } = await mockHost.get<MonthlyReportsResponseData>('/ubds/trip-report/v1/monthly-reports/$userKey')
        return data
      } else {
        const { data } = await getMonthlyReports({ lastMonth: params?.lastMonth || 12 })
        return data
      }
    },
  )
}

export { useGetDrivingScoreMonthlyReports }
