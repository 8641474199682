import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'
import { RootState } from '../index'
import { AxiosError } from 'axios'

type AppState = {
  error: AxiosError | null;
  tts: {
    isPlaying: boolean,
    productId: string,
  };
  response: any | null;
}

interface Actions extends SliceCaseReducers<AppState> {
  setError: (state: AppState, action: PayloadAction<AxiosError>) => void;
  clearError: (state: AppState) => void;
  setTTS: (state: AppState, action: PayloadAction<{ productId: string }>) => void;
  setResponse: (state: AppState, action: PayloadAction<any>) => void;
}

const appSlice = createSlice<AppState, Actions>({
  name: 'app',
  initialState: {
    error: null,
    tts: {
      isPlaying: false,
      productId: '',
    },
    response: null,
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload
    },
    clearError: (state) => {
      state.error = null
    },
    setTTS: (state, action) => {
      const { productId } = action.payload
      state.tts.productId = productId
      state.tts.isPlaying = !!productId
    },
    setResponse(state, action) {
      state.response = action.payload
    },
  },
})

export { appSlice }

export const {
  setError,
  clearError,
  setTTS,
  setResponse,
} = appSlice.actions

export const selectAppError = (state: RootState) => state.app.error
