import { AxiosError } from 'axios'
import mixpanel from 'mixpanel-browser'
import { datadogRum } from '@datadog/browser-rum'

function trackErrorLogs(error: AxiosError, message?: string, custom?: any) {
  mixpanel.track(message ?? 'tscore:error:onError', { error: error, custom: custom })
  datadogRum.addError(error, {
    error: {
      ...error,
      request: error.request.response,
      message: message,
      custom
    },
  })
}

export { trackErrorLogs }