import React, { PropsWithChildren, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { classNames } from '@tmap-web-lib/utils'
import { ButtonTapHighlight } from '../Button'

interface ExpandProps {
  header: ReactNode
  isFocus?: boolean
  onClick?: () => void
}

function Accordion(props: PropsWithChildren<ExpandProps>) {
  const { header, isFocus, onClick, children } = props
  const ref = useRef<HTMLDivElement>(null)
  const [isExpand, setIsExpand] = useState(false)

  const onFocus = useCallback(() => {
    if (!ref.current) return
    const element = ref.current
    const headerOffset = 56
    const elementPosition = element.getBoundingClientRect().top
    const offsetPosition = elementPosition + window.scrollY - headerOffset
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    })
  }, [])

  const handleClickExpand = useCallback((isOpen: boolean) => {
    setIsExpand(isOpen)
    onClick?.()
  }, [onClick])

  useEffect(() => {
    if (isExpand) {
      onFocus()
    }
  }, [isExpand, onFocus])

  useEffect(() => {
    if (isFocus) {
      handleClickExpand(true)
    }
  }, [isFocus, handleClickExpand])

  return (
    <div
      ref={ref}
      className="component_accordion"
    >
      <ButtonTapHighlight
        className={classNames('component_accordion_title', isExpand ? 'active' : '')}
        borderRadius={0}
        onClick={() => handleClickExpand(!isExpand)}
      >
        {header}
      </ButtonTapHighlight>

      {isExpand && (
        <div className="component_accordion_content">
          {children}
        </div>
      )}
    </div>
  )
}

export { Accordion }
