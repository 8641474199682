import { useCallback, useMemo } from 'react'
import { useGetBenefitCategories, useGetBenefitList } from '../../../react-query'
import {
  BenefitCategories,
  BenefitList
} from '@tmap-web-lib/remote-api-client/frontman'
import { shuffle, sortBy } from 'lodash-es'

export type BenefitDataType = 'MAIN' | 'BENEFIT_TAB' | 'BENEFIT_PAGE'
export interface BenefitListDataType extends BenefitCategories {
  benefitItem: BenefitList[]
}
type getFilteredCategoriesOptions = {
  categoryDepth?: number
  targetCategoryId?: string
  isMainCategory?: boolean
}

interface Props {
  type: BenefitDataType
  targetCategoryId?: string
  useErrorBoundary?: boolean
}

function useGetBenefitData(props: Props) {
  const { type, targetCategoryId, useErrorBoundary } = props
  const { data: categoryData, isInitialLoading: isCategoryLoading } = useGetBenefitCategories({ useErrorBoundary: useErrorBoundary ?? true })
  const { data: benefitData, isInitialLoading: isBenefitLoading } = useGetBenefitList({ categoryId: '', useErrorBoundary: useErrorBoundary ?? true })

  const getFilteredCategories = useCallback((options?: getFilteredCategoriesOptions) => {
    if (!categoryData) return []
    const categories = categoryData.filter((category) => {
      const { display, hasBenefits } = category.option
      const exposureCategoryIndex = display.exposureAreas.findIndex((item) => {
        if (options?.isMainCategory) {
          return item.type !== 'NONE'
        } else {
          return (item.area === type) && (item.type !== 'NONE')
        }
      })
      const hasCategory = (exposureCategoryIndex !== -1) && hasBenefits && (options?.isMainCategory ? display.isMainCategory : true)

      if (options?.targetCategoryId) {
        return (category.categoryId === options.targetCategoryId) && hasCategory
      } else if (options?.categoryDepth) {
        return (category.categoryDepth === options.categoryDepth) && hasCategory
      }
      return hasCategory
    })
    return sortBy(categories, ['categoryDepth', 'categoryPriority'])
  }, [categoryData, type])

  const getBenefitsByCategory = useCallback((categories: BenefitCategories[], isExcept?: boolean) => {
    const benefits: BenefitListDataType[] = []

    categories.forEach((category) => {
      if (!benefitData) return

      const {display, sort} = category.option
      const findExposureCategory = display.exposureAreas.find((item) => {
        if (isExcept) {
          return item
        } else if ((item.area === type) && (item.type === 'LIST' || item.type === 'COMMERCE_LIST')) {
          return item
        }
        return null
      })
      let benefitList = benefitData.filter((benefit) => benefit.categoryId === category.categoryId)

      if (sort.by === 'RANDOM') {
        benefitList = shuffle(benefitList)
      }
      if (findExposureCategory?.displayCount) {
        benefitList = benefitList.slice(0, findExposureCategory.displayCount)
      }
      if (benefitList.length) {
        benefits.push({ ...category, benefitItem: benefitList })
      }
    })
    return benefits
  }, [benefitData, type])

  const benefitCategories = useMemo(() => {
    switch (type) {
      case 'BENEFIT_TAB':
        return getFilteredCategories({ categoryDepth: 1 })
      case 'BENEFIT_PAGE':
      default:
        return getFilteredCategories()
    }
  }, [type, getFilteredCategories])

  const benefitList = useMemo(() => {
    if (!benefitCategories) return

    const categories: BenefitCategories[] = []

    switch (type) {
      case 'BENEFIT_PAGE':
        if (!targetCategoryId) return
        const lastCategories = benefitCategories.filter((item) => (item.parentCategoryId === targetCategoryId) && (item.categoryDepth === 3))
        lastCategories.length ? categories.push(...lastCategories) : categories.push(...getFilteredCategories({ targetCategoryId }))
        break
      case 'MAIN':
      case 'BENEFIT_TAB':
        categories.push(...getFilteredCategories())
        break
    }
    return getBenefitsByCategory(categories, type === 'BENEFIT_PAGE')
  }, [benefitCategories, targetCategoryId, type, getFilteredCategories, getBenefitsByCategory])

  const mainBenefitList = useMemo(() => {
    if (type !== 'MAIN') return []

    const categories = shuffle(getFilteredCategories({ isMainCategory: true }))
    return getBenefitsByCategory(categories, true)
  }, [type, getFilteredCategories, getBenefitsByCategory])

  return {
    categoryData,
    benefitData,
    benefitCategories,
    benefitList,
    mainBenefitList,
    isCategoryLoading,
    isBenefitLoading,
  }
}

export { useGetBenefitData }
