import { useQuery } from '@tanstack/react-query'
import { AdsTrackingParams, getAdsTracking } from '@tmap-web-lib/remote-api-client/frontman'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'


interface Props extends AdsTrackingParams {
  enabled: boolean
  useErrorBoundary?: boolean
}

function useGetAdsTracking(props: Props) {
  const { url, materialId, orientation, vimpressionId } = props

  return useQuery<void, AxiosError>([KEYS.BANNER_TRACKING_LIST(vimpressionId)],
    () => getAdsTracking({ url, materialId, orientation, vimpressionId })
      .then(({ data }) => {
        return data
      }),
    {
      enabled: props.enabled,
      useErrorBoundary: props.useErrorBoundary ?? true
    }
  )
}

export { useGetAdsTracking }
