export const MAIN_LOG_PRESET = {
  ATTRIBUTES: {
    page_type: 'web',
    page_id: '/drivinglife/main',
  },
  CUSTOM_FIELDS: [] as [name: string, value: string][],
}

export const MAIN_NEWCOMER_LOG_PRESET = {
  ATTRIBUTES: {
    page_type: 'web',
    page_id: '/drivinglife/main_newcomer',
  },
  CUSTOM_FIELDS: [] as [name: string, value: string][],
}

export const APP_UPDATE_LOG_PRESET = {
  ATTRIBUTES: {
    page_type: 'web',
    page_id: '/drivinglife/main/popup_appupdate',
  },
  CUSTOM_FIELDS: [] as [name: string, value: string][],
}

export const TERMS_LOG_PRESET = {
  ATTRIBUTES: {
    page_type: 'web',
    page_id: '/drivinglife/terms',
  },
  CUSTOM_FIELDS: [] as [name: string, value: string][],
}

export const TERMS_TELE_MARKETING_LOG_PRESET = {
  ATTRIBUTES: {
    page_type: 'web',
    page_id: '/drivinglife/terms_tele-marketing',
  },
  CUSTOM_FIELDS: [] as [name: string, value: string][],
}

export const TERMS_REGISTER_VEHICLE_LOG_PRESET = {
  ATTRIBUTES: {
    page_type: 'web',
    page_id: '/drivinglife/carRegistration',
  },
  CUSTOM_FIELDS: [] as [name: string, value: string][],
}

export const INSURANCE_DETAIL_LOG_PRESET = {
  ATTRIBUTES: {
    page_type: 'web',
    page_id: '/drivinglife/insur_vehicle_detail',
  },
  CUSTOM_FIELDS: [] as [name: string, value: string][],
}

export const BENEFIT_LOG_PRESET = {
  ATTRIBUTES: {
    page_type: 'web',
    page_id: '/drivinglife/benefit',
  },
  CUSTOM_FIELDS: [] as [name: string, value: string][],
}

export const BENEFIT_POPULAR_LOG_PRESET = {
  ATTRIBUTES: {
    page_type: 'web',
    page_id: '/drivinglife/selected_benefits',
  },
  CUSTOM_FIELDS: [] as [name: string, value: string][],
}

export const BENEFIT_REMINDER_LOG_PRESET = {
  ATTRIBUTES: {
    page_type: 'web',
    page_id: '/drivinglife/bottomsheet_benefit_reminder',
  },
  CUSTOM_FIELDS: [] as [name: string, value: string][],
}

export const MAIN_POPUP_LOG_PRESET= {
  ATTRIBUTES: {
    page_type: 'web',
    page_id: '/drivinglife/intro',
  },
  CUSTOM_FIELDS: [] as [name: string, value: string][],
}

export const DRIVING_ANALYSIS_LOG_PRESET = {
  ATTRIBUTES: {
    page_type: 'web',
    page_id: '/drivinglife/analysis',
  },
  CUSTOM_FIELDS: [] as [name: string, value: string][],
}

export const DRIVING_RECORD_LOG_PRESET = {
  ATTRIBUTES: {
    page_type: 'web',
    page_id: '/drivinglife/record',
  },
  CUSTOM_FIELDS: [] as [name: string, value: string][],
}

export const DRIVING_RECORD_HISTORY_LOG_PRESET = {
  ATTRIBUTES: {
    page_type: 'web',
    page_id: '/drivinglife/record/history',
  },
  CUSTOM_FIELDS: [] as [name: string, value: string][],
}

export const VEHICLE_LOG_PRESET = {
  ATTRIBUTES: {
    page_type: 'web',
    page_id: '/drivinglife/mycar',
  },
  CUSTOM_FIELDS: [] as [name: string, value: string][],
}

export const VEHICLE_HIGHWAYTOLL_TERMS_LOG_PRESET = {
  ATTRIBUTES: {
    page_type: 'web',
    page_id: '/drivinglife/mycar/highwaytoll_terms',
  },
  CUSTOM_FIELDS: [] as [name: string, value: string][],
}

export const VEHICLE_SETTING_LOG_PRESET = {
  ATTRIBUTES: {
    page_type: 'web',
    page_id: '/drivinglife/carChange',
  },
  CUSTOM_FIELDS: [] as [name: string, value: string][],
}

export const VEHICLE_NOT_REGISTERED_LOG_PRESET = {
  ATTRIBUTES: {
    page_type: 'web',
    page_id: '/drivinglife/mycar_not_registered',
  },
  CUSTOM_FIELDS: [] as [name: string, value: string][],
}

export const ETC_INSURER_CALL_LOG_PRESET = {
  ATTRIBUTES: {
    page_type: 'web',
    page_id: '/drivinglife/mycar/insurcall',
  },
  CUSTOM_FIELDS: [] as [name: string, value: string][],
}

export const ETC_INSURER_CALL_LIST_LOG_PRESET = {
  ATTRIBUTES: {
    page_type: 'web',
    page_id: '/drivinglife/mycar/insurcall/callList',
  },
  CUSTOM_FIELDS: [] as [name: string, value: string][],
}

export const SETTINGS_LOG_PRESET = {
  ATTRIBUTES: {
    page_type: 'web',
    page_id: '/drivinglife/setting',
  },
  CUSTOM_FIELDS: [] as [name: string, value: string][],
}

export const SETTINGS_OTP_LOG_PRESET = {
  ATTRIBUTES: {
    page_type: 'web',
    page_id: '/drivinglife/otp',
  },
  CUSTOM_FIELDS: [] as [name: string, value: string][],
}

export const SETTINGS_WITHDRAWAL_LOG_PRESET = {
  ATTRIBUTES: {
    page_type: 'web',
    page_id: '/drivinglife/account_withdrawal',
  },
  CUSTOM_FIELDS: [] as [name: string, value: string][],
}

export const UNPAID_TOLL_HISTORY_LOG_PRESET = {
  ATTRIBUTES: {
    page_type: 'web',
    page_id: '/drivinglife/mycar/highwaytoll',
  },
  CUSTOM_FIELDS: [] as [name: string, value: string][],
}

export const UNPAID_TOLL_RESULT_LOG_PRESET = {
  ATTRIBUTES: {
    page_type: 'web',
    page_id: '/drivinglife/mycar/highwaytoll/result',
  },
  CUSTOM_FIELDS: [] as [name: string, value: string][],
}