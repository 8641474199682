import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { registerCarMaintenance, DeepPartial, CarMaintenanceDataType } from '@tmap-web-lib/remote-api-client/frontman'

function useRegisterCarMaintenance(carProfileId: string) {
  return useMutation<AxiosResponse, AxiosError,DeepPartial<Omit<DeepPartial<CarMaintenanceDataType>, 'createdAt' | 'updatedAt'>>> (
    (data) => registerCarMaintenance(carProfileId, data),
  )
}

export {useRegisterCarMaintenance}
