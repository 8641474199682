import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'
import {
  getEventJoinStatus,
  GetEventJoinStatusRequestType,
  GetEventJoinStatusResponseType
} from '@tmap-web-lib/remote-api-client/frontman'

interface Props extends GetEventJoinStatusRequestType {
  useErrorBoundary?: boolean
}

function useGetEventJoinStatus(props: Props) {
  const { access_key, event_name } = props
  return useQuery<GetEventJoinStatusResponseType, AxiosError>([KEYS.EVENT_JOIN_STATUS(event_name)],
    () => getEventJoinStatus({ access_key, event_name }).then(({ data }) => data),
    {
      useErrorBoundary: props.useErrorBoundary ?? true
    }
  )
}

export { useGetEventJoinStatus }
