import React from 'react'
import { DialogCancelButton, DialogContent, DialogCustom, DialogFooter, DialogFrame, DialogOkButton } from './Elements'
import { DialogOkCancelProps } from './types'

function DialogOkCancel(props: DialogOkCancelProps) {
  const {
    type,
    isCustom,
    className,
    title,
    content,
    okText,
    cancelText,
    okDisabled,
    cancelDisabled,
    okClassName,
    cancelClassName,
    onOk,
    onCancel,
    btnBorderRadius,
    gap
  } = props
  return (
    <DialogFrame
      type={type}
      className={className}
      content={isCustom ? (
        <DialogCustom title={title} content={content}/>
      ) : (
        <DialogContent title={title} content={content}/>
      )}
      buttonSet={
        <DialogFooter
          okButton={
            <DialogOkButton className={okClassName} disabled={okDisabled} onClick={onOk}
                            borderRadius={btnBorderRadius ? btnBorderRadius : '8px'} gap={gap ? gap : '8px'}>
              {okText}
            </DialogOkButton>
          }
          cancelButton={
            <DialogCancelButton className={cancelClassName} disabled={cancelDisabled} onClick={onCancel} borderRadius={btnBorderRadius ? btnBorderRadius : '8px'}>
              {cancelText}
            </DialogCancelButton>
          }
        />
      }
    />
  )
}

export default DialogOkCancel
export { DialogOkCancel }
