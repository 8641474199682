import { useQuery } from '@tanstack/react-query'
import {
  getUserReddotList,
  RedddotListData,
} from '@tmap-web-lib/remote-api-client/frontman'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'

interface Options {
  groupName?: string
  useErrorBoundary?: boolean
}
function useGetUserRedDotList({ groupName, useErrorBoundary }: Options = {}) {
  return useQuery<RedddotListData, AxiosError>([KEYS.RED_DOT_LIST(groupName)],
    () => getUserReddotList(groupName).then(({ data }) => data),
    {
      useErrorBoundary
    }
  )
}

export { useGetUserRedDotList }
