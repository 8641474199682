import { useQuery } from '@tanstack/react-query'
import { BenefitList } from '@tmap-web-lib/remote-api-client/dist/types/frontman/apis/driving-score/types'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'
import { getBenefitList } from '@tmap-web-lib/remote-api-client/frontman'

interface Props {
  categoryId: string
  useErrorBoundary?: boolean
}

function useGetBenefitList(props: Props) {
  const { categoryId } = props
  return useQuery<BenefitList[], AxiosError>([KEYS.BENEFIT_LIST()],
    () => getBenefitList({ categoryId, valid: true })
      .then(({ data }) => data),
    {
      useErrorBoundary: props?.useErrorBoundary ?? true
    }
  )
}

export { useGetBenefitList }
