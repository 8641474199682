import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { RoutePath } from './paths'
import { lazyRetry } from '../utils/lazy-retry'

const VehicleSetting = lazyRetry(() => import('../pages/Driving/vehicle/setting/Setting'))
const VehicleDetail = lazyRetry(() => import('../pages/Driving/vehicle/detail/Detail'))
const UnpaidTollHistory = lazyRetry(() => import('../pages/Driving/vehicle/unpaidToll/UnpaidTollHistory'))
const UnpaidTollBridge = lazyRetry(() => import('../pages/Driving/vehicle/unpaidToll/UnpaidTollBridge'))
const UnpaidTollResult = lazyRetry(() => import('../pages/Driving/vehicle/unpaidToll/UnpaidTollResult'))

function SwitchVehicleSubPages() {
  return (
    <Suspense fallback="">
      <Switch>
        <Route path={RoutePath.Vehicle_detail} exact>
          <VehicleDetail/>
        </Route>
        <Route path={RoutePath.Vehicle_setting} exact>
          <VehicleSetting/>
        </Route>
        <Route path={RoutePath.Unpaid_toll} exact>
          <UnpaidTollHistory/>
        </Route>
        <Route path={RoutePath.unpaid_toll_bridge} exact>
          <UnpaidTollBridge/>
        </Route>
        <Route path={RoutePath.unpaid_toll_result} exact>
          <UnpaidTollResult/>
        </Route>
        <Redirect to={RoutePath.Home} from="*"/>
      </Switch>
    </Suspense>
  )
}

export { SwitchVehicleSubPages }
