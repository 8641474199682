import { useQuery } from '@tanstack/react-query'
import { getTripsDetail, TripsDetailParams, TripsDetailResponse } from '@tmap-web-lib/remote-api-client/frontman'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'
import { mockHost } from '../../api'
import { useSelector } from 'react-redux'
import { selectSettingsMockEnabled } from '../../store/settings'

interface Props extends TripsDetailParams {
  enabled?: boolean
  useErrorBoundary?: boolean
}

function useGetTripDrivingDetailInfo(props: Props) {
  const { tripInitSessionId, enabled } = props
  const isMockEnabled = useSelector(selectSettingsMockEnabled)

  return useQuery<TripsDetailResponse, AxiosError>([KEYS.TRIP_DRIVING_DETAIL_INFO()],
    async () => {
      if (JSON.parse(isMockEnabled as string)) {
        const { data } = await mockHost.get<TripsDetailResponse>('/ubds/trip/v1/trips/{}')
        return data
      } else {
        const { data } = await getTripsDetail({ tripInitSessionId })
        return data
      }
    },
    {
      enabled: enabled,
      useErrorBoundary: props.useErrorBoundary ?? true,
    }
  )
}

export { useGetTripDrivingDetailInfo }
