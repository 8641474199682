import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'
import { getCarOptionCodeList, CodeListResData, OptionTypeCode } from '@tmap-web-lib/remote-api-client/frontman'

interface Props {
  type: OptionTypeCode
  useErrorBoundary?: boolean
}

function useGetCarOptionCodeList({ type, useErrorBoundary }: Props) {
  return useQuery<CodeListResData, AxiosError>([KEYS.CAR_OPTION_CODE_LIST()],
    () => getCarOptionCodeList({ type }).then(({ data }) => data),
    {
      useErrorBoundary: useErrorBoundary ?? true
    }
  )
}

export { useGetCarOptionCodeList }
