import { BarGraphItem, BarGraphOption, BarGraphType } from './BarGraphItem'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

type BarGraphStyle = {
  displayType?: string
  barHeight?: number
  barGraphWidth?: string | number
  marginTop?: number
  borderRadius?: number
  totalCount?: number
  isItemBottomBoxStyle?: boolean
  animationReady?: boolean
}

interface BarGraphProps extends BarGraphOption, BarGraphStyle {
  barGraphData: BarGraphType[] | null
}

function BarGraph(props: BarGraphProps) {
  const {
    displayType = 'flex',
    barGraphData,
    barWidth,
    barGraphWidth,
    barHeight = 108,
    marginTop,
    borderRadius = 6,
    totalCount,
    animationReady,
    unit,
    isAlwaysVisibleUnit,
    isItemBottomBoxStyle,
    eventLogActionId,
    eventLog,
    handleClick
  } = props

  return (
    <Graph
      displayType={displayType}
      barHeight={barHeight}
      barGraphWidth={barGraphWidth}
      marginTop={marginTop}
      isItemBottomBoxStyle={isItemBottomBoxStyle}
      totalCount={totalCount}
    >
      {barGraphData?.map((item, index) => {
        return (
          <BarGraphItem
            key={index}
            barGraph={item}
            index={index}
            barWidth={barWidth}
            borderRadius={borderRadius}
            unit={unit}
            eventLogActionId={eventLogActionId}
            animationReady={animationReady}
            isAlwaysVisibleUnit={isAlwaysVisibleUnit}
            isItemBottomBoxStyle={isItemBottomBoxStyle}
            eventLog={eventLog}
            handleClick={handleClick}
          />
        )
      })}
    </Graph>
  )
}

export { BarGraph }

const Graph = styled.ul<BarGraphStyle>`
  width: ${({ barGraphWidth }) => barGraphWidth ? typeof barGraphWidth === 'string' ? barGraphWidth : `${barGraphWidth}px` : 'auto'};
  display: ${({ displayType }) => displayType};
  justify-content: space-around;
  position: relative;
  height: ${({ barHeight }) => barHeight}px;
  margin-top: ${({ marginTop }) => `${marginTop ?? 0}px`};
  box-sizing: border-box;
  
  // bar 하단 경계선
  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: ${({ isItemBottomBoxStyle }) => isItemBottomBoxStyle ? '80px' : '22px'};
    left: 0;
    z-index: 0;
    border-bottom: 1px solid var(--C-gray-200);
  }
  ${({ totalCount }) => totalCount && totalCount >= 7 && css`
    @media (max-width: 320px) {
      & em {
        font-size: 0.9rem !important;
      }
    }
  `}
`
