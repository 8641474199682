import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  DialogBackground,
  DialogCustom,
} from './Elements'
import { classNames, getSearchParams } from '@tmap-web-lib/utils'
import { CSSTransition } from 'react-transition-group'
import { Button, ButtonTapHighlight } from '../Button'
import { CARPROFILE_SCHEME } from '../../utils'
import { useCarProfile, useLogBox, useOpenService } from '../../hooks'
import { useGetCarOptionCodeList } from '../../react-query'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { useGetInsurerInfo } from '../../pages/Etc/hook'
import { ETC_INSURER_CALL_LOG_PRESET } from '../../hooks/logbox/preset'
import { PageIdSearchParams, usePageIdContext } from '../../providers/page-id-context'
import isEmpty from 'lodash-es/isEmpty'
import { DialogBaseProps } from './types'

function DialogEmergencyCall({ onCancel }: DialogBaseProps) {
  const { carProfile } = useCarProfile()
  const { data: carOptionCodeList } = useGetCarOptionCodeList({ type: '200' })
  const insurance = useGetInsurerInfo({ carProfile, carOptionCodeList })

  const openService = useOpenService()
  const pageIdContext = usePageIdContext()
  const [eventLog, exposeLog] = useLogBox(['event', 'expose'], {
    attributesPreset: ETC_INSURER_CALL_LOG_PRESET.ATTRIBUTES,
    customFieldsPreset: ETC_INSURER_CALL_LOG_PRESET.CUSTOM_FIELDS,
    withEuk: true,
  })

  const [modalIsOpen, setModalIsOpen] = useState(true)

  const ref = useRef<HTMLDivElement>(null)
  const backgroundRef = useRef(null)

  const handleModifyInsurerClick = useCallback(() => {
    const extra = {
      serviceName : 'life',
      logbox: {
        type: 'tmap',
        origin: 'life'
      },
      action: {
        actionType: 'close'
      },
      carProfileId: carProfile?.carProfileId
    }
    const url = `${CARPROFILE_SCHEME}?pageid=open_car_insurance&extra=${encodeURIComponent(JSON.stringify(extra))}`
    eventLog().set('action_id', 'tap.cta_change_insur').send()
    openService(url)
  }, [carProfile, openService, eventLog])

  const handleCallClick = useCallback((phoneNumber?: string) => {
    if (!phoneNumber) return
    eventLog().set('action_id', 'tap.cta_call').send()
    TmapApp.phoneCall({ strTel: phoneNumber })
  }, [eventLog])

  const renderContent = useMemo(() => {
    return (
      <div className="popup_emergency">
        <h1 className="popup_emergency_title">내 보험사 긴급호출</h1>
        <>
          {insurance.insurer?.value === 'INS_ETC' ? (
            // 기타 보험의 경우
            <>
              <img
                src={require('../../assets/images/img_carcare_item_insure_etc.svg').default}
                width="72"
                height="72"
                className="popup_emergency_ico"
                alt=""
              />
              <em className="popup_emergency_select">기타보험사로 설정되어 있어요</em>
              <p className="popup_emergency_desc">
                보험사 전화연결을 위해서<br />정확한 보험사를 선택해주세요
              </p>
              <Button
                variant={'primary'}
                styleWidth={'130px'}
                styleHeight={40}
                styleMargin={'24px 0 0'}
                stylePadding={'0'}
                onClick={handleModifyInsurerClick}
              >
                내 보험사 변경
              </Button>
              <ButtonTapHighlight
                className="popup_emergency_call"
                isFullWidth={false}
                disabled
              >
                전화연결
              </ButtonTapHighlight>
            </>
          ) : (
            // 보험정보가 있는 경우
            <>
              <img
                src={insurance.description?.iconUrl}
                width="112"
                height="112"
                className="popup_emergency_logo"
                alt=""
              />
              <strong className="popup_emergency_insurer">{insurance.insurer?.name}</strong>
              <ButtonTapHighlight
                isFullWidth={false}
                className="popup_emergency_modify"
                onClick={handleModifyInsurerClick}
              >
                내 보험사 변경
              </ButtonTapHighlight>
              <ButtonTapHighlight
                className="popup_emergency_call"
                isFullWidth={false}
                onClick={() => handleCallClick(insurance.description?.emergencyNumber)}
              >
                {insurance.description?.emergencyNumber.replace(/(.{4})/,"$1-")} 전화연결
              </ButtonTapHighlight>
            </>
          )}
        </>
      </div>
    )
  }, [insurance, handleModifyInsurerClick, handleCallClick])

  useEffect(() => {
    const searchParams = getSearchParams<PageIdSearchParams>()
    if (!isEmpty(searchParams) && searchParams.extra) {
      const extraObject = JSON.parse(searchParams.extra as string)
      const extraCustomFields = extraObject.logbox

      if (extraCustomFields) {
        Object.keys(extraCustomFields).forEach(key => {
          ETC_INSURER_CALL_LOG_PRESET.CUSTOM_FIELDS.push([key, extraCustomFields[key]])
        })
      }
    }
    exposeLog().send()
  }, [exposeLog, pageIdContext])

  useEffect(() => {
    return TmapApp.utils.addNativeEventListener('onPause', () => {
      setModalIsOpen(false)
      onCancel?.()
    })
  }, [onCancel])

  return (
    <CSSTransition
      classNames="dimmed-fade-in"
      unmountOnExit={true}
      timeout={300}
      in={modalIsOpen}
      nodeRef={backgroundRef}
      appear
    >
      <DialogBackground ref={backgroundRef}>
        <CSSTransition
          classNames="bottom-slide-up"
          unmountOnExit={true}
          timeout={200}
          in={modalIsOpen}
          nodeRef={ref}
          appear
        >
          <div ref={ref} className={classNames('popup_wrap', 'popup_bottom')}>
            <div className={classNames('popup_cont')}>
              <DialogCustom
                className={'no_padding'}
                content={renderContent}
              />
            </div>
          </div>
        </CSSTransition>
      </DialogBackground>
    </CSSTransition>
  )
}

export default DialogEmergencyCall
export { DialogEmergencyCall }
