import { useQuery } from '@tanstack/react-query'
import {
  InsuranceDiscounts,
} from '@tmap-web-lib/remote-api-client/dist/types/frontman/apis/driving-score/types'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'
import { getInsuranceDiscounts } from '@tmap-web-lib/remote-api-client/frontman'
import { useSelector } from 'react-redux'
import { selectSettingsMockEnabled } from '../../store/settings'
import { mockHost } from '../../api'

function useGetInsuranceDiscounts(benefitId: string) {
  const isMockEnabled = useSelector(selectSettingsMockEnabled)

  return useQuery<InsuranceDiscounts, AxiosError>(
    [KEYS.INSURANCE_DISCOUNT()],
    async () => {
      if (JSON.parse(isMockEnabled as string)) {
        const { data } = await mockHost.get('/driving-score/bnf/insurance-discounts')
        return data
      } else {
        const { data } = await getInsuranceDiscounts({ benefitId })
        return data
      }
    },
    {
      enabled: !!benefitId,
    },
  )
}

export { useGetInsuranceDiscounts }
