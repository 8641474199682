import React, { ReactNode } from 'react'
import { classNames } from '@tmap-web-lib/utils'

interface Props {
  cancelButton?: ReactNode;
  okButton?: ReactNode;
  className?:string;
}

function DialogFooter(props: Props) {
  return (
    <div className={classNames('popup_btn_wrap', props.className)}>
      {props.cancelButton}
      {props.okButton}
    </div>
  )
}

export { DialogFooter }
