import React, { useCallback, useEffect, useState } from 'react'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'

interface Props {
  actionId: string
  isMainPageExposureArea?: boolean
  eventLog: () => TmapLogBuilder
}

function  useHorizontalScrollingLogs({ actionId, isMainPageExposureArea, eventLog }: Props) {
  const [scrolled, setScrolled] = useState(false)

  const handleScroll = useCallback((el: React.UIEvent<HTMLUListElement, UIEvent>) => {
    // 136은 한 아이템의 넓이값
    if (!scrolled && isMainPageExposureArea && el.currentTarget.scrollLeft > 136) {
      setScrolled(true)
    }
  }, [scrolled, isMainPageExposureArea])

  useEffect(() => {
    if (scrolled) {
      console.log(actionId)
      eventLog().set('action_id', actionId).send()
    }
  }, [scrolled, actionId, eventLog])

  return handleScroll
}

export { useHorizontalScrollingLogs }
