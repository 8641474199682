import { ButtonHTMLAttributes, PropsWithChildren, useMemo } from 'react'
import { classNames } from '@tmap-web-lib/utils'

interface ButtonProps extends PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> {
  isWhiteIcon?: boolean,
  isRoundIcon?:boolean,
  isForLowSpec?: boolean,
}

function HeaderBackButton({
  type = 'button',
  isWhiteIcon,
  isRoundIcon,
  isForLowSpec,
  children,
  ...attrs
}: ButtonProps) {

  const getIconShape = useMemo(() => {
    if (isWhiteIcon) {
      return 'reversal'
    } else if (isRoundIcon) {
      return 'round'
    } else if (isForLowSpec) {
      return 'low_spec'
    } else {
      return ''
    }
  },[isWhiteIcon, isRoundIcon, isForLowSpec])

  return (
    <button
      type={type}
      className={classNames('header_back', getIconShape)}
      {...attrs}
    >
      {children}
    </button>
  )
}

export { HeaderBackButton }
