import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'
import {
  CharanchaMediaList,
  CharanchaMediaListParams,
} from '@tmap-web-lib/remote-api-client/dist/types/frontman/apis/charancha/types'
import { getCharanchaMediaList } from '@tmap-web-lib/remote-api-client/frontman'
import { useSelector } from 'react-redux'
import { selectSettingsMockEnabled } from '../../store/settings'
import { mockHost } from '../../api'

interface Props extends CharanchaMediaListParams {
  enabled: boolean
  useErrorBoundary?: boolean
}

function useGetCharanchaMediaList(props: Props) {
  const { enabled, size, type, useErrorBoundary } = props
  const isMockEnabled = useSelector(selectSettingsMockEnabled)
  /**
   * type
   * - TSCORE : 아웃바운딩 url
   * - TSCORE_INAPP : 인앱브라우저에서 열리는  티맵 스킴 url
   */
  return useQuery<CharanchaMediaList, AxiosError>([KEYS.CHARANCHA_MEDIA_LIST()],
    async () => {
      if (JSON.parse(isMockEnabled as string)) {
        const { data } = await mockHost.get('/charancha/v1/media')
        return data
      } else {
        const { data } = await getCharanchaMediaList({ size, type })
        return data
      }
    },
    {
      enabled,
      useErrorBoundary: useErrorBoundary ?? true,
    },
  )
}

export { useGetCharanchaMediaList }
