import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { deleteTrips, TripsDetailParams } from '@tmap-web-lib/remote-api-client/frontman'

function useDeleteTrips() {
  return useMutation<AxiosResponse, AxiosError, TripsDetailParams>(
    (data) => deleteTrips(data)
  )
}

export { useDeleteTrips }
