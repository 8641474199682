import { ButtonTapHighlight } from '../../components'
import { classNames, formatCommaNumber } from '@tmap-web-lib/utils'
import { useCallback, useMemo } from 'react'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'
import { useOpenService, useOpenServiceUrl } from '../../hooks'

type RankInfoType = 'LOCATION' | 'CAR'

export interface RankInfo {
  type: RankInfoType
  label: string
  rank: number | null | undefined
  link: string
  isActive: boolean
}

interface Props {
  data: RankInfo | null
  eventLog: () => TmapLogBuilder
}

function MainRankInfo({ data, eventLog }: Props) {
  const openService = useOpenService()
  const openServiceUrl = useOpenServiceUrl()

  const upDown = useMemo(() => {
    if (!data?.rank) return
    if (data.rank > 0) {
      return 'up'
    } else if (data.rank < 0) {
      return 'down'
    }
  }, [data])

  const renderDescription = useMemo(() => {
    if (!data) return
    const { isActive, type, rank } = data

    if (!isActive) {
      return type === 'LOCATION' ? '집 등록이 필요해요' : '내 차 등록이 필요해요'
    } else {
      if (rank === null) {
        return '주행하면 랭킹이 생겨요'
      } else if (rank === undefined) {
        return '랭킹보기를 눌러 확인'
      } else if (rank === 0) {
        return '어제와 동일해요'
      } else {
        return `${formatCommaNumber(Math.abs(rank))}등`
      }
    }
  }, [data])

  const handleClick = useCallback((type: RankInfoType) => {
    if (!data) return
    const { isActive, link } = data

    if (type === 'LOCATION') {
      eventLog().set('action_id', isActive ? 'tap.view_rank_neighbor' : 'tap.view_rank_register_home').send()
    } else if (type === 'CAR') {
      eventLog().set('action_id', isActive ? 'tap.view_rank_mycar' : 'tap.view_rank_register_car').send()
    }

    if (isActive) {
      openServiceUrl(link)
    } else {
      openService(link)
    }
  }, [data, openServiceUrl, openService, eventLog])

  if (!data) return null

  const { type, label, isActive } = data

  return (
    <ButtonTapHighlight
      borderRadius={0}
      className="main_rank_item"
      onClick={() => handleClick(type)}
    >
      <img
        src={require(`../../assets/images/img_myrank_${type.toLowerCase()}${!isActive ? '_null' : ''}.svg`).default}
        className="main_rank_item_ico"
        width="48"
        height="48"
        alt=""
      />
      <div>
        <span className={classNames('main_rank_item_label', !isActive ? 'disabled' : undefined)}>{label} 랭킹</span>
        <em className={classNames('main_rank_item_rank', upDown, !isActive ? 'disabled' : undefined)}>{renderDescription}</em>
      </div>
      <span className="main_rank_item_view">랭킹보기</span>
    </ButtonTapHighlight>
  )
}

export { MainRankInfo }
