import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'
import {
  SameOdRouteIdRequestParams,
  SameOdRouteIdResData,
  getSameOdRouteId,
} from '@tmap-web-lib/remote-api-client/frontman'
import { useSelector } from 'react-redux'
import { selectSettingsMockEnabled } from '../../store/settings'
import { mockHost } from '../../api'

function useGetSameOdRouteId(props: SameOdRouteIdRequestParams) {
  const { departCoordinate, destCoordinate } = props
  const isMockEnabled = useSelector(selectSettingsMockEnabled)

  return useQuery<SameOdRouteIdResData, AxiosError>([KEYS.SAME_OD_ROUTE_ID(), departCoordinate, destCoordinate],
    async () => {
      if (JSON.parse(isMockEnabled as string)) {
        const { data } = await mockHost.post<SameOdRouteIdResData>('/esc/esc-channel/poi/favorite/findsameodrouteid')
        return data
      } else {
        const { data } = await getSameOdRouteId({ departCoordinate, destCoordinate })
        return data
      }
    },
    {
      enabled: !!departCoordinate && !!destCoordinate,
    },
  )
}

export { useGetSameOdRouteId }
