import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { concat } from 'lodash-es'
import { DialogBaseProps } from '../../../components'
import { useAgreeTerms } from './use-agree-terms'
import { TermsAgreements } from '@tmap-web-lib/remote-api-client/frontman'
import { useGetTermsAgreement, useGetTermsGroup } from '../../../react-query'
import { TermsInfoType, useGetTermsInfo } from './use-get-terms-info'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'

interface Props extends DialogBaseProps {
  eventLog: () => TmapLogBuilder
  handleError: () => void
  isDialogOpen?: boolean
  setModalIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
  handleSuccess?: () => void
}
function useTerms({ isDialogOpen, eventLog, handleError, onOk, setModalIsOpen, handleSuccess }: Props) {
  const [termsAgreements, setTermsAgreements] = useState<TermsAgreements[]>([])
  const [ubsTermsCode, setUbsTermsCode] = useState<string[]>([])
  const [tmsTermsCode, setTmsTermsCode] = useState<string[]>([])
  const [termsList, setTermsList] = useState<TermsInfoType[]>([])

  const { data: termsGroupData } = useGetTermsGroup({ groupAppCode: 'TMAP', termsGroupCode: 'TMAP_TSCORE_V2' })
  const { data: ubsTermsAgreementData } = useGetTermsAgreement({
    termsType: 'UBS01',
    termsAllowCode: ubsTermsCode.join(','),
    enabled: !!ubsTermsCode.length
  })
  const { data: tmsTermsAgreementData } = useGetTermsAgreement({
    termsType: 'TMS01',
    termsAllowCode: tmsTermsCode.join(','),
    enabled: !!tmsTermsCode.length
  })
  const termsInfo = useGetTermsInfo({ termsGroupData, termsAgreements })

  const isAllChecked = useMemo(() => {
    const checkedList: boolean[] = []
    termsList.forEach((terms) => {
      checkedList.push(terms.isChecked)
      if (terms.subTermsList) {
        terms.subTermsList.forEach((subTerms) => {
          checkedList.push(subTerms.isChecked)
        })
      }
    })
    return checkedList.every((item) => item)
  }, [termsList])

  const handleChecked = useCallback((termsList: TermsInfoType[]) => {
    setTermsList(termsList)
  }, [])

  const handleAllTermsChecked = useCallback(() => {
    eventLog().set('action_id', 'tap.agreeAll').send()
    const list = [ ...termsList ]

    list.forEach((terms) => {
      terms.isChecked = !isAllChecked
      if (terms.subTermsList) {
        terms.subTermsList.forEach((subTerms) => {
          subTerms.isChecked = !isAllChecked
        })
      }
    })
    setTermsList(list)
  }, [isAllChecked, termsList, eventLog])

  const handleAgreements = useAgreeTerms({
    termsGroupData,
    termsList,
    eventLog,
    setModalIsOpen,
    handleError,
    onOk,
    isDialogOpen,
    handleSuccess
  })

  useEffect(() => {
    if (termsGroupData) {
      const termsAgreeList = termsGroupData.termsAgreeList.filter(terms => !terms.parentTermsCode)
      termsAgreeList.forEach((terms) => {
        if (terms.termsType === 'UBS01_') {
          setUbsTermsCode((prev) => [ ...prev, terms.termsCode ])
        } else {
          setTmsTermsCode((prev) => [ ...prev, terms.termsCode ])
        }
      })
    }
  }, [termsGroupData])

  useEffect(() => {
    setTermsAgreements(concat(ubsTermsAgreementData?.termsAgreements || [], tmsTermsAgreementData?.termsAgreements || []))
  }, [ubsTermsAgreementData, tmsTermsAgreementData])

  useEffect(() => {
    if (termsInfo) {
      setTermsList(termsInfo)
    }
  }, [termsInfo])

  return {
    isAllChecked,
    termsInfo,
    termsList,
    termsGroupData,
    handleAgreements,
    handleAllTermsChecked,
    handleChecked,
  }
}

export { useTerms }
