import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { useDispatch, useStore } from 'react-redux'
import { appSlice } from './app'
import { settingsSlice } from './settings'

const rootReducer = combineReducers({
  app: appSlice.reducer,
  settings: settingsSlice.reducer,
})

const store = configureStore({
  reducer: rootReducer,
})

export { store }
export type RootState = ReturnType<typeof rootReducer>
export type AppStore = typeof store
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppStore = () => useStore<RootState>()
