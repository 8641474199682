export const KEYS = {
  // 약관
  TERMS_AGREEMENT: (termsAllowCode?:string) => ['termsAgreement',termsAllowCode],
  TERMS_GROUP: (termsGroupCode?: string) => ['termsGroup', termsGroupCode],
  TERMS_DETAIL_BY_APPLY_DATE: (termsCode: string, termsApplyDate: string) => ['termsDetailByApplyDate', termsCode, termsApplyDate],

  // 공통
  USER_INFO: () => ['userInfo'],
  EVENT_BANNER_LIST:() => ['eventBannerList'],
  EVENT_JOIN_STATUS: (eventName: string) => ['eventJoinStatus', eventName],
  RED_DOT_LIST: (groupName?: string) => ['redDotList', groupName],
  OTP_CODE: () => ['otpCode'],

  // 주행 정보
  DRIVING_SCORE_REPORTS: () => ['drivingScoreReports'],
  DRIVING_SCORE_MONTHLY_REPORTS: () => ['drivingScoreMonthlyReports'],
  SIMPLE_SAFE_DRIVING_INFO: () => ['simpleSafeDrivingInfo'],
  TRIP_SAFE_DRIVING_INFO: (createdAt?: string, lastMonth?: number, nextToken?: string) => ['tripSafeDrivingInfo', createdAt, lastMonth, nextToken],
  MONTHLY_TRIP_SCORE_INFO: () => ['monthlyTripScoreInfo'],
  TOTAL_SAFE_DRIVING: () => ['totalSafeDriving'],
  TRIP_DRIVING_DETAIL_INFO: () => ['tripDrivingDetailInfo'],
  SAME_OD_ROUTE_ID: () => ['sameOdRouteId'],
  LATEST_VIOLATIONS: () => ['latestViolations'],

  // 차량
  CAR_PROFILE_LIST: () => ['carProfileList'],
  CAR_PROFILE: () => ['carProfile'],
  CAR_OPTION_CODE_LIST: () => ['carOptionCodeList'],
  UPDATE_CAR_PROFILE_LIST: (carProfileId?: string) => ['updateCarProfileList', carProfileId],
  OUTER_CAR_INFO: () => ['outerCarInfo'],
  OUTER_CAR_INFO_REFETCH: () => ['outerCarInfoRefetch'],
  OUTER_CAR_WONBU: () => ['outerCarWonbu'],
  UNPAID_TOLLS: () => ['unpaidTolls'], // 내차관리 > 미납통행건수 조회
  UNPAID_TOLLS_LIST:() => ['unpaidTollsList'], // 미납통행료 내역
  CAR_RECALL_INFOS: () => ['carRecallInfos'],
  CAR_MAINTENANCE: (carProfileId?: string) => ['carMaintenance', carProfileId],

  // 운전분석
  USER_RANKS: () => ['userRanks'],
  MODEL_AVERAGE_SCORES: () => ['modelAverageScores'],
  USER_EXPECTED_RANKS: (score?: number) => ['userExpectedRanks', score],

  // 혜택
  BENEFIT_CATEGORIES: () => ['benefitCategories'],
  BENEFIT_LIST: () => ['benefitList'],
  INSURANCE_DETAIL: () => ['insuranceDetail'],
  INSURANCE_DISCOUNT: () => ['insuranceDiscount'],

  // 개인화 메시지
  USER_MESSAGES: () => ['userMessages'],

  // 컨텐츠
  SCORE_CONTENTS_LIST: () => ['scoreContentsList'],
  SCORE_CONTENTS_DETAIL: () => ['scoreContentsDetail'],

  // 광고
  BANNER_TRACKING_LIST: (vimpressionId: string) => ['bannerList', vimpressionId],
  BANNER_LIST: () => ['bannerList'],

  // POI
  FAVORITE_LIST: () => ['favoriteList'],

  // 차란차
  CHARANCHA_MEDIA_LIST: () => ['charanchaMediaList'],
  CHARANCHA_RECETNLY_VIEWED_CAR_LIST: () => ['charanchaRecentlyViewedCarList'],
  CHARANCHA_POPULAR_CAR_LIST: () => ['charanchaPopularCarList'],
}
