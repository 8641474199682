import React, { useMemo } from 'react'
import debounce from 'lodash-es/debounce'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { store } from '../../../store'
import {
  setIsCarLifeTermsAgreed,
  setOptionalMarketingTermsStatus,
} from '../../../store/settings'
import { DialogBaseProps } from '../../../components'
import { KEYS, useUpdateTermsAgreement } from '../../../react-query'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'
import { useQueryClient } from '@tanstack/react-query'
import { trackErrorLogs } from '../../../utils'
import { DateTime } from 'luxon'
import { TermsAgreements, TermsGroupDetailResType } from '@tmap-web-lib/remote-api-client/frontman'
import { TermsInfoType } from './use-get-terms-info'

interface Props extends DialogBaseProps {
  termsGroupData: TermsGroupDetailResType | undefined
  termsList: TermsInfoType[]
  isDialogOpen?: boolean
  eventLog: () => TmapLogBuilder
  handleError: () => void
  setModalIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
  handleSuccess?: () => void
}

function useAgreeTerms(props: Props) {
  const {
    termsGroupData,
    termsList,
    setModalIsOpen,
    isDialogOpen,
    onOk,
    eventLog,
    handleError,
    handleSuccess,
  } = props
  const updateTermsAgreement = useUpdateTermsAgreement()
  const queryClient = useQueryClient()

  return useMemo(() => debounce(async () => {
    if (!termsList[0].isChecked) {
      TmapApp.makeToast({ msg: '필수 약관에 동의해주세요.' })
    } else {
      const parentTermsList = termsList.filter((terms) => terms.subTermsList)
      const subTermsList = parentTermsList[0].subTermsList?.filter((terms) => terms)
      const termsCode = parentTermsList[0].termsCode
      const updateDate = DateTime.now().toFormat('yyyyMMdd')
      const { targetCount } = JSON.parse(termsGroupData?.additionalData || '{}')
      const list: Pick<TermsAgreements, 'allowCode' | 'allowYn' | 'allowTitle'>[] = []

      if (termsList) {
        termsList.forEach((terms) => {
          list.push({
            'allowCode': terms.termsType ?`${terms.termsType}${terms.termsCode}` : `${terms.termsCode}`,
            'allowTitle': `${terms.title}`,
            'allowYn': terms.isChecked ? 'Y' : 'N',
          })
        })
        subTermsList?.forEach((terms) => {
          list.push({
            'allowCode': `${terms.termsType}${terms.termsCode}`,
            'allowTitle': `${terms.title}`,
            'allowYn': terms.isChecked ? 'Y' : 'N',
          })
        })
      }

      if (!parentTermsList[0].isChecked) {
        store.dispatch(setOptionalMarketingTermsStatus(JSON.stringify([{ termsCode: termsCode, updateDate: updateDate, count: 0 }])))
      } else {
        store.dispatch(setOptionalMarketingTermsStatus(JSON.stringify([{ termsCode: termsCode, updateDate: updateDate, count: targetCount || 2 }])))
      }

      if (list.length) {
        await updateTermsAgreement.mutateAsync(list.map((terms) => terms), {
          onSuccess: () => {
            eventLog().set('action_id', 'tap.agreeContinue').send()
            store.dispatch(setIsCarLifeTermsAgreed(true))
            handleSuccess?.()
          },
          onError: (e) => {
            trackErrorLogs(e, 'tscore:error:updateTermsAgreement')
            handleError()
          },
          onSettled: () => {
            termsList.forEach((terms) => {
              queryClient.invalidateQueries([KEYS.TERMS_AGREEMENT(terms.termsCode)])
            })
            setModalIsOpen?.(!isDialogOpen)
            onOk?.()
          },
        })
      }
    }
  }, 500, {
    leading: true,
    trailing: false,
  }), [termsGroupData, termsList, isDialogOpen, onOk, queryClient, updateTermsAgreement, setModalIsOpen, handleError, handleSuccess, eventLog])
}

export { useAgreeTerms }
