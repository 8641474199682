import { PropsWithChildren, useEffect, useState } from 'react'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { pageIdInfos, usePageIdContext } from '../../providers/page-id-context'
import { RoutePath } from '../../routes'

interface MomentProps extends PropsWithChildren<{}> {
}

function MomentSendWrapper(props: MomentProps) {
  const { children } = props
  const pageIdContext = usePageIdContext()
  const { extraObject } = pageIdContext
  const [momentPageId, setMomentPageId] = useState('')

  useEffect(() => {
    Object.values(pageIdInfos).forEach((item) => {
      if (item.url === window.location.pathname) {
        if (item.url === RoutePath.Benefit) {
          if (extraObject.logbox && extraObject.logbox.type === 'menu_all') {
            // 전체 > 자동차보험으로 들어오는 경우와 혜택 페이지의 url path가 동일하지만 pageid는 다르기 때문에 전체 메뉴에서 들어오는 경우 따로 처리
            setMomentPageId('insur_vehicle_all')
          } else {
            setMomentPageId('benefit')
          }
        } else {
          setMomentPageId(item.pageId)
        }
      }
    })
  }, [extraObject.logbox])

  useEffect(() => {
    if (momentPageId) {
      TmapApp.sendMomentHappen({
        momentCode: 'TMAP_007',
        importData: {
          type: 'TMAP_DRIVING_HABIT',
          vo: {
            pageid: momentPageId
          }
        }
      })
    }
  }, [momentPageId])

  return (
    <>
      {children}
    </>
  )
}

export { MomentSendWrapper }
