import {  useQuery } from '@tanstack/react-query'
import { KEYS } from '../keys'
import { DateTime } from 'luxon'

export type InsuranceManualDetail = {
  title: string
  description?: string[]
  inlineHTMLDescription?: string
}

export type InsuranceManualDisplay = {
  pointBgColor: string
  pointFontColor: string
  buttonBgColor: string
  buttonFontColor?: string
}

export type InsuranceManual = {
  details: InsuranceManualDetail[]
  reviewNum: string
  display: InsuranceManualDisplay
}

export function fetchInsuranceGuide(insurerCode: string) {
  const path = `${process.env.REACT_APP_CDN_URL}/life-tab/json/insuranceSubscriptionManual_${insurerCode}.json?timestamp=${DateTime.now().toFormat('yyyyMMdd')}`
  return fetch(path).then((res) => res.json())
}

function useGetInsuranceDetail(insurerCode: string) {
  return useQuery<InsuranceManual>(
    [KEYS.INSURANCE_DETAIL()],
    () => fetchInsuranceGuide(insurerCode),
    {
      enabled: !!insurerCode,
    }
  )
}

export { useGetInsuranceDetail }
