import { useMutation } from '@tanstack/react-query'
import {
  getOuterCarInfoForRefetch,
  OuterCarInfoParam,
  OuterCarInfoRefetchResponseData,
} from '@tmap-web-lib/remote-api-client/frontman'
import { AxiosError } from 'axios'

function useGetOuterCarInfoForRefetch() {
  return useMutation<OuterCarInfoRefetchResponseData, AxiosError, OuterCarInfoParam>(
    (data) => getOuterCarInfoForRefetch(data).then(({ data }) => data),
  )
}

export { useGetOuterCarInfoForRefetch }
