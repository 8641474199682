import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'
import {
  getUserExpectedRanks,
  UserExpectedRanks,
  UserExpectedRanksParams,
} from '@tmap-web-lib/remote-api-client/frontman'
import { useSelector } from 'react-redux'
import { selectSettingsMockEnabled } from '../../store/settings'
import { mockHost } from '../../api'

function useGetUserExpectedRanks({ score }: UserExpectedRanksParams) {
  const isMockEnabled = useSelector(selectSettingsMockEnabled)

  return useQuery<UserExpectedRanks, AxiosError>([KEYS.USER_EXPECTED_RANKS(score)],
    async () => {
      if (JSON.parse(isMockEnabled as string)) {
        const { data } = await mockHost.get<UserExpectedRanks>('/driving-score/report/ranks/$userKey/expected')
        return data
      } else {
        const { data } = await getUserExpectedRanks({ score })
        return data
      }
    },
    {
      useErrorBoundary: false,
      refetchOnMount: 'always',
    },
  )
}

export { useGetUserExpectedRanks }
