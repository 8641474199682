import styled from '@emotion/styled'
import { ForwardedRef, forwardRef, PropsWithChildren } from 'react'
import { css } from '@emotion/react'

type GridStyleType = {
  paddingTop?: number
  paddingRight?: number
  hasDefaultPadding?: boolean
  dimmed?: boolean
  dimmedHeight?: string
}

type DescriptionOptionStyle = {
  fontSize?: string
  lineHeight?: number
}

export interface SectionGridType extends GridStyleType, PropsWithChildren<{}> {
  id?: string
  title?: string
  description?: string
  descriptionOption?: DescriptionOptionStyle
}

const SectionGrid = forwardRef((props: SectionGridType, ref?: ForwardedRef<HTMLElement>) => {
  const {
    id,
    title,
    description,
    descriptionOption,
    paddingTop,
    paddingRight,
    hasDefaultPadding,
    dimmed,
    dimmedHeight = '240px',
    children
  } = props

  return (
    <Grid
      id={id}
      paddingTop={paddingTop}
      hasDefaultPadding={hasDefaultPadding}
      dimmed={dimmed}
      dimmedHeight={dimmedHeight}
      ref={ref}
    >
      {title && (
        <GridTitle
          paddingRight={paddingRight}
          hasDefaultPadding={hasDefaultPadding}
          dangerouslySetInnerHTML={{__html: title}}
        >
        </GridTitle>
      )}
      {description && (
        <GridDescription
          options={descriptionOption}
        >
          {description}
        </GridDescription>
      )}
      {children}
    </Grid>
  )
})

export { SectionGrid }

const Grid = styled.section<GridStyleType>`
  position: relative;
  padding: ${({ hasDefaultPadding }) => hasDefaultPadding ? '0' : '36px 20px'};
  ${({ paddingTop }) => paddingTop && css`padding-top: ${paddingTop}px`};
  border-top: 8px solid var(--C-gray-100);
  
  &:first-of-type {
    border: 0;
  }
  ${({ dimmed, dimmedHeight }) => dimmed && css`
    &::after {
      content: '';
      position: absolute;
      z-index: 100;
      right: 0;
      bottom: 32px;
      left: 0;
      height: ${dimmedHeight};
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%); 
    }
  `}
`

const GridTitle = styled.h2<GridStyleType>`
  ${({ hasDefaultPadding }) => hasDefaultPadding && css`padding-left: 20px`};
  ${({ paddingRight }) => paddingRight && css`padding-right: ${paddingRight}px`};
  color: var(--C-gray-900);
  line-height: 2.8rem;
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-medium);
  white-space: pre-wrap;
  
  .highlight {
    color: var(--C-primary-500);
  }
`

const GridDescription = styled.p<{ options?: DescriptionOptionStyle }>`
  margin-top: 8px;
  color: var(--C-gray-500);
  line-height: 2.4rem;
  word-break: keep-all;
  
  ${({ options }) => options && css`
    font-size: ${options?.fontSize};
    line-height: ${options.lineHeight}px;
  `}
`
