import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { classNames } from '@tmap-web-lib/utils'
import { DialogWithAnimationProps } from './types'

function DialogToast(props: DialogWithAnimationProps) {
  const {
    customClassName,
    title,
    okText,
    onOk
  } = props
  const ref = useRef(null)
  const [modalIsOpen, setModalIsOpen] = useState(true)

  const handleOk = useCallback(() => {
    setModalIsOpen(false)
    setTimeout(() => {
      onOk?.()
    }, 500)

  }, [onOk])

  useEffect(() => {
    if (modalIsOpen) {
      const timer = setTimeout(() => {
        setModalIsOpen(false)
      }, 2000)
      return () => clearTimeout(timer)
    }
  }, [modalIsOpen])

  return (
    <CSSTransition
      classNames="slide-up"
      unmountOnExit={true}
      timeout={400}
      in={modalIsOpen}
      nodeRef={ref}
      appear
    >
      <div ref={ref} className={classNames('popup_wrap', 'popup_bottom_float', customClassName)}>
        <div className={classNames('popup_cont')}>
          <div className={classNames('popup_subject', customClassName)}>
            {title}
            {
              okText && (
                <button
                  className="popup_cont_toast"
                  onClick={handleOk}
                >
                  {okText}
                </button>
              )
            }

          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export default DialogToast
export { DialogToast }