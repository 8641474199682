import { serializeSearchParams } from '@tmap-web-lib/utils'
import Url from 'url-parse'

interface ShareUrlParams {
  title: string; body: string; url: string; imageUrl: string;
}

const bridgePageUrls = {
  DEV: 'https://dev.tmap.co.kr/tmap2/mobile/tmap.jsp',
  DTG: 'https://stg.tmap.co.kr/tmap2/mobile/tmap.jsp',
  STG: 'https://stg.tmap.co.kr/tmap2/mobile/tmap.jsp',
  PRD: 'https://www.tmap.co.kr/tmap2/mobile/tmap.jsp',
}

function getShareBridgeUrl({
  title, body, url, imageUrl,
}: ShareUrlParams) {
  const urlObject = new Url(url)
  let searchParams

  if (urlObject.protocol.startsWith('http')) {
    searchParams = serializeSearchParams({
      shareTitle: title,
      shareContents: body,
      shareImage: imageUrl,
      link: url,
    })
  } else {
    searchParams = serializeSearchParams({
      shareTitle: title,
      shareContents: body,
      shareImage: imageUrl,
      scheme: 'tmap',
      host: urlObject.host,
    })
    searchParams = `${searchParams}${urlObject.query ? `&${urlObject.query}`: ''}`
  }

  const bridgePageUrl = bridgePageUrls[process.env.REACT_APP_BUILD_ENV as keyof typeof bridgePageUrls] || bridgePageUrls.STG
  return `${bridgePageUrl}?${searchParams}`
}

export { getShareBridgeUrl }
