import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { DateTime } from 'luxon'
import { gte } from 'semver'
import {
  STORAGE_KEY_LIFE_DEV_TOOL_ACCESS_KEY,
  STORAGE_KEY_LIFE_STORE_MAIN_POPUP_SUPPRESSED,
  STORAGE_KEY_LIFE_SCHEME_URL,
  STORAGE_KEY_LIFE_UPDATE_OUTER_CAR_INFO,
  STORAGE_KEY_LIFE_VEHICLE_MAINTENANCE_UPDATE_ITEMS,
  STORAGE_KEY_LIFE_EMERGENCY_CALL_CHECKED,
  STORAGE_KEY_LIFE_DEV_TOOL_MOCK_ENABLED,
  STORAGE_KEY_LIFE_OPTIONAL_MARKETING_TERMS_STATUS,
  STORAGE_KEY_LIFE_OTP_CLOSED,
} from './storage-keys'
import { TermsState } from './settings-slice'

function createInitialState() {
  const mainPopupExpirationTime = DateTime.fromISO(localStorage.getItem(STORAGE_KEY_LIFE_STORE_MAIN_POPUP_SUPPRESSED) || '')

  return {
    devToolsAccessKey: localStorage.getItem(STORAGE_KEY_LIFE_DEV_TOOL_ACCESS_KEY) || '',
    accessKey: '',
    carrierName: '',
    deviceId: '',
    euk: '',
    sessionID: '',
    currentUrl: window.location.href,
    cookies: document.cookie,
    mockEnabled: localStorage.getItem(STORAGE_KEY_LIFE_DEV_TOOL_MOCK_ENABLED) || false,
    tabUiModeEnabled: gte(TmapApp.env.appVersion, '9.0.0'),
    mainPopupSuppressed: mainPopupExpirationTime.isValid && mainPopupExpirationTime.valueOf() > DateTime.now().valueOf(),
    isCarLifeTermsAgreed: null as TermsState,
    accessKeyExpired: false,
    isSkeleton: false,
    schemeURL: localStorage.getItem(STORAGE_KEY_LIFE_SCHEME_URL) || '',
    updateOuterCarInfo: localStorage.getItem(STORAGE_KEY_LIFE_UPDATE_OUTER_CAR_INFO) || '', // 외부 자동차 API 갱신 주기 체크
    headerHeight: 0,
    vehicleMaintenanceUpdateItems: localStorage.getItem(STORAGE_KEY_LIFE_VEHICLE_MAINTENANCE_UPDATE_ITEMS) || '', // 내차관리 정보 업데이트 상태(상세 툴팁 노출 여부)
    emergencyCallChecked: localStorage.getItem(STORAGE_KEY_LIFE_EMERGENCY_CALL_CHECKED) || '', // 긴급출동(보험사)등록 여부 체크
    termsAfterAction: false,
    optionMarketingTermsStatus: localStorage.getItem(STORAGE_KEY_LIFE_OPTIONAL_MARKETING_TERMS_STATUS) || '',
    optClosed: localStorage.getItem(STORAGE_KEY_LIFE_OTP_CLOSED) || '',
  }
}

export { createInitialState }
