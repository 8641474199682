import React from 'react'
import { DialogFrame, DialogFooter, DialogCustom, DialogRefreshButton } from './Elements'
import { DialogRefreshProps } from './types'

function DialogRefresh(props: DialogRefreshProps) {
  const {
    type = 'FULL',
    title,
    content,
    okText,
    onOk,
  } = props

  return (
    <DialogFrame
      type={type}
      content={
        <DialogCustom
          title={title}
          content={content}
          className="no_padding"
        />
      }
      buttonSet={
        <DialogFooter okButton={
          <DialogRefreshButton onClick={onOk}>
            {okText}
          </DialogRefreshButton>
        }/>
      }
    />
  )
}

export default DialogRefresh
export { DialogRefresh }
