import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { RoutePath } from './paths'
import { lazyRetry } from '../utils/lazy-retry'

const Benefit = lazyRetry(() => import('../pages/Benefit/Benefit'))
const BenefitPopular = lazyRetry(() => import('../pages/Benefit/popular/Popular'))
const BenefitDetail = lazyRetry(() => import('../pages/Benefit/detail/Detail'))

function SwitchBenefitPages() {
  return (
    <Suspense fallback="">
      <Switch>
        <Route path={RoutePath.Benefit} exact>
          <Benefit/>
        </Route>
        <Route path={RoutePath.Insurance} exact>
          <Benefit/>
        </Route>
        <Route path={RoutePath.Benefit_popular} exact>
          <BenefitPopular/>
        </Route>
        <Route path={RoutePath.Benefit_detail} exact>
          <BenefitDetail/>
        </Route>
        <Redirect to={RoutePath.Home} from="*"/>
      </Switch>
    </Suspense>
  )
}

export { SwitchBenefitPages }
