import { DateTime, Duration } from 'luxon'

function formatEventDate(dateString: string, format: string = 'yyyy.MM.dd') {
  const dateTime = DateTime.fromFormat(dateString, 'yyyy-MM-dd hh:mm:ss.0')
  if (!dateTime.isValid) {
    return ''
  }
  return dateTime.toFormat(format)
}

function formatTripTime(tripTime: number) {
  const hours = parseInt(String(tripTime / 3600))
  const minute = parseInt(String((tripTime % 3600) / 60))
  let time:string = '0분'

  if (tripTime === 0) return  time
  if (hours >= 1) {
    time = `${hours}시간`
    if (minute !== 0) {
      time += ` ${minute}분`
    }
    return time
  } else {
    return `${minute}분`
  }
}

function formatTripTimeDiff(tripStartTime: string, tripEndTime: string) {
  const startTime = new Date(tmapDateFormatter(tripStartTime).toFormat('yyyy-MM-dd HH:mm').replace(' ', 'T')).getTime()
  const endTime = new Date(tmapDateFormatter(tripEndTime).toFormat('yyyy-MM-dd HH:mm').replace(' ', 'T')).getTime()
  const diff = endTime - startTime
  const hours = parseInt(String(diff / (60 * 60 * 1000)))
  const minutes = parseInt(String((diff / 1000 / 60) % 60))
  let time: string

  if (hours >= 1) {
    time = `${hours}시간`
    if (minutes !== 0) {
      time += ` ${minutes}분`
    }
    return time
  } else {
    return `${minutes}분`
  }
}

function tmapDateFormatter(value: string) {
  let date = value
  if (value && value.length > 8) {
    date = value.slice(0, 8).concat('T').concat(value.slice(8))
  }
  return DateTime.fromISO(date).setLocale('ko')
}

function formatPastDatePeriod(date: string) {
  const now = DateTime.now().toFormat('yyyyMMdd')
  const start = DateTime.fromISO(date).toFormat('yyyyMMdd')
  const diff = DateTime.fromISO(now).diff(DateTime.fromISO(start).minus({ day: 1 }), 'months')
  const years = Math.floor(diff.as('years'))
  const months = diff.as('month') % 12

  if (!years && months < 1) {
    return `${Math.ceil(diff.as('days'))}일`
  } else if (years && months) {
    if (months === 0) {
      return `${years}년`
    } else {
      const ceilMonths = Math.ceil(months)
      if (ceilMonths === 12) {
        return `${years + 1}년`
      } else {
        return `${years}년 ${Math.ceil(months)}개월`
      }
    }
  } else if (!years && months) {
    const ceilMonths = Math.ceil(months)
    if (ceilMonths === 12) {
      return '1년'
    } else {
      return `${Math.ceil(months)}개월`
    }
  } else if (!months) {
    return `${years}년`
  }
}

function formatPastMonthPeriod(startDate: string, isException?: boolean) {
  const now = DateTime.now().toFormat('yyyy-MM')
  const start = DateTime.fromISO(startDate).toFormat('yyyy-MM')
  const diff = DateTime.fromISO(now).diff(DateTime.fromISO(start), 'months')
  const years = Math.floor(diff.as('years'))
  const months = (diff.as('month') % 12) + 1

  if (start === now) {
    return isException ? '1개월' : '이번달'
  } else if (months === 12) {
    if (years) {
      return `${years + 1}년`
    } else {
      return `1년`
    }
  } else if (years && months) {
    if (months === 12) {
      return `${years + 1}년`
    }
    return `${years}년 ${months}개월`
  } else if (!years) {
    return `${months}개월`
  } else {
    return ''
  }
}

function formatDiffBetweenPeriods(startDate: string | null, year: number) {
  if (!startDate) return
  const now = DateTime.now().toFormat('yyyyMMdd')
  const start = DateTime.fromISO(startDate)
  const end = start.plus({ year: year }).minus({ day: 1 }).toFormat('yyyyMMdd')
  let diff: Duration

  if (now > end) {
    // 지난 경우
    diff = DateTime.fromISO(now).diff(DateTime.fromISO(end), 'months')
  } else {
    // 남은 경우
    diff = DateTime.fromISO(end).diff(DateTime.fromISO(now), 'months')
  }

  const years = Math.floor(diff.as('years'))
  const months = Math.floor(diff.as('month') % 12)

  if (!years && months < 1) {
    return `${Math.ceil(diff.as('days'))}일`
  } else if (!years && months) {
    return `${months}개월`
  } else if (years && !months) {
    return `${years}년`
  } else {
    return `${years}년 ${months}개월`
  }
}

export { formatTripTime, formatEventDate, tmapDateFormatter, formatTripTimeDiff, formatPastDatePeriod, formatPastMonthPeriod, formatDiffBetweenPeriods }
