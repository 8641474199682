import React, { HTMLAttributes } from 'react'
import InsureDoc from '../../assets/images/lottie/insure_doc.json'
import { Player } from '@lottiefiles/react-lottie-player'

interface Props extends HTMLAttributes<HTMLDivElement> {}

function Loading(props: Props) {
  const { className, ...attrs } = props
  return (
    <div className={`insure_loading_indicator ${className}`} {...attrs}>
      <Player className="insure_loading_indicator_lottie" src={InsureDoc} autoplay loop={true}/>
      <p className="insure_loading_indicator_title">TMAP특약 가입을 위해<br/>보험사로 이동합니다</p>
    </div>
  )
}

export { Loading }
