import { ButtonTapHighlight } from '../Button'
import { ForwardedRef, forwardRef } from 'react'
import { Tooltip as _Tooltip } from '../../pages/Driving/vehicle/static/tooltip'
import { classNames } from '@tmap-web-lib/utils'

interface Props {
  id: string
  tooltip: _Tooltip
  handleClose: (id: string) => void
}

const Tooltip = forwardRef(({ id, tooltip, handleClose }: Props, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <div
      ref={ref}
      id={id}
      className="ly_tooltip"
    >
      {tooltip.title && (<h1 className="ly_tooltip_tit">{tooltip.title}</h1>)}
      <div className="ly_tooltip_cont">
        <ul className="ly_tooltip_desc">
          {tooltip.description.map((item, index) => {
            return (
              <li
                key={index}
                className={classNames('ly_tooltip_desc_item', tooltip.isNotBullet ? 'no_bullet' : '')}
              >
                {item}
              </li>
            )
          })}
        </ul>
        {tooltip.additional && <p className="ly_tooltip_additional">{tooltip.additional}</p>}
      </div>
      <ButtonTapHighlight
        isFullWidth={false}
        className="ly_tooltip_close"
        onClick={() => handleClose(id)}
      >
        닫기
      </ButtonTapHighlight>
    </div>
  )
})

export { Tooltip }
