import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'
import { getUserMessage, UserMessageResType } from '@tmap-web-lib/remote-api-client/frontman'

interface Options {
  enabled: boolean
  useErrorBoundary?: boolean
}

function useGetUserMessages(options?: Options) {
  return useQuery<UserMessageResType, AxiosError>([KEYS.USER_MESSAGES()],
    async () => await getUserMessage().then(({ data }) => data),
    {
      enabled: options?.enabled,
      useErrorBoundary: options?.useErrorBoundary ?? true
    }
  )
}

export { useGetUserMessages }
