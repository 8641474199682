import { DateTime } from 'luxon'

function getEndDate(startDate: string, endDate: string, isMonthComparison?: boolean) {
  const format = isMonthComparison ? 'yyyyMM' : 'yyyyMMdd'
  const start = DateTime.fromISO(startDate).toFormat(format)
  const end = DateTime.fromISO(endDate).toFormat(format)

  return DateTime.fromISO(end).diff(DateTime.fromISO(start), 'months')
}

export { getEndDate }
