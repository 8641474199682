import { CSSProperties, HTMLAttributes, PropsWithChildren, useMemo } from 'react'
import { classNames } from '@tmap-web-lib/utils'

type NotificationType = 'dot' | 'new' | 'counter'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLElement>> {
  notificationType: NotificationType
  count?: number
  styles?: CSSProperties
}

function Notification(props: Props) {
  const {
    notificationType,
    count,
    className,
    styles
  } = props

  const text = useMemo(() => {
    switch (notificationType) {
      case 'dot':
        return '최신정보'
      case 'new':
        return '신규'
      case 'counter':
        return count
    }
  }, [notificationType, count])

  return (
    <em
      className={classNames(`tmds-notification_${notificationType}`, className)}
      style={{ ...styles }}
    >
      {text}
    </em>
  )
}

export { Notification }