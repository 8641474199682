import qs from 'qs'
import { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  PageId,
  PageIdExtra,
  PageIdInfo,
  PageIdSearchParams,
  usePageIdContext,
} from '../../providers/page-id-context'
import { useOpenServiceUrl } from '../../hooks'
import { useGetTermsAgreement } from '../../react-query'
import { store } from '../../store'
import { selectSettingsIsCarLifeTermsAgreed, setIsCarLifeTermsAgreed } from '../../store/settings'
import { useSelector } from 'react-redux'
import { Dictionary, SearchParams } from '@tmap-web-lib/utils'
import { RoutePath } from '../paths'

interface Props extends PropsWithChildren<{}> {
}

function CheckPageId(props: Props) {
  const { children } = props
  const history = useHistory<{ firstHistory?: boolean } | undefined>()
  const pageIdContext = usePageIdContext()
  const openServiceUrl = useOpenServiceUrl()
  const isCarLifeTermsAgreed = useSelector(selectSettingsIsCarLifeTermsAgreed)

  const { data: carLifeTermsAllow, isInitialLoading } = useGetTermsAgreement({
    termsType: 'UBS01',
    termsAllowCode: 'UTERMS-B2C-1'
  })

  const [isReady, setReady] = useState(!pageIdContext.pageId)

  const replaceGeneralPage = useCallback((pageId: PageId, searchParams: SearchParams<PageIdSearchParams>, extraObject: PageIdExtra & Dictionary<string | number | boolean | undefined>, url: RoutePath | string, path: string) => {
    if (pageId === 'main') {
      history.replace({
        pathname: url,
        search: qs.stringify({ ...searchParams }),
      })
    } else if (pageId === 'insur_vehicle_all' && extraObject.logbox && extraObject.logbox.type === 'menu_all') {
      history.replace({
        pathname: url,
        search: qs.stringify({ ...searchParams }),
      })
    } else if (pageId === 'driving_history' && extraObject.logbox && extraObject.logbox.type === 'favorite') {
      history.replace({
        pathname: url,
        search: qs.stringify({ ...searchParams }),
      })
    } else {
      openServiceUrl(path)
    }
  }, [history, openServiceUrl])

  const movePageTo = useCallback((pageIdInfo: PageIdInfo<PageId>, extraObject: PageIdExtra & Dictionary<string | number | boolean | undefined>, searchParams: SearchParams<PageIdSearchParams>, encodedExtraObject: string, isTscoreScheme: boolean) => {
    if (pageIdInfo && !isInitialLoading) {
      const { url, pageId } = pageIdInfo
      const needTermsPage = pageId === 'driving_analysis' || pageId === 'vehicle' || pageId === 'vehicle_detail'
      let path = `${url}?extra=${encodedExtraObject}`

      if (isCarLifeTermsAgreed === false) {
        const extraObj = { ...extraObject, move_to: pageId }
        if (isTscoreScheme) {
          if (needTermsPage) {
            history.replace({
              pathname: '/web/etc/terms',
              search: qs.stringify({ ...extraObj }),
            })
          } else {
            history.replace({
              pathname: url,
              search: qs.stringify({ ...searchParams }),
            })
          }
        } else {
          if (needTermsPage) {
            openServiceUrl(`tmap://life?pageid=etc_terms&extra=${encodeURIComponent(JSON.stringify(extraObj))}`)
          } else {
            replaceGeneralPage(pageId, searchParams, extraObject, url, path)
          }
        }
      } else if (isCarLifeTermsAgreed === true) {
        if (isTscoreScheme) {
          history.replace({
            pathname: url,
            search: qs.stringify({ ...searchParams }),
          })
        } else {
          replaceGeneralPage(pageId, searchParams, extraObject, url, path)
        }
      }
      setReady(true)
    }
  }, [history, isCarLifeTermsAgreed, isInitialLoading, openServiceUrl, replaceGeneralPage])

  useEffect(() => {
    if (carLifeTermsAllow && carLifeTermsAllow.termsAgreements) {
      store.dispatch(setIsCarLifeTermsAgreed(carLifeTermsAllow.termsAgreements[0].allowYn === 'Y'))
    }
  }, [carLifeTermsAllow])

  useEffect(() => {
    const { extraObject, pageIdInfo, searchParams } = pageIdContext
    const encodedExtraObject = encodeURIComponent(JSON.stringify(extraObject))

    /**
     * 타 서비스에서 tscore를 사용한 상세 페이지를 열기를 원하면 반드시 targetScheme에 tscore 넣어서 호출하도록 가이드
     * 10.0 미만 버전에서 pageid가 insur_vehicle_all 일 때 전체탭>자동차보험의 extra 값으로 고정되어 있는 이슈
     * 10.0부터 사용자가 보내는 값으로 적용되도록 수정 예정
     *
     * tmap://tscore 스킴은 기본 랜딩 url이 '/insurance' 로 되어있기 때문에 다이렉트로 다른 페이지를 열어주려고 한다면 해당 url로 replace 해주어야 함.
     */
    const isTscoreScheme = extraObject.targetScheme === 'tscore'
    movePageTo(pageIdInfo, extraObject, searchParams, encodedExtraObject, isTscoreScheme)
  }, [pageIdContext, movePageTo])

  return isReady ? <>{children}</> : null
}

export { CheckPageId }