import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { useCallback } from 'react'
import { useShowInsureLoading } from '../components/Loading/hooks'

function useOpenInsureLink() {
  const showInsureLoading = useShowInsureLoading()
  return useCallback((srcUrl: string) => {
    if (!srcUrl) {
      return
    }

    showInsureLoading(
      // 로딩 인디케이터 보여주기 위한 지연
      new Promise(resolve => setTimeout(() => resolve(true), 3000))
        .then(() => TmapApp.openBrowser({ url: srcUrl }))
    )
  }, [showInsureLoading])
}

export { useOpenInsureLink }
