import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  getOuterCarInfo,
} from '@tmap-web-lib/remote-api-client/frontman'
import { AxiosError } from 'axios'
import { BaseCarInfo, OuterCarInfoResponseData } from '@tmap-web-lib/remote-api-client/esc-proxy'

function useGetOuterCarInfo() {
  const queryClient = useQueryClient()

  return useMutation<OuterCarInfoResponseData, AxiosError, BaseCarInfo>(
    (data) => getOuterCarInfo(data).then(({ data }) => data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries()
      }
    }
  )
}

export { useGetOuterCarInfo }
