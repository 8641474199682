import { ForwardedRef, forwardRef, HTMLAttributes, PropsWithChildren } from 'react'
import { classNames } from '@tmap-web-lib/utils'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  variant?: 'default' | 'scrollable' | 'capsule'
}

const Tabs = forwardRef((props: Props, ref?: ForwardedRef<HTMLUListElement>) => {
  const {
    variant = 'default',
    children,
    ...attr
  } = props

  return (
    <div
      className={classNames('tmds-tab', variant)}
      {...attr}
    >
      <ul
        ref={ref}
        className="tmds-tab_tray"
      >
        {children}
      </ul>
    </div>
  )
})

export { Tabs }