import { epsg4162ToEpsg4326 } from '../pages/History/detail/projUtil'
import { Point } from '@vsm/vsm'
import { GeoCoordinates } from '../pages/History/detail/Detail'


function toPoint(strCoords: string): Point {
  const [x, y] = strCoords.split(',').map(str => parseInt(str, 10))
  return { x: x / 360000, y: y / 360000 }
}

function convertSkBesselListToWgs84List(trace: string) {
  const traceCoordinates = trace.split(':')
  let lonVal = 0
  let latVal = 0

  return traceCoordinates.map((data) => {
    const lonLatStrArray = data.split(',')
    lonVal = lonVal + Number(lonLatStrArray[0])
    latVal = latVal + Number(lonLatStrArray[1])

    // WGS84 좌표계로 변환
    const wgs84 = epsg4162ToEpsg4326(toPoint(`${lonVal},${latVal}`))

    return [wgs84.x, wgs84.y]
  })
}

function getCenter(start: GeoCoordinates, end: GeoCoordinates) {
  const center: [number, number] = [
    (start.longitude + end.longitude) / 2,
    (start.latitude + end.latitude) / 2
  ]
  return center
}

function getMaxMapBounds({ latList, lngList }: { latList: number[]; lngList: number[] }): [any, any] {
  const maxLat = Math.max(...latList)
  const minLat = Math.min(...latList)
  const maxLng = Math.max(...lngList)
  const minLng = Math.min(...lngList)
  const swBound: any = { lng: minLng, lat: minLat }
  const neBound: any = { lng: maxLng, lat: maxLat }

  return [swBound, neBound]
}


export { toPoint, convertSkBesselListToWgs84List, getCenter, getMaxMapBounds }
