import { useMemo, useRef } from 'react'
import debounce from 'lodash-es/debounce'

function useTabActivity() {
  const tabRef = useRef<HTMLUListElement | null>(null)

  const handleTabActivity = useMemo(() => debounce((index: number) => {
    if (!tabRef.current) return
    const ref = tabRef.current!
    const wrapWidth = ref.offsetWidth
    const wrapScrollWidth = ref.scrollWidth
    const wrapScrollLeft = ref.scrollLeft
    const gap = wrapScrollWidth - wrapWidth

    if (gap <= 0) return
    const target = ref.children[index]
    const targetWidth = target.getBoundingClientRect().width
    const targetLeft = target.getBoundingClientRect().left - ref.getBoundingClientRect().left
    const targetCenter = (targetLeft - (wrapWidth / 2)) + (Math.round(targetWidth / 2) + wrapScrollLeft)

    ref?.scrollTo({ behavior: 'smooth', left: targetCenter })
  }, 100), [tabRef])
  
  return {
    tabRef,
    handleTabActivity
  }
}

export { useTabActivity }