import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { ButtonTapHighlight, DialogEmergencyCall, Notification } from '../../components'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'
import { RedDotLog, useOpenServiceUrl } from '../../hooks'
import { MenuSetType } from '../../utils'
import { CarProfile, CodeListResData } from '@tmap-web-lib/remote-api-client/frontman'
import { useGetInsurerInfo } from '../Etc/hook'
import { useSelector } from 'react-redux'
import {
  selectSettingsEmergencyCallChecked,
  setEmergencyCallChecked,
} from '../../store/settings'
import { store } from '../../store'
import { useDialogManager } from '@tmap-web-lib-close/dialog-manager/react-router'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import debounce from 'lodash-es/debounce'

interface Props {
  carProfile: CarProfile
  menuList?: MenuSetType[]
  carOptionCodeList?: CodeListResData
  isHideIntroBanner: boolean
  redDotLog: RedDotLog
  handleSetMenuList: (menu: MenuSetType) => void
  eventLog: () => TmapLogBuilder
}

function MainMenuSet(props: Props) {
  const {
    carProfile,
    menuList,
    carOptionCodeList,
    isHideIntroBanner,
    redDotLog,
    handleSetMenuList,
    eventLog
  } = props

  const emergencyCallChecked = useSelector(selectSettingsEmergencyCallChecked)

  const ref = useRef<HTMLUListElement | null>(null)

  const dialogManager = useDialogManager()
  const openServiceUrl = useOpenServiceUrl()
  const insurance = useGetInsurerInfo({ carProfile, carOptionCodeList })

  const handleScroll = useMemo(() => debounce(() => {
    const screenWidth = window.screen.width
    const scrollWidth = ref.current!.scrollWidth

    setTimeout(() => {
      ref.current?.scrollTo({ behavior: 'smooth', left: (scrollWidth - screenWidth) })
      TmapApp.makeToast({ msg: '긴급출동 메뉴가 업데이트됐어요' })
      store.dispatch(setEmergencyCallChecked(insurance.insurer?.value || ''))
    }, 200)
  }, 300), [insurance])

  const handleMenuClick = useCallback((menu: MenuSetType) => {
    if (redDotLog === null) return
    const log = eventLog().set('action_id', menu.action_id).addCustomField('reddot', redDotLog)
    // 보험정보가 등록된 경우에는 바텀시트로 열고 등록이 안된 경우에는 등록유도 페이지를 연다
    if (menu.id === 'EMERGENCY_CALL') {
      if (carProfile.insurance?.insurer) {
        log.addCustomField('registration_status', '1').send()
        dialogManager.showDialog({
          component: DialogEmergencyCall,
        })
        return
      } else if (carProfile) {
        log.addCustomField('registration_status', '2').send()
      } else {
        log.addCustomField('registration_status', '3').send()
      }
      openServiceUrl(menu.link)
    } else {
      log.send()
      handleSetMenuList(menu)
    }
  }, [carProfile, redDotLog, openServiceUrl, dialogManager, handleSetMenuList, eventLog])

  useEffect(() => {
    // 인트로팝업이 있으면 팝업이 닫히고 스크롤 + 토스트팝업 동작
    // 보험사를 변경하여도 스크롤 + 토스트팝업 동작
    if (!ref.current || !menuList || !isHideIntroBanner || !insurance.insurer?.value || emergencyCallChecked === insurance.insurer?.value) return
    handleScroll()
  }, [menuList, isHideIntroBanner, insurance, emergencyCallChecked, handleScroll])

  if (!menuList) return null

  return (
    <ul
      ref={ref}
      className="main_menu"
    >
      {menuList.map((menu) => {
        return (
          <li
            key={menu.id}
            className="main_menu_item"
          >
            <ButtonTapHighlight
              className="main_menu_item_btn"
              borderRadius={12}
              onClick={() => handleMenuClick(menu)}
            >
              {/* 긴급호출 - 보험정보가 등록된 경우 보험사 이미지로 변경해서 노출 */}
              {((menu.id === 'EMERGENCY_CALL') && insurance.description?.iconUrl) ? (
                <img src={insurance.description.iconUrl} width="16" height="16" alt=""/>
              ) : (
                <img src={require(`@/assets/images/${menu.img}.svg`).default} width="16" height="16" alt=""/>
              )}
              <span className="main_menu_item_label">
                {menu.title}
                {menu.isRedDot && (
                  <Notification
                    notificationType={'dot'}
                    className="main_menu_item_dot"
                  />
                )}
              </span>
            </ButtonTapHighlight>
          </li>
        )
      })}
    </ul>
  )
}

export { MainMenuSet }
