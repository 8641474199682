import { useEffect } from 'react'
import { setRootScrollTop} from '@tmap-web-lib/utils'

function ScrollTop() {
  useEffect(() => {
    setRootScrollTop(0)
  }, [])

  return null
}

export { ScrollTop }
