import {
  FuelType,
  GearBoxType,
  VehicleModel,
} from '@tmap-web-lib/remote-api-client/frontman'

export const getCarModelCodeByHighwayNum: {
  model: VehicleModel | null
  name: string
}[] = [
  {
    model: 'CT_SMALL_TRUCK',
    name: '소형화물차',
  },
  {
    model: 'CT_NORMAL',
    name: '승용차',
  },
  {
    model: 'CT_MIDDLE',
    name: '중형차',
  },
  {
    model: 'CT_LARGE',
    name: '대형차',
  },
  {
    model: 'CT_TRUCK',
    name: '대형화물차',
  },
  {
    model: 'CT_SPECIAL',
    name: '특수화물차',
  },
  {
    model: 'CT_COMPACT',
    name: '경차',
  },
]

export const codeToFuelName = (code: FuelType | null | '') => {
  switch (code) {
    case 'FT_GAS':
      return '휘발유'
    case 'FT_DIESEL':
      return '경유'
    case 'FT_LPG':
      return 'LPG'
    case 'FT_GASPM':
      return '고급휘발유'
    case 'FT_EV':
      return '전기'
    case 'FT_HYD':
    case 'FT_H2':
      return '수소'
    case '':
    case null:
      return ''
    default:
      return '휘발유'
  }
}

export const nameToFuelCode = (type: string | undefined): FuelType | 'FT_H2' | '' => {
  switch (type) {
    case 'LPG':
    case 'LPG+가솔린':
    case 'LPG+전기':
      return 'FT_LPG'
    case '휘발유':
    case '가솔린':
    case '가솔린+전기':
      return 'FT_GAS'
    case '고급휘발유':
      return 'FT_GASPM'
    case '경유':
    case '디젤':
    case '디젤+전기':
      return 'FT_DIESEL'
    case '수소+전기':
    case '전기':
      return 'FT_EV'
    case '수소':
      return 'FT_H2'
    default:
      return ''
  }
}

export const nameToGearBoxCode = (code: string | undefined): GearBoxType | null => {
  switch (code) {
    case '오토':
    case '자동':
    case 'CVT':
      return 'AUTO'
    case '수동':
      return 'MANUAL'
    default:
      return null
  }
}

export const getProperCurbWeight = (str: string) => {
  // 해당 함수는 차량 중량 값 데이터를 정제하기 위한 용도로,
  // 오토비긴즈에서 내려주는 중량값이 1234(1235), 1234/1235/1236 와 같이 나오는 값을 1234 와 같이 유효한 형태로 정제하기 위한 용도임
  // 쉼표 제거
  const strippedStr = str.replace(/,/g, '')
  // 괄호 안의 숫자와 '/' 뒤의 모든 문자열 제거
  const cleanedStr = strippedStr.replace(/\([^()]+\)|\/.*/g, '')
  // 숫자로 변환하여 반환, 변환이 불가능한 경우 null 반환
  const number = Number(cleanedStr)
  return !number && isNaN(number) ? null : number.toString()
}

const TRUCK_CAR_NUMBER_REGEX =
  /^[8-9][0-9][가나다라마거너더러머버서어저고노도로모보소오조구누두루무부수우주바사아자배허하호국합육해공][0-9]{4}$/
const TRUCK_CAR_NUMBER_REGEX2 =
  /^(80[0-9]|8[1-9][0-9]|9[0-8][0-9]|99[0-7])[가나다라마거너더러머버서어저고노도로모보소오조구누두루무부수우주바사아자배허하호국합육해공][0-9]{4}$/
const IS_COMMERCIAL_TRUCK_REGEX = /[바사아자배]/
const LEGACY_TRUCK_CAR_NUMBER_REGEX =
  /^(?:서울|부산|대구|인천|대전|광주|울산|제주|경기|강원|충남|충북|전남|전북|경남|경북|세종)[80-99]{2}[가나다라마거너더러머버서어저고노도로모보소오조구누두루무부수우주바사아자배허하호국합육해공][0-9]{4}$/

export function isCommercialTruck(carNumber: string) {
  return IS_COMMERCIAL_TRUCK_REGEX.test(carNumber)
}

export function isTruckCarNumber(carNumber: string) {
  return TRUCK_CAR_NUMBER_REGEX.test(carNumber) || TRUCK_CAR_NUMBER_REGEX2.test(carNumber) || LEGACY_TRUCK_CAR_NUMBER_REGEX.test(carNumber)
}

