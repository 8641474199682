import { Dictionary, getSearchParams } from '@tmap-web-lib/utils'
import { createContext, PropsWithChildren, useMemo } from 'react'
import UrlParse from 'url-parse'
import isEmpty from 'lodash-es/isEmpty'
import { pageIdInfos } from './page-id-infos'
import { PageId, PageIdContext, PageIdExtra, PageIdSearchParams } from './types'

type Props = PropsWithChildren<{}>

const Context = createContext({} as PageIdContext)

function PageIdContextProvider(props: Props) {
  const { children } = props
  const pageIdContext = useMemo(() => createPageIdContext(), [])

  return (
    <Context.Provider value={pageIdContext}>
      <>{children}</>
    </Context.Provider>
  )
}

function createPageIdContext() {
  const urlObject = new UrlParse(window.location.href)
  const searchParams = getSearchParams<PageIdSearchParams>()
  const { pageid, extra } = searchParams
  const defaultExtra = isEmpty(extra) ? JSON.stringify({ logbox: { type: 'tab', origin: 'tab' } }) :  extra && decodeURIComponent(extra)
  let extraObject: PageIdExtra & Dictionary<string | number | boolean | undefined>
  try {
    extraObject = JSON.parse(defaultExtra as string)
  } catch {
    extraObject = {}
  }
  return {
    pageIdInfo: pageIdInfos[pageid as PageId] || null,
    pageId: pageid,
    extra,
    extraObject: extraObject,
    searchParams,
    searchParamsString: urlObject.query.replace(/^\?/, ''),
  }
}

export { PageIdContextProvider, Context, createPageIdContext }
