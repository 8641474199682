import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'
import { ReportsResponseData, getReports } from '@tmap-web-lib/remote-api-client/frontman'
import { mockHost } from '../../api'
import { useSelector } from 'react-redux'
import { selectSettingsMockEnabled } from '../../store/settings'

interface Options {
  useErrorBoundary?: boolean
}

function useGetDrivingScoreReports(options?: Options) {
  const isMockEnabled = useSelector(selectSettingsMockEnabled)

  return useQuery<ReportsResponseData, AxiosError>([KEYS.DRIVING_SCORE_REPORTS()],
    async () => {
      if (JSON.parse(isMockEnabled as string)) {
        const { data } = await mockHost.get<ReportsResponseData>('/ubds/trip-report/v1/reports/$userKey')
        return data
      } else {
        const { data } = await getReports()
        return data
      }
    },
    {
      useErrorBoundary: options?.useErrorBoundary ?? true,
    },
  )
}

export { useGetDrivingScoreReports }
