import React from 'react'
import { DialogFrame, DialogContent, DialogOkButton, DialogFooter, DialogCustom } from './Elements'
import { DialogOkProps } from './types'

function DialogOk(props: DialogOkProps) {
  const {
    isCustom = true,
    type,
    title,
    content,
    okText,
    onOk,
  } = props

  return (
    <DialogFrame
      type={type}
      content={isCustom ? (
        <DialogCustom title={title} content={content}/>
      ) : (
        <DialogContent title={title} content={content}/>
      )}
      buttonSet={
        <DialogFooter okButton={
          <DialogOkButton
            borderRadius={'0px'}
            onClick={onOk}
          >
            {okText}
          </DialogOkButton>
        }/>
      }
    />
  )
}

export default DialogOk
export { DialogOk }
