import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { RoutePath } from './paths'
import { lazyRetry } from '../utils/lazy-retry'

const Terms = lazyRetry(() => import('../pages/Etc/Terms'))
const InsurerContact = lazyRetry(() => import('../pages/Etc/InsurerContact'))
const EmergencyCall = lazyRetry(() => import('../pages/Etc/EmergencyCall'))

function SwitchEtcPages() {
  return (
    <Suspense fallback="">
      <Switch>
        <Route path={RoutePath.Etc_terms} exact>
          <Terms/>
        </Route>
        <Route path={RoutePath.Etc_emergency_call} exact>
          <EmergencyCall/>
        </Route>
        <Route path={RoutePath.Etc_insurer_contact} exact>
          <InsurerContact/>
        </Route>
        <Redirect to={RoutePath.Home} from="*"/>
      </Switch>
    </Suspense>
  )
}

export { SwitchEtcPages }
