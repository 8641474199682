import { useSelector } from 'react-redux'
import { selectSettingsEuk } from '../../store/settings'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'
import { CharanchaCarResData, getCharanchaRecentlyViewedCarList } from '@tmap-web-lib/remote-api-client/frontman'

interface Props {
  useErrorBoundary?: boolean
}

function useGetRecentlyViewedCarList(props: Props) {
  const { useErrorBoundary } = props
  const euk = useSelector(selectSettingsEuk)

  return useQuery<CharanchaCarResData, AxiosError>([KEYS.CHARANCHA_RECETNLY_VIEWED_CAR_LIST()],
    () => getCharanchaRecentlyViewedCarList(euk).then(({ data }) => data),
    {
      useErrorBoundary: useErrorBoundary ?? true,
    },
  )
}

export { useGetRecentlyViewedCarList }
