import React, { useCallback, useMemo, useRef, useState } from 'react'
import {
  DialogBackground,
  DialogCustom,
  DialogFooter,
  DialogOkButton,
} from './Elements'
import { DialogWithAnimationProps } from './types'
import { classNames } from '@tmap-web-lib/utils'
import { CSSTransition } from 'react-transition-group'

interface Props extends DialogWithAnimationProps {
  type: string
}

function DialogTip(props: Props) {
  const { type, onOk } = props

  const ref = useRef<HTMLDivElement>(null)
  const backgroundRef = useRef(null)
  const [modalIsOpen, setModalIsOpen] = useState(true)

  const renderTip = useMemo(() => {
    switch (type) {
      case 'TIRE':
        return (
          <div className="popup_tip">
            <h1 className="popup_tip_tit">타이어 마모도 점검 팁</h1>
            <p className="popup_tip_img">
              <img
                src={require('../../assets/images/img_carcare_tire_guide.svg').default}
                alt=""
              />
            </p>
            <p className="popup_tip_desc">타이어 홈에 백원짜리 동전을 거꾸로 끼워 보고 ‘감투' 부분이 2/3 이상 보이면 점검이 필요해요.</p>
          </div>
        )
      case 'AIR_FILTER':
        return (
          <div className="popup_tip">
            <h1 className="popup_tip_tit">엔진에어필터란?</h1>
            <p className="popup_tip_img">
              <img
                src={require('../../assets/images/img_carcare_airfilter_guide.svg').default}
                alt=""
              />
            </p>
            <p className="popup_tip_desc">엔진으로 들어오는 공기의 불순물을 제거하는 필터를 뜻해요. 필터가 오염되면 이물질로 인한 엔진마모가 발생하고 출력 저하와 불필요한 연료소모가 발생할 수 있어요.</p>
          </div>
        )
    }
  }, [type])

  const handleConfirm = useCallback(() => {
    setModalIsOpen(false)
    onOk?.()
  }, [onOk])

  return (
    <CSSTransition
      classNames="dimmed-fade-in"
      unmountOnExit={true}
      timeout={300}
      in={modalIsOpen}
      nodeRef={backgroundRef}
      appear
    >
      <DialogBackground ref={backgroundRef}>
        <CSSTransition
          classNames="bottom-slide-up"
          unmountOnExit={true}
          timeout={200}
          in={modalIsOpen}
          nodeRef={ref}
          appear
        >
          <div ref={ref} className={classNames('popup_wrap', 'popup_bottom')}>
            <div className={classNames('popup_cont')}>
              <DialogCustom
                className={'no_padding'}
                content={renderTip}
              />
            </div>
            <DialogFooter
              okButton={<DialogOkButton styleFontWeight={'medium'} onClick={handleConfirm} />}
            />
          </div>
        </CSSTransition>
      </DialogBackground>
    </CSSTransition>
  )
}

export default DialogTip
export { DialogTip }
