import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DialogBackground, DialogCancelButton, DialogContent, DialogFooter, DialogOkButton } from './Elements'
import { DialogWithAnimationProps } from './types'
import { DialogOk } from './DialogOk'
import { useDialogManager } from '@tmap-web-lib-close/dialog-manager/react-router'
import { classNames, getSearchParams } from '@tmap-web-lib/utils'
import isEmpty from 'lodash-es/isEmpty'
import { useLogBox, useOpenService } from '../../hooks'
import { clearError } from '../../store/app'
import { TERMS_LOG_PRESET, TERMS_REGISTER_VEHICLE_LOG_PRESET } from '../../hooks/logbox/preset'
import { PageIdSearchParams } from '../../providers/page-id-context'
import { useSelector } from 'react-redux'
import { selectSettingsIsCarLifeTermsAgreed } from '../../store/settings'
import { CSSTransition } from 'react-transition-group'
import { store } from '../../store'
import { TermsContents } from '../../pages/Etc'
import { useTerms } from '../../pages/Etc/hook'
import { CARPROFILE_SCHEME } from '../../utils'
import debounce from 'lodash-es/debounce'

function DialogGeneralTerms(props: DialogWithAnimationProps) {
  const {
    wrapperClassName,
    title,
    onOk,
    onCancel,
    okText,
    isDialogOpen,
  } = props

  const dialogManager = useDialogManager()
  const openService = useOpenService()

  // 주행데이터 약관 동의 여부
  const isCarLifeTermsAgreed = useSelector(selectSettingsIsCarLifeTermsAgreed)
  const ref = useRef(null)
  const backgroundRef = useRef(null)

  const [modalIsOpen, setModalIsOpen] = useState(true)
  const [isEnd, setIsEnd] = useState<boolean | null>(null)

  const [termsEventLog, termsExposeLog] = useLogBox(['event', 'expose'], {
    attributesPreset: TERMS_LOG_PRESET.ATTRIBUTES,
    customFieldsPreset: TERMS_LOG_PRESET.CUSTOM_FIELDS,
    withEuk: true
  })
  const [registerVehicleEventLog, registerVehicleExposeLog] = useLogBox(['event', 'expose'], {
    attributesPreset: TERMS_REGISTER_VEHICLE_LOG_PRESET.ATTRIBUTES,
    customFieldsPreset: TERMS_REGISTER_VEHICLE_LOG_PRESET.CUSTOM_FIELDS,
    withEuk: true
  })
  const [eventLog] = useMemo(() => {
    if (isCarLifeTermsAgreed && !isDialogOpen) {
      return [registerVehicleEventLog]
    } else {
      return [termsEventLog]
    }
  }, [isCarLifeTermsAgreed, isDialogOpen, registerVehicleEventLog, termsEventLog])

  const handleError = useCallback(() => {
    dialogManager.showDialog(
      {
        component: DialogOk,
        props: {
          title: <>일시적으로 서비스를<br/> 이용할 수 없습니다.<br/> <p className="sub">앱을 다시 실행해주세요.</p></>,
          onOk() {
            setModalIsOpen(false)
            store.dispatch(clearError())
          }
        },
      }
    )
  }, [dialogManager])

  const {
    isAllChecked,
    termsList,
    termsGroupData,
    handleAgreements,
    handleAllTermsChecked,
    handleChecked,
  } = useTerms({ eventLog, handleError, onOk, isDialogOpen, setModalIsOpen })

  const handleRegisterClick = useCallback(() => {
    eventLog().set('action_id', 'tap.register_mycar').send()
    const extra = {
      serviceName: 'life',
      logbox: {
        type: 'tmap',
        origin: 'life'
      },
      action: {
        actionType: 'close'
      },
      pageType: [
        'manual-input-off'
      ]
    }
    onCancel?.()
    const url = `${CARPROFILE_SCHEME}?pageid=register_car_profile&extra=${encodeURIComponent(JSON.stringify(extra))}`
    openService(url)
  }, [eventLog, openService, onCancel])

  const displayTerms = useMemo(() => {
    if (!termsList.length) return null
    return (
      <>
        <DialogContent
          title={title}
          content={
            <>
              <button
                className={classNames('terms_checkbox_all', isAllChecked ? 'checked' : '')}
                onClick={handleAllTermsChecked}
              >
                <span className={classNames('terms_checkbox_all_label', isAllChecked ? 'checked' : '')}>
                  모두 동의합니다
                </span>
              </button>
              <TermsContents
                termsList={termsList}
                handleChecked={handleChecked}
                eventLog={eventLog}
              />
            </>
          }
        />
        <DialogFooter
          okButton={
            <DialogOkButton
              styleFontWeight={'medium'}
              className={!termsList[0].isChecked ? 'clickable-disabled' : ''}
              onClick={handleAgreements}
            >
              {okText || '다음'}
            </DialogOkButton>
          }
        />
      </>
    )
  }, [title, okText, isAllChecked, termsList, handleAllTermsChecked, handleChecked, handleAgreements, eventLog])

  const displayVehicleRegistration = useMemo(() => {
    return (
      <>
        <DialogContent
          title={
            <div className="vehicle_register_title">
              이제 운전점수가 생성돼요<br/>
              내 차 등록하고 관리도 해보세요
            </div>
        }
          content={
            <>
              <div className="vehicle_register_cont_img">
                <img src={require('../../assets/images/img_carcare_item_tire.svg').default} width={40} alt=""/>
                <img src={require('../../assets/images/img_carcare_item_oil.svg').default} width={40} alt=""/>
                <img src={require('../../assets/images/img_carcare_item_repair.svg').default} width={40} alt=""/>
                <img src={require('../../assets/images/img_carcare_item_insure.svg').default} width={40} alt=""/>
              </div>
              <p className="vehicle_register_cont_desc">리콜정보, 차량용품 교체 시기 안내까지</p>
            </>
          }
        />
        <DialogFooter
          okButton={
            <DialogOkButton className="vehicle_register_btn" onClick={handleRegisterClick}>
              내차등록하기
            </DialogOkButton>
          }
          cancelButton={
            <DialogCancelButton className="vehicle_register_cancel" onClick={() => {
              eventLog().set('action_id', 'tap.close').send()
              onCancel?.()
            }}>
              괜찮아요
            </DialogCancelButton>
          }
        />
      </>
    )
  }, [handleRegisterClick, onCancel, eventLog])

  const displayContent = useMemo(() => {
    if (termsGroupData) {
      if (!isCarLifeTermsAgreed) {
        return displayTerms
      } else {
        if (isDialogOpen !== undefined) {
          if (!isDialogOpen) {
            return displayVehicleRegistration
          } else {
            return null
          }
        }
      }
    }
  }, [termsGroupData, isCarLifeTermsAgreed, displayTerms, displayVehicleRegistration, isDialogOpen])

  const onLog = useMemo(() => debounce(() => {
    if (isCarLifeTermsAgreed && !isEnd) {
      registerVehicleExposeLog().send()
    } else {
      if (isEnd === null) {
        termsExposeLog().send()
      }
    }
  }, 100), [isCarLifeTermsAgreed, isEnd, termsExposeLog, registerVehicleExposeLog])

  const handleLog = useCallback(() => {
    onLog()
  }, [onLog])

  useEffect(() => {
    if (isCarLifeTermsAgreed && isDialogOpen) {
      setModalIsOpen(false)
    }
  }, [isCarLifeTermsAgreed, isDialogOpen, onOk])

  useEffect(() => {
    setIsEnd(!!isDialogOpen)
  },[isDialogOpen])

  useEffect(() => {
    const searchParams = getSearchParams<PageIdSearchParams>()

    if (!isEmpty(searchParams) && searchParams.extra) {
      const extraObject = JSON.parse(searchParams.extra as string)
      const extraCustomFields = extraObject.logbox

      if (extraCustomFields) {
        Object.keys(extraCustomFields).forEach(key => {
          if (isCarLifeTermsAgreed) {
            TERMS_REGISTER_VEHICLE_LOG_PRESET.CUSTOM_FIELDS.push([key, extraCustomFields[key]])
          } else {
            TERMS_LOG_PRESET.CUSTOM_FIELDS.push([key, extraCustomFields[key]])
          }
        })
      }
    } else {
      if (isCarLifeTermsAgreed) {
        TERMS_REGISTER_VEHICLE_LOG_PRESET.CUSTOM_FIELDS.push(['type', 'tab'], ['origin', 'tab'])
      } else {
        TERMS_LOG_PRESET.CUSTOM_FIELDS.push(['type', 'tab'], ['origin', 'tab'])
      }
    }
    handleLog()
  }, [isCarLifeTermsAgreed, handleLog])

  if (!termsList) return null

  return (
    <CSSTransition
      classNames="dimmed-fade-in"
      unmountOnExit={true}
      timeout={300}
      in={modalIsOpen}
      nodeRef={backgroundRef}
      appear
    >
      <DialogBackground className={wrapperClassName} ref={backgroundRef}>
        <CSSTransition
          classNames="bottom-slide-up"
          unmountOnExit={true}
          timeout={200}
          in={modalIsOpen}
          nodeRef={ref}
          appear
        >
          <div ref={ref} className={classNames('popup_wrap', 'popup_bottom', 'popup_terms_form')}>
            <div className={classNames('popup_cont')}>
              {displayContent}
            </div>
          </div>
        </CSSTransition>
      </DialogBackground>
    </CSSTransition>
  )
}

export default DialogGeneralTerms
export { DialogGeneralTerms }
