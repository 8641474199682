import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'
import {
  getTrips,
  TripsListReqParams,
  TripsListResData,
} from '@tmap-web-lib/remote-api-client/frontman'
import { mockHost } from '../../api'
import { useSelector } from 'react-redux'
import { selectSettingsMockEnabled } from '../../store/settings'

interface Options {
  enabled: boolean
  useErrorBoundary?: boolean
}

function useGetTripSafeDrivingInfo(props: TripsListReqParams, options?: Options) {
  const {
    nextToken,
    lastMonth,
    createdAt,
    includeOriginCoord,
  } = props
  const isMockEnabled = useSelector(selectSettingsMockEnabled)

  return useQuery<TripsListResData, AxiosError>([KEYS.TRIP_SAFE_DRIVING_INFO(createdAt)],
    async () => {
      if (JSON.parse(isMockEnabled as string)) {
        const { data } = await mockHost.get<TripsListResData>('/ubds/trip/v1/trips')
        return data
      } else {
        const { data } = await getTrips({ nextToken, lastMonth, createdAt, includeOriginCoord })
        return data
      }
    },
    {
      enabled: options?.enabled,
      useErrorBoundary: options?.useErrorBoundary ?? true,
    },
  )
}

export { useGetTripSafeDrivingInfo }
