import { useCallback, useEffect, useState } from 'react'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { gte } from 'semver'
import { RedDotListData } from '@tmap-web-lib/tmap-app-interface/src/types'
import { tmapDateStringToDateTime } from '../utils'
import { DateTime } from 'luxon'

export type RedDotLog = string | null

function useGetRedDotList() {
  const [isLoading, setLoading] = useState(true)
  const [redDotList, setRedDotList] = useState<RedDotListData[]>()
  const [redDotLog, setRedDotLog] = useState<RedDotLog>(null)

  const getRedDotList = useCallback(() => {
    if ((TmapApp.env.isAndroid && gte(TmapApp.env.appVersion, '9.7.0')) || (TmapApp.env.isIOS && gte(TmapApp.env.appVersion, '9.6.0'))) {
      TmapApp.getRedDotList().then((redDotList) => {
        const result = redDotList.filter((data) =>
          data.itemGroup === 'DRIVING_LIFE'
          && data.displayType === 'REDDOT'
          && (tmapDateStringToDateTime(data.expiredDateTime) > DateTime.now())
          && !data.readDateTime
        )
        setLoading(false)
        setRedDotList(result)
      }).finally(() => {
        setLoading(false)
      })
    }
  }, [])

  const updateRedDotList = useCallback((itemName: string) => {
    TmapApp.updateRedDotList({
      updateData: [{
        itemGroup: 'DRIVING_LIFE',
        item: itemName,
        displayType: 'REDDOT',
        readDateTime: DateTime.now().toFormat('yyyyMMddHHmmss')
      }]
    })
  }, [])

  const getRedDot = useCallback((itemName: string) => {
    return redDotList?.findIndex((redDot) => {
      const name = redDot.item.split('_')[0]
      return name === itemName
    })
  }, [redDotList])

  useEffect(() => {
    if (!isLoading) {
      const hasScore = getRedDot('SCORE')
      const hasMyCar = getRedDot('MYCAR')

      if ((hasScore !== -1) && (hasMyCar !== -1)) {
        setRedDotLog('y_운전점수변동/y_내차관리상태변경')
      } else if (hasScore !== -1) {
        setRedDotLog('y_운전점수변동')
      } else if (hasMyCar !== -1) {
        setRedDotLog('y_내차관리상태변경')
      } else {
        setRedDotLog('n')
      }
    }
  }, [isLoading, getRedDot])

  useEffect(() => {
    getRedDotList()
  }, [getRedDotList])

  return { redDotList, redDotLog, isLoading, getRedDotList, updateRedDotList }
}

export { useGetRedDotList }