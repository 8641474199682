import React, { useCallback, useEffect } from 'react'
import { useOpenServiceUrl } from '../../hooks'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'
import { useInView } from 'react-intersection-observer'

interface Props {
  eventLog: () => TmapLogBuilder
}

function MainSettings(props: Props) {
  const { eventLog } = props
  const openServiceUrl = useOpenServiceUrl()
  const { ref, inView, entry } = useInView({
    threshold: 1,
    triggerOnce: true,
  })

  const handleClickSettings = useCallback(() => {
    eventLog().set('action_id', 'tap.setting').send()
    openServiceUrl('tmap://life?pageid=settings')
  },[eventLog, openServiceUrl])

  useEffect(() => {
    if (inView) {
      const actionId = entry?.target.getAttribute('data-log') || ''
      eventLog().set('action_id', actionId).send()
    }
  }, [inView, entry, eventLog])

  return (
    <button
      className="main_settings_btn"
      data-log={'view.setting'}
      onClick={handleClickSettings}
      ref={ref}
    >
      카라이프설정
    </button>
  )
}

export { MainSettings }
