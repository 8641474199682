import { lazy } from 'react'

const lazyRetry = (componentImport: any) =>
  lazy(async () => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retryLazyRefreshed') || 'false'
    )
    try {
      const component = await componentImport()
      window.sessionStorage.setItem('retryLazyRefreshed', 'false')
      return component
    } catch (error) {
      if (!hasRefreshed) {
        window.sessionStorage.setItem('retryLazyRefreshed', 'true')
        return window.location.reload()
      }
      throw error
    }
  })

export { lazyRetry }
