import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { deleteTermsAgreement } from '@tmap-web-lib/remote-api-client/frontman'
import { trackErrorLogs } from '../../utils'
import { useSelector } from 'react-redux'
import { selectSettingsMockEnabled } from '../../store/settings'
import { mockHost } from '../../api'

function useDeleteTermsAgreement() {
  const isMockEnabled = useSelector(selectSettingsMockEnabled)

  return useMutation<AxiosResponse, AxiosError, string>({
    mutationFn: (termsType) => {
      if (JSON.parse(isMockEnabled as string)) {
        return mockHost.delete('/heimdall/heimdall-rmi/api/v1/users/$userKey?termsType=UBS01')
      } else {
        return deleteTermsAgreement(termsType)
      }
    },
    onError: (e, variables) => {
      trackErrorLogs(e, 'tscore:error:deleteTermsAgreement', variables)
    },
  })
}

export { useDeleteTermsAgreement }