import { initializeApp } from 'firebase/app'
import { getRemoteConfig } from 'firebase/remote-config'

// TMAP config
const firebaseConfig = {
  apiKey: 'AIzaSyASeTN_1BEmJfEAXXIb887F0kzwuzUU0fM',
  authDomain: 't-map-474e8.firebaseapp.com',
  databaseURL: 'https://t-map-474e8.firebaseio.com',
  projectId: 't-map-474e8',
  storageBucket: 't-map-474e8.appspot.com',
  messagingSenderId: '272139553947',
  appId: '1:272139553947:web:e029fdec2b269f5f29ca3e',
  measurementId: 'G-DHYQDEYMRC',
}

const firebaseApp = initializeApp(firebaseConfig)
export const remoteConfig = getRemoteConfig(firebaseApp)
remoteConfig.settings.minimumFetchIntervalMillis = 0

export { firebaseApp }