import { useMutation } from '@tanstack/react-query'
import { updateCarProfile, UpdateCarProfileParam } from '@tmap-web-lib/remote-api-client/frontman'
import { AxiosError, AxiosResponse } from 'axios'

function useUpdateCarProfile() {
  return useMutation<AxiosResponse, AxiosError, UpdateCarProfileParam>(
    (data) => updateCarProfile(data),
  )
}

export { useUpdateCarProfile }
