import styled from '@emotion/styled'
import { ButtonHTMLAttributes, PropsWithChildren } from 'react'
import { css } from '@emotion/react'

import IcoArrDown from '../../assets/images/ico_arrow_down_bold.svg'

interface ButtonProps extends PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> {
  variant: 'default' | 'primary' | 'simple',
  styleWidth?: string
  styleHeight?: number
  styleMargin?: string
  stylePadding?: string
  borderRadius?: string
  styleFontSize?: 'default' | 'normal' | 'under' | 'basic' | 'big'
  styleFontWeight?: 'normal' | 'medium' | 'bold'
  isMore?: boolean
  disabled?: boolean
  className?: string
  gap?: string
}

function Button({
  type = 'button',
  variant = 'primary',
  styleWidth,
  styleHeight,
  styleMargin,
  stylePadding,
  borderRadius = '8px',
  styleFontSize = 'default',
  styleFontWeight,
  isMore,
  disabled,
  className,
  gap,
  children,
  ...attrs
}: ButtonProps) {
  return (
    <ButtonItem
      type={type}
      className={className}
      variant={variant}
      styleWidth={styleWidth}
      styleHeight={styleHeight}
      styleMargin={styleMargin}
      stylePadding={stylePadding}
      borderRadius={borderRadius}
      styleFontSize={styleFontSize}
      styleFontWeight={styleFontWeight}
      isMore={isMore}
      disabled={disabled}
      gap={gap}
      {...attrs}
    >
      {children}
    </ButtonItem>
  )
}

export { Button }

const ButtonItem = styled.button<ButtonProps>`
  ${({ variant }) => {
    switch (variant) {
      case 'default':
        return css`
          background: var(--C-gray-200);
          color: var(--C-gray-900);
          
          // 버튼 스타일은 disabled 이지만 클릭 이벤트가 필요한 경우
          // (ex : 앱 토스트 띄우기)
          &.clickable-disabled {
            background-color: var(--C-gray-100);
            color: var(--C-gray-300);
          }
        `
      case 'primary':
        return css`
          background: var(--C-primary-500);
          color: var(--C-white);
          
          // 버튼 스타일은 disabled 이지만 클릭 이벤트가 필요한 경우
          // (ex : 앱 토스트 띄우기)
          &.clickable-disabled {
            background-color: var(--C-gray-100);
            color: var(--C-gray-300);
          }

          &:disabled {
            background-color: var(--C-gray-100);
            color: var(--C-gray-300);
          }
        `
      case 'simple':
      default:
        return css`
          border: 1px solid var(--C-gray-300);
          color: var(--C-gray-900);
        `
    }
  }}
  ${({ styleWidth }) => css`width: ${styleWidth ?? '100%'};`}
  ${({ styleHeight }) => css`height: ${styleHeight ?? 56}px;`}
  ${({ styleMargin }) => styleMargin && css`margin: ${styleMargin};`}
  ${({ stylePadding }) => css`padding: ${stylePadding ?? '0 16px'};`}
  position: relative;
  border-radius: ${(props) => props.borderRadius};
  ${({ styleFontSize }) => {
    switch (styleFontSize) {
      case 'normal':
        return css`
          font-size: var(--font-size-14);
        `
      case 'under':
        return css`
          font-size: 1.5rem;
        `
      case 'basic':
        return css`
          font-size: 1.7rem;
        `
      case 'big':
        return css`
          font-size: var(--font-size-18);
        `
      case 'default':
      default:
        return css`
          font-size: var(--font-size-16);
        `
    }
  }}
  ${({ styleFontWeight }) => {
    switch (styleFontWeight) {
      case 'medium':
        return css`
          font-weight: var(--font-weight-medium);
        `
      case 'bold':
        return css`
          font-weight: var(--font-weight-bold);
        `
      case 'normal':
      default:
        return css`
          font-weight: var(--font-weight-normal);
        `
    }
  }}

  ${({ gap }) => {
    return css`
      &:not(:first-of-type) {
      margin-left: ${gap};
    }`
  }}
  &::${({ isMore }) => isMore ? 'before' : 'after'} {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
    border-radius: ${(props) => props.borderRadius};
    background-color: var(--C-black-tr);
    transition: background-color 100ms;
  }
  &:active {
    &::${({ isMore }) => isMore ? 'before' : 'after'} {
      background-color: var(--C-wb-transparent-100);
    }
  }
  ${({ isMore }) => isMore && css`
    line-height: 2.4rem;
    
    &::after {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-left: 8px;
      background: url(${IcoArrDown}) no-repeat;
      vertical-align: top;
    }
  `}
  &:disabled {
    color: var(--C-gray-400);
    background: var(--C-gray-100);
    
    &::after {
      display: none;
    }
  }
`
