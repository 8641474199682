import styled from '@emotion/styled'
import { ButtonHTMLAttributes, PropsWithChildren } from 'react'
import { css } from '@emotion/react'

export interface ButtonProps extends PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> {
  isFullWidth?: boolean
  borderRadius?: number
  outerPadding?: number
  className?: string
  isBeforePseudoElement?: boolean
}

function ButtonTapHighlight({
    type = 'button',
    isFullWidth = true,
    borderRadius = 4,
    outerPadding = 0,
    className,
    isBeforePseudoElement,
    children,
    ...attrs
  }: ButtonProps) {

  return (
    <Button
      type={type}
      borderRadius={borderRadius}
      outerPadding={outerPadding}
      className={className}
      isBeforePseudoElement={isBeforePseudoElement}
      isFullWidth={isFullWidth}
      {...attrs}
    >
      {children}
    </Button>
  )
}

export { ButtonTapHighlight }

const Button = styled.button<ButtonProps>`
  ${({ isFullWidth }) => isFullWidth && css`width: 100%`};
  position: relative;
  text-align: left;
  white-space: pre-line;
  
  &::${({ isBeforePseudoElement }) => isBeforePseudoElement ? 'before' : 'after'} {
    content: '';
    position: absolute;
    left: ${(props) => props.outerPadding}px;
    right: ${(props) => props.outerPadding}px;
    top: ${(props) => props.outerPadding}px;
    bottom: ${(props) => props.outerPadding}px;
    z-index: 100;
    border-radius: ${(props) => props.borderRadius}px;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 100ms;
  }
  &:active {
    &::${({ isBeforePseudoElement }) => isBeforePseudoElement ? 'before' : 'after'} {
      background-color: var(--C-wb-transparent-100);
    }
    &:disabled {
      &::${({ isBeforePseudoElement }) => isBeforePseudoElement ? 'before' : 'after'} {
        display: none;
      }
    }
  }
`
