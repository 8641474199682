import { useQuery } from '@tanstack/react-query'
import { generateOtpCode, GenerateOtpCodeRes } from '@tmap-web-lib/remote-api-client/frontman'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'

interface Props {
  useErrorBoundary?: boolean
}

function useGenerateOtpCode(props: Props) {
  const { useErrorBoundary } = props
  return useQuery<GenerateOtpCodeRes, AxiosError>([KEYS.OTP_CODE()],
    () => generateOtpCode({}).then(({ data }) => data),
    {
      useErrorBoundary: useErrorBoundary ?? true,
    },
  )
}

export { useGenerateOtpCode }