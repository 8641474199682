import React, { useCallback, useEffect, useState } from 'react'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'
import { BenefitListDataType, useTabActivity } from '../Benefit/hooks'
import { SectionCard, Button, Tab, Tabs } from '../../components'
import { BenefitList } from '../Benefit/BenefitList'
import { RedDotLog, useOpenServiceUrl } from '../../hooks'
import { ReportsResponseData } from '@tmap-web-lib/remote-api-client/frontman'
import { useInView } from 'react-intersection-observer'

interface Props {
  data: BenefitListDataType[]
  drivingScoreData?: ReportsResponseData
  redDotLog: RedDotLog
  eventLog: () => TmapLogBuilder
}

function MainAllBenefitList({ data, drivingScoreData, redDotLog, eventLog }: Props) {
  const openServiceUrl = useOpenServiceUrl()
  const { tabRef, handleTabActivity } = useTabActivity()
  const { ref, inView, entry } = useInView({
    threshold: 1,
    triggerOnce: true,
  })

  const [categoryId, setCategoryId] = useState(data.length ? data[0].categoryId : '')
  const [benefitData, setBenefitData] = useState<BenefitListDataType | undefined>(data.length ? data[0] : undefined)

  const handleClickTab = useCallback((item: BenefitListDataType, index: number) => {
    eventLog()
      .set('action_id', 'tap.benefit_category_benefit_explore')
      .addCustomField('category_id', item.categoryId)
      .addCustomField('category_title', item.categoryName)
      .addCustomField('reddot', redDotLog || 'n')
      .send()
    setCategoryId(item.categoryId)
    setBenefitData(item)
    handleTabActivity(index)
  }, [redDotLog, handleTabActivity, eventLog])

  const handleClickMore = useCallback((item: BenefitListDataType) => {
    const extra = { seq: item.categoryId }
    eventLog()
      .set('action_id', 'tap.view_all_benefit_explore')
      .addCustomField('category_id', item.categoryId)
      .addCustomField('category_title', item.categoryName)
      .addCustomField('reddot', redDotLog || 'n')
      .send()
    openServiceUrl(`tmap://life?pageid=benefit&extra=${encodeURIComponent(JSON.stringify(extra))}`)
  }, [redDotLog, openServiceUrl, eventLog])

  useEffect(() => {
    if (inView) {
      const actionId = entry?.target.getAttribute('data-log') || ''
      eventLog().set('action_id', actionId).send()
    }
  }, [inView, entry, eventLog])

  if (!data || !benefitData) return null

  return (
    <SectionCard
      title={'운전점수 혜택'}
      data-log={'view.benefit_explore'}
      ref={ref}
    >
      <Tabs
        ref={tabRef}
        variant={'capsule'}
      >
        {data.map((item, index) => {
          return (
            <Tab
              key={item.categoryId}
              variant={'stroke'}
              isActive={categoryId === item.categoryId}
              onClick={() => handleClickTab(item, index)}
            >
              {item.categoryName}
            </Tab>
          )
        })}
      </Tabs>
      <BenefitList
        data={benefitData}
        drivingScoreData={drivingScoreData}
        displayType={'type_commerce_carousel_main_only'}
        className={'exception'}
        redDotLog={redDotLog}
        isMainPageExposureArea
        eventLog={eventLog}
        style={{ marginTop: '8px'}}
      />
      <Button
        variant={'default'}
        styleWidth={'calc(100% - 40px)'}
        styleHeight={52}
        styleMargin={'20px 20px 20px'}
        borderRadius={'6px'}
        onClick={() => handleClickMore(benefitData)}
      >
        {benefitData.categoryName} 전체보기
      </Button>
    </SectionCard>
  )
}

export { MainAllBenefitList }
