import React, { useCallback } from 'react'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'
import { SectionCard, ButtonTapHighlight } from '../../components'
import { useOpenService } from '../../hooks'
import { BoardListDataType } from '@tmap-web-lib/remote-api-client/frontman'

interface Props  {
  data: BoardListDataType | undefined
  eventLog: () => TmapLogBuilder
}

function MainTips(props:Props) {
  const { data, eventLog } = props

  const openService = useOpenService()

  const handleClickDetail = useCallback((seq: number) => {
    eventLog()
      .set('action_id', 'tap.content')
      .add('customField', ['content_id', `${seq}`])
      .send()
    openService(`tmap://board?pageid=board_detail&boardid=BOARD-SCORE&seq=${seq}&type=vertical&origin=life`)
  }, [openService, eventLog])

  const handleClickMore = useCallback(() => {
    const extraCustomFields = eventLog().get('customFieldAll')
    let path
    if (extraCustomFields) {
      const params = `&extra=${encodeURIComponent(JSON.stringify({
        is_first_history: true,
        logbox: {...extraCustomFields},
      }))}`
      path = `tmap://board?pageid=board_list&boardid=BOARD-SCORE${params}`
    } else {
      path = 'tmap://board?pageid=board_list&boardid=BOARD-SCORE'
    }
    eventLog().set('action_id', 'tap.view_allcontents').send()
    openService(path)
  }, [openService, eventLog])

  if (!data || !data.boards) return null

  return(
    <SectionCard
      title={'운전꿀팁'}
      handleClickMore={handleClickMore}
      isMore
    >
      <ul className="main_tip">
        {data.boards.map((content, index) => {
          return (
            <li
              key={index}
              className="main_tip_cell"
            >
              <ButtonTapHighlight
                borderRadius={0}
                className="main_tip_item"
                onClick={() => handleClickDetail(content.boardSeq)}
              >
                <img
                  src={content.thumbnailImage || ''}
                  className="main_tip_img"
                  alt=""
                />
                <p className="main_tip_tit">{content.title}</p>
              </ButtonTapHighlight>
            </li>
          )
        })}
      </ul>
    </SectionCard>
  )
}

export { MainTips }
