import React, { Fragment } from 'react'

interface Props {
  text?: string | null;
  separator?: string;
}

export function ResponsiveLineBreak(props: Props) {
  const { text, separator = '\n' } = props
  if (text) {
    const parts = text.split(separator)
    const endIndex = parts.length - 1
    return (
      <>
        {parts.map((part, index) =>
          <Fragment key={index}><p className="type_medium">{part}</p>{index === endIndex ? '' : ' '}</Fragment>
        )}
      </>
    )
  }
  return null
}
