import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'
import { CarProfile, getCarProfile } from '@tmap-web-lib/remote-api-client/frontman'
import { useSelector } from 'react-redux'
import { selectSettingsMockEnabled } from '../../store/settings'
import { mockHost } from '../../api'

function useGetCarProfile(carProfileId: string) {
  const isMockEnabled = useSelector(selectSettingsMockEnabled)

  return useQuery<CarProfile, AxiosError>([KEYS.CAR_PROFILE()],
    async () => {
      if (JSON.parse(isMockEnabled as string)) {
        const { data } = await mockHost.get<CarProfile>('/flerken/api/v3/car-info/carinfos')
        return data
      } else {
        const { data } = await getCarProfile(carProfileId)
        return data
      }
    },
    {
      enabled: !!carProfileId,
    },
  )
}

export { useGetCarProfile }
