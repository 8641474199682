
import { isUndefined } from 'lodash-es'
import { Dictionary } from '@tmap-web-lib/utils'

function getQuerystringMap(): Dictionary<string | undefined> {
  const search = window.location.search
    .replace('?', '')

  if (!search) {
    return {}
  }

  return search.split('&').reduce((map, token) => {
    const pair = token.split('=')
    map[decodeURIComponent(pair[0])] = isUndefined(pair[1]) ? undefined : decodeURIComponent(pair[1])
    return map
  }, {} as Dictionary<string | undefined>)
}

export { getQuerystringMap }
