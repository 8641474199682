import { usePromiseTracker } from 'react-promise-tracker'
import { Loading } from './Loading'

export function InsureLoading() {
  const { promiseInProgress } = usePromiseTracker(CONFIG)
  return <Loading className={promiseInProgress ? '' : 'type-hide'}/>
}

export const LOADING_AREA_INSURE = 'INSURE'

const CONFIG = { area: LOADING_AREA_INSURE }
