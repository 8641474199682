import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  DialogBackground,
  DialogContent,
  DialogFooter,
  DialogOkButton
} from './Elements'
import { DialogWithAnimationProps } from './types'
import { classNames, getSearchParams } from '@tmap-web-lib/utils'
import { CSSTransition } from 'react-transition-group'
import { useLogBox } from '../../hooks'
import { VEHICLE_HIGHWAYTOLL_TERMS_LOG_PRESET } from '../../hooks/logbox/preset'
import { PageIdSearchParams } from '../../providers/page-id-context'
import isEmpty from 'lodash-es/isEmpty'
import { store } from '../../store'
import { clearError } from '../../store/app'
import { useDialogManager } from '@tmap-web-lib-close/dialog-manager/react-router'
import DialogOk from './DialogOk'
import { useUpdateTermsAgreement } from '../../react-query'

function DialogUnpaidTollTerms(props: DialogWithAnimationProps) {
  const {
    wrapperClassName,
    title,
    okText,
    cancelText,
    onOk,
    onCancel,
    footerClassName,
    onContentClick
  } = props

  const ref = useRef(null)
  const backgroundRef = useRef(null)
  const [eventLog, exposeLog] = useLogBox(['event', 'expose'], {
    attributesPreset: VEHICLE_HIGHWAYTOLL_TERMS_LOG_PRESET.ATTRIBUTES,
    customFieldsPreset: VEHICLE_HIGHWAYTOLL_TERMS_LOG_PRESET.CUSTOM_FIELDS,
    withEuk: true
  })

  const { mutate, status } = useUpdateTermsAgreement()
  const dialogManager = useDialogManager()

  const [isAgreed, setAgreed] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(true)

  const handleOk = useCallback(async () => {
    await mutate([{
      allowCode: "TTERMS-B2C-18",
      allowTitle: "개인정보 제3자 제공 동의(고속도로 미납 통행료)",
      allowYn: 'Y'
    }])
  }, [mutate])

  const handleCancel = useCallback(() => {
    eventLog().set('action_id', 'tap.disagree').send()
    setModalIsOpen(false)
    onCancel?.()
  }, [eventLog, onCancel])

  useEffect(() => {
    if (status === 'error') {
      dialogManager.showDialog(
        {
          component: DialogOk,
          props: {
            title: <>일시적으로 서비스를<br/> 이용할 수 없습니다.<br/><p className="sub">잠시 후 다시 시도해주세요.</p></>,
            onOk() {
              setModalIsOpen(false)
              store.dispatch(clearError())
            }
          },
        }
      )
    } else if (status === 'success') {
      eventLog().set('action_id', 'tap.agree').send()
      onOk?.()
    }
  }, [status, dialogManager, eventLog, onOk])

  useEffect(() => {
    const searchParams = getSearchParams<PageIdSearchParams>()
    if (!isEmpty(searchParams) && searchParams.extra) {
      const extraObject = JSON.parse(searchParams.extra as string)
      const extraCustomFields = extraObject.logbox

      if (extraCustomFields) {
        Object.keys(extraCustomFields).forEach(key => {
          VEHICLE_HIGHWAYTOLL_TERMS_LOG_PRESET.CUSTOM_FIELDS.push([key, extraCustomFields[key]])
        })
      }
    } else {
      VEHICLE_HIGHWAYTOLL_TERMS_LOG_PRESET.CUSTOM_FIELDS.push(['type', 'tab'], ['origin', 'tab'])
    }
    exposeLog().send()
  }, [exposeLog])

  return (
    <>
      <CSSTransition
        classNames="fade-out"
        unmountOnExit={true}
        timeout={800}
        in={modalIsOpen}
        nodeRef={backgroundRef}
        appear
      >
        <DialogBackground className={wrapperClassName} ref={backgroundRef}>
          <CSSTransition
            classNames="slide-up"
            unmountOnExit={true}
            timeout={400}
            in={modalIsOpen}
            nodeRef={ref}
            appear
          >
            <div ref={ref} className={classNames('popup_wrap', 'popup_bottom', 'popup_terms_form')}>
              <div className={classNames('popup_cont')}>
                <DialogContent
                  title={title}
                  content={
                    <div className="form_checkbox">
                      <button
                        className={classNames('form_checkbox_label', isAgreed ? 'checked' : '')}
                        onClick={() => setAgreed((prev) => !prev)}
                      >
                        (필수) 제3자 미납통행료 조회 동의
                      </button>
                      <button
                        className="form_checkbox_link"
                        onClick={onContentClick}
                      >
                        약관 내용보기
                      </button>
                    </div>
                  }
                />
                <DialogFooter
                  className={classNames(footerClassName, 'column_reverse')}
                  okButton={
                    <DialogOkButton
                      className={!isAgreed ? 'clickable-disabled' : ''}
                      disabled={!isAgreed}
                      onClick={handleOk}
                    >
                      {okText}
                    </DialogOkButton>
                  }
                  cancelButton={
                    <button
                      type="button"
                      className={classNames('popup_btn_text')}
                      onClick={handleCancel}
                    >
                      {cancelText}
                    </button>
                  }
                />
              </div>
            </div>
          </CSSTransition>
        </DialogBackground>
      </CSSTransition>
    </>
  )
}

export default DialogUnpaidTollTerms
export { DialogUnpaidTollTerms }
