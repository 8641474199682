import React, { ForwardedRef, forwardRef, PropsWithChildren, ReactNode } from 'react'
import styled from '@emotion/styled/dist/emotion-styled.cjs'
import { css } from '@emotion/react'

type TitleOptions = {
  align?: 'left' | 'center' | 'right'
}

interface Props extends PropsWithChildren<{}> {
  title: ReactNode
  titleOptions?: TitleOptions
}

const DialogBottomSheet = forwardRef((props: Props, ref: ForwardedRef<HTMLElement>) => {
  const { title, titleOptions, children } = props

  return (
    <Wrap ref={ref}>
      <Container>
        {title && (
          <Header>
            <HeaderTitle {...titleOptions}>{title}</HeaderTitle>
          </Header>
        )}
        <Content>
          {children}
        </Content>
      </Container>
    </Wrap>
  )
})

export { DialogBottomSheet}

const Wrap = styled.section`
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  max-height: 360px;
  padding-top: 20px;
  border-radius: 10px 10px 0 0;
  box-sizing: border-box;
  background: var(--C-white);
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const Header = styled.header`
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  height: 56px;
`

const HeaderTitle = styled.h1<TitleOptions>`
  ${({ align = 'center' }) => css`text-align: ${align}`};
  line-height: 56px;
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-medium);
`

const Content = styled.div`
  overflow-y: auto;
`
