import { useEffect, useState } from 'react'
import { RedDotListData } from '@tmap-web-lib/tmap-app-interface'

interface Props {
  list?: RedDotListData[]
  itemName: string
}

function useGetRedDot({ list, itemName }: Props) {
  const [isRedDot, setRedDot] = useState(false)

  useEffect(() => {
    setRedDot(!!list?.find((redDot) => redDot.item === itemName))
  }, [list, itemName])

  return { isRedDot }
}

export { useGetRedDot }
