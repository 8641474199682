import React, { useCallback, useMemo } from 'react'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'
import { useOpenServiceUrl } from '../../hooks'
import { classNames } from '@tmap-web-lib/utils'
import { SubTermsInfoType, TermsInfoType } from './hook'

interface Props {
  termsList: TermsInfoType[];
  handleChecked: (arr: TermsInfoType[]) => void;
  eventLog: () => TmapLogBuilder;
  isSingleTerms?: boolean
}

function TermsContents(props: Props) {
  const {
    termsList,
    handleChecked,
    eventLog,
    isSingleTerms
  } = props

  const openServiceUrl = useOpenServiceUrl()

  const handleParentTermsClick = useCallback((termsInfo: TermsInfoType) => {
    eventLog().set('action_id', termsInfo.logActionId).send()
    const list = [ ...termsList ]

    list.forEach((terms) => {
      if (terms.termsCode === termsInfo.termsCode) {
        terms.isChecked = !terms.isChecked
      }
      // 하위 약관이 있는 경우 모두 선택 유/무 처리
      if (termsInfo.subTermsList) {
        terms.subTermsList?.forEach((subTerms) => {
          subTerms.isChecked = terms.isChecked
        })
      }
    })
    handleChecked(list)
  }, [termsList, handleChecked, eventLog])

  const handleSubTermsClick = useCallback((termsInfo: SubTermsInfoType, index: number) => {
    eventLog().set('action_id', termsInfo.logActionId).send()
    const list = [ ...termsList ]
    const parentIndex = list.findIndex((terms) => terms.termsCode === termsInfo.parentTermsCode)
    const subTermsIndex = list.findIndex((terms) => terms.subTermsList)
    const subTermsList = list[subTermsIndex].subTermsList
    const requiredTermsIndex = subTermsList?.findIndex((item) => item.required)

    if (subTermsList) {
      // 클릭한 약관 체크 유/무
      subTermsList[index].isChecked = !subTermsList[index].isChecked
      // 하위 약관이 하나라도 체크되어 있는지 확인하는 플래그
      const isCheckedAnyOne = !!subTermsList?.find((terms) => terms.isChecked)
      // 하위 약관이 체크된 것에 따라 상위 약관 체크 유/무
      list[parentIndex].isChecked = isCheckedAnyOne

      // 필수 약관 체크 여부
      if (termsInfo.required) {
        if (subTermsList[index].isChecked) {
          // 필수 약관이 체크된 상태이면 상위 약관 체크
          list[parentIndex].isChecked = true
        } else {
          // 필수 약관이 체크 해제된 상태이면 상위, 하위 약관 해제
          list[parentIndex].isChecked = false
          subTermsList?.forEach((terms) => terms.isChecked = false)
        }
      } else {
        subTermsList[requiredTermsIndex || 0].isChecked = isCheckedAnyOne
      }
    }
    handleChecked(list)
  }, [termsList, handleChecked, eventLog])

  const renderTerms = useMemo(() => {
    return termsList.map((terms) => {
      return (
        <div
          key={terms.seq}
          className={isSingleTerms ? 'terms_checkbox_single_terms' : 'terms_checkbox_wrap'}
        >
          <div className="terms_checkbox_item">
            <button
              className={classNames('terms_checkbox_item_label', terms.isChecked ? 'checked' : undefined)}
              onClick={() => handleParentTermsClick(terms)}
            >
              {terms.title}
            </button>
            <button
              className="terms_checkbox_item_link"
              onClick={() => openServiceUrl(terms.link, 'terms')}
            >
              약관 내용보기
            </button>
          </div>
          {terms.subTermsList?.length && (
            <div className="terms_checkbox_sub_terms">
              {terms.subTermsList.map((subTerms, subIndex) => {
                return (
                  <div
                    key={subTerms.seq}
                    className={classNames('terms_checkbox_item', subTerms.required ? 'sub' : 'sub_opt')}
                  >
                    <button
                      className={classNames('terms_checkbox_item_label', 'sub', subTerms.isChecked ? 'checked' : undefined)}
                      onClick={() => handleSubTermsClick(subTerms, subIndex)}
                    >
                      {subTerms.title}
                    </button>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      )
    })
  }, [termsList, isSingleTerms, openServiceUrl, handleParentTermsClick, handleSubTermsClick])

  return (
    <>{renderTerms}</>
  )
}

export { TermsContents }
