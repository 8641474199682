import { MouseEvent, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  selectOtpClosed,
  setOtpClosed,
} from '../../../store/settings'
import { store } from '../../../store'

/**
 * READY: 오픈 전
 * OPEN: 오픈
 * CLOSE: 닫기
 * END: 닫기 애니메이션 종료
 */
export enum OtpTooltipStatus {
  READY = 0,
  OPEN = 1,
  CLOSE = 2,
  END = 3,
}

function useOtpTooltip() {
  const otpClosed = useSelector(selectOtpClosed)

  const [openOtpTooltip, setOpenOtpTooltip] = useState<OtpTooltipStatus>(OtpTooltipStatus.READY)
  const [count] = useState(Number(otpClosed) || 0)

  const handleOtpTooltipClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setOpenOtpTooltip(OtpTooltipStatus.CLOSE)
  }, [])

  const handleOtpTooltipTransitionEnd = useCallback(() => {
    if (openOtpTooltip === OtpTooltipStatus.CLOSE) {
      setOpenOtpTooltip(OtpTooltipStatus.END)
      store.dispatch(setOtpClosed(String(count + 1)))
    }
  }, [count, openOtpTooltip])

  useEffect(() => {
    setOpenOtpTooltip(OtpTooltipStatus.OPEN)
  }, [])

  useEffect(() => {
    if (openOtpTooltip === OtpTooltipStatus.OPEN) {
      const timerId = setTimeout(() => setOpenOtpTooltip(OtpTooltipStatus.CLOSE), 1500)
      return () => clearTimeout(timerId)
    }
  }, [openOtpTooltip])

  return {
    openOtpTooltip,
    handleOtpTooltipClick,
    handleOtpTooltipTransitionEnd,
  }
}

export { useOtpTooltip }
