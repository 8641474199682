import React from 'react'
import { ButtonProps, ButtonTapHighlight } from './ButtonTapHighlight'
import { classNames } from '@tmap-web-lib/utils'

interface FloatingButtonProps extends ButtonProps {
  usage?: string
}

function FloatingButton({ usage, children, ...attrs }: FloatingButtonProps) {
  return (
    <div className="floating_wrap">
      <ButtonTapHighlight
        className={classNames('floating_button', usage)}
        {...attrs}
      >
        {children}
      </ButtonTapHighlight>
    </div>
  )
}

export { FloatingButton }
