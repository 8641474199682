import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'
import { CharanchaPopularCarResData, getCharanchaPopularCarList } from '@tmap-web-lib/remote-api-client/frontman'

interface Props {
  limit?: number
  useErrorBoundary?: boolean
}

function useGetCharanchPopularCarList(props: Props) {
  const { limit, useErrorBoundary } = props

  return useQuery<CharanchaPopularCarResData, AxiosError>([KEYS.CHARANCHA_POPULAR_CAR_LIST()],
    () => getCharanchaPopularCarList(limit).then(({ data }) => data),
    {
      useErrorBoundary: useErrorBoundary ?? true,
    },
  )
}

export { useGetCharanchPopularCarList }
