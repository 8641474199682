import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'
import { useSelector } from 'react-redux'
import { selectSettingsEuk, selectSettingsIsCarLifeTermsAgreed } from '../../store/settings'
import { MainUserStatusDiscountAnimate } from './MainUserStatusDiscountAnimate'
import { ButtonTapHighlight } from '../../components'
import debounce from 'lodash-es/debounce'
import { RedDotListData, TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { RedDotLog, useOpenServiceUrl } from '../../hooks'
import { ReportsResponseData } from '@tmap-web-lib/remote-api-client/frontman'
import { Notification } from '../../components'

interface Props {
  drivingScoreData: ReportsResponseData | undefined
  redDotList?: RedDotListData[]
  updateRedDotList: (itemName: string) => void
  redDotLog: RedDotLog
  eventLog: () => TmapLogBuilder
  showTermsPopup:() => void
}

function MainUserStatus(props: Props) {
  const {
    drivingScoreData,
    redDotList,
    updateRedDotList,
    redDotLog,
    eventLog,
    showTermsPopup
  } = props
  const isCarLifeTermsAgreed = useSelector(selectSettingsIsCarLifeTermsAgreed)
  const euk = useSelector(selectSettingsEuk)
  const openServiceUrl = useOpenServiceUrl()

  const [unReadRedDotItem, setUnReadRedDotItem] = useState<RedDotListData>()
  const [isTooltip, setTooltip] = useState(true)

  const callClickDebouncing = useMemo(() => debounce((type?: string) => {
    if (redDotLog === null) return
    if (type === 'score_analysis') {
      eventLog().set('action_id', 'tap.button_scoreAnalysis').addCustomField('reddot', redDotLog).send()
      TmapApp.recordEvent({ name: 'drivingscore_scoreAnalysis', json: { euk: euk } })
    } else {
      eventLog().set('action_id', 'tap.score').addCustomField('reddot', redDotLog).send()
      TmapApp.recordEvent({ name: 'drivingscore_score', json: { euk: euk } })
    }

    if (isCarLifeTermsAgreed) {
      TmapApp.recordEvent({ name: 'drivingscore_scoreAnalysis', json: { euk: euk } })
      if (unReadRedDotItem) {
        updateRedDotList('SCORE_REGISTERED')
        setUnReadRedDotItem(undefined)
      }
      openServiceUrl('tmap://life?pageid=driving_analysis')
    } else {
      showTermsPopup()
    }
  }, 500, {
    leading: true,
    trailing: false
  }), [eventLog, euk, openServiceUrl, unReadRedDotItem, isCarLifeTermsAgreed, redDotLog, updateRedDotList, showTermsPopup])

  const splitScore = useMemo(() => {
    if (drivingScoreData?.score) {
      return drivingScoreData.score.toString().split('')
    } else {
      return ['0']
    }
  }, [drivingScoreData])

  const showTooltip = useMemo(() => {
    return !!unReadRedDotItem && isTooltip
  }, [unReadRedDotItem, isTooltip])

  const handleClick = useCallback((type?: string) => {
    callClickDebouncing(type)
  }, [callClickDebouncing])

  const handleTooltipClick = useCallback((type?: string) => {
    if (type === 'click') {
      eventLog().set('action_id', 'tap.tooltip_scoreAnalysis').send()
    }
    setTooltip(false)
  }, [eventLog])

  const displayScore = useMemo(() => {
    if (isCarLifeTermsAgreed  && drivingScoreData) {
      return (
        <>
          {
            splitScore.map((str, index) => {
              return (
                <MainUserStatusDiscountAnimate
                  key={`score-${index}`}
                  targetNumber={str}
                  index={index}
                />
              )
            })
          }
          <span className="main_score_unit">점</span>
        </>
      )
    } else if (!isCarLifeTermsAgreed ) {
      return '?점'
    }
  },[drivingScoreData, isCarLifeTermsAgreed, splitScore])

  const renderScoreBox = useMemo(() => {
    return (
      <>
        <div className="main_score_wrapper">
          <button
            type="button"
            className="main_score_point"
            onClick={() => handleClick()}
          >
            {displayScore}
          </button>
        </div>
        <div className="main_score_analysis">
          <ButtonTapHighlight
            isFullWidth={false}
            isBeforePseudoElement={true}
            className="main_score_analysis_link"
            onClick={() => handleClick('score_analysis')}
          >
            운전분석
            {unReadRedDotItem && (
              <Notification
                notificationType={'dot'}
                className="main_score_analysis_dot"
              />
            )}
          </ButtonTapHighlight>
          {showTooltip && (
            <p
              className="main_score_analysis_tooltip"
              onClick={() => handleTooltipClick('click')}
            >
              운전점수 변동이 있어요
            </p>
          )}
        </div>
      </>
    )
  }, [handleClick, handleTooltipClick, unReadRedDotItem, displayScore, showTooltip])

  useEffect(() => {
    setUnReadRedDotItem(redDotList?.find(data => data.item === 'SCORE_REGISTERED'))
  }, [redDotList])

  useEffect(() => {
    window.addEventListener('scroll', () => handleTooltipClick())
    return () => {
      window.removeEventListener('scroll', () => handleTooltipClick())
    }
  }, [handleTooltipClick])

  return (
    <>
      <div className="main_score">
        <h1 className="main_score_title">
          운전점수
        </h1>
        {renderScoreBox}
      </div>
    </>
  )

}

export { MainUserStatus }
