import React from 'react'
import { classNames } from '@tmap-web-lib/utils'

function MainCarInfoError() {
  return (
    <>
      <div className="main_vehicle">
        <div className="main_vehicle_detail">
          <img
            src={require('../../assets/images/img_top_car_empty.svg').default}
            width="200"
            height="120"
            alt=""
          />
        </div>
      </div>
      <div className={classNames('main_vehicle_desc', 'error')}>데이터 오류, 잠시 후 다시 시도해주세요</div>
    </>
  )
}

export { MainCarInfoError }
