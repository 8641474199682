import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { shortenerUrl, ShortenerUrlParams, ShortenerUrlRes } from '@tmap-web-lib/remote-api-client/frontman'

function useShortenerUrl() {
  return useMutation<ShortenerUrlRes, AxiosError, ShortenerUrlParams> (
    (params) => shortenerUrl(params).then(({ data }) => data),
  )
}

export { useShortenerUrl }