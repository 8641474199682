import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'
import { CarProfileListResData, getCarProfileList } from '@tmap-web-lib/remote-api-client/frontman'
import { useSelector } from 'react-redux'
import { selectSettingsMockEnabled } from '../../store/settings'
import { mockHost } from '../../api'

interface Options {
  enabled: boolean
  useErrorBoundary?: boolean // 기본은 공통 에러를 사용하고 특정 페이지에서 부분 에러 처리가 필요하다면 false로 옵션 제공
}

function useGetCarProfileList(options?: Options) {
  const isMockEnabled = useSelector(selectSettingsMockEnabled)

  return useQuery<CarProfileListResData, AxiosError>([KEYS.CAR_PROFILE_LIST()],
    async () => {
      if (JSON.parse(isMockEnabled as string)) {
        const { data } = await mockHost.get<CarProfileListResData>('/flerken/api/v3/car-info')
        return data
      } else {
        const { data } = await getCarProfileList()
        return data
      }
    },
    {
      enabled: options?.enabled,
      useErrorBoundary: options?.useErrorBoundary ?? true,
    },
  )
}

export { useGetCarProfileList }
