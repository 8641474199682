import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import throttle from 'lodash/throttle'
import { useCallback, useMemo } from 'react'
import { getShareBridgeUrl } from '../utils'

type OpenShareLinkParams = Parameters<typeof getShareBridgeUrl>[0]

type Options = {
  useBridge?: boolean;
}

const intervalTime = 3000

function useOpenShareLink() {
  const callShare = useCallback((params: OpenShareLinkParams, options: Options) => {
    const shareUrl = options.useBridge ? getShareBridgeUrl(params) : params.url
    TmapApp.shareMessage({ title: params.title, body: params.body, url: shareUrl })
  }, [])
  const callShareThrottling = useMemo(() => throttle(callShare, intervalTime, { trailing: false }), [callShare])

  return useCallback((params: OpenShareLinkParams, options: Options = { useBridge: true }) => {
    if (TmapApp.env.isAndroid) {
      callShareThrottling(params, options)
    } else {
      callShare(params, options)
    }
  }, [callShare, callShareThrottling])
}

export { useOpenShareLink }
