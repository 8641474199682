import { useMutation } from '@tanstack/react-query'
import { updateUserMessageExpire, UserMessageExpireReqType } from '@tmap-web-lib/remote-api-client/frontman'
import { AxiosError, AxiosResponse } from 'axios'

interface Options {
  useErrorBoundary?: boolean
}

function useUpdateUserMessageExpire(options?: Options) {
  return useMutation<AxiosResponse, AxiosError, UserMessageExpireReqType>(
    (data) => updateUserMessageExpire(data), {
      useErrorBoundary: options?.useErrorBoundary ?? true
    }
  )
}

export { useUpdateUserMessageExpire }
