import { useHistory } from 'react-router'
import { useCallback } from 'react'
import isEmpty from 'lodash-es/isEmpty'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { getSearchParams } from '@tmap-web-lib/utils'
import { PageIdSearchParams } from '../providers/page-id-context'

function useHandleHeaderBack() {
  const history = useHistory()
  const searchParams = getSearchParams<PageIdSearchParams>()
  return useCallback((closeOnFirstHistoryState: boolean, onBack?: () => void) => {
    if (closeOnFirstHistoryState && !isEmpty(searchParams) && JSON.parse(searchParams.extra as string).is_first_history) {
      TmapApp.onBackKeyPressed()
    } else {
      onBack ? onBack() : history.goBack()
    }
  }, [history, searchParams])
}

export {useHandleHeaderBack}