import { useMutation } from '@tanstack/react-query'
import { registerOuterCarWonbu, EscApiBody, RegisterCarWonbuParam } from '@tmap-web-lib/remote-api-client/frontman'
import { AxiosError } from 'axios'

function useRegisterOuterCarWonbu() {
  return useMutation<EscApiBody, AxiosError, RegisterCarWonbuParam>(
    (data) => registerOuterCarWonbu(data).then(({ data }) => data),
  )
}

export { useRegisterOuterCarWonbu }
