import { CSSTransition } from 'react-transition-group'
import { useRef } from 'react'
import { classNames } from '@tmap-web-lib/utils'

interface Props {
  in: boolean
  className?: string
}

function ParticalLoadingIndicator(props: Props) {
  const ref = useRef<HTMLDivElement>(null)

  return (
    <CSSTransition
      classNames="fade-in"
      unmountOnExit={true}
      timeout={200}
      in={props.in}
      nodeRef={ref}
    >
      <div ref={ref} className={classNames('partial_loading_indicator', props.className && props.className)} />
    </CSSTransition>
  )
}

export { ParticalLoadingIndicator }
