import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'
import { getUnpaidTolls } from '../../utils'
import { UbPayUnpaidTolls } from '../../api/types'
import { useSelector } from 'react-redux'
import { selectSettingsMockEnabled } from '../../store/settings'
import { mockHost } from '../../api'

export interface UnpaidTollsParams {
  car_no: string
  user_name: string
  isAgreed: boolean
  isListPage?: boolean
}

function useGetUnpaidTolls(params: UnpaidTollsParams) {
  const { car_no, user_name, isAgreed, isListPage } = params
  const isMockEnabled = useSelector(selectSettingsMockEnabled)

  return useQuery<UbPayUnpaidTolls, AxiosError>([isListPage ? KEYS.UNPAID_TOLLS_LIST() : KEYS.UNPAID_TOLLS()],
    async () => {
      if (isListPage && JSON.parse(isMockEnabled as string)) {
        const { data } = await mockHost.post('/ubpay/expayTmap/API')
        return data
      } else {
        const { data } = await getUnpaidTolls(params)
        return data
      }
    },
    {
      enabled: (!!car_no && !!user_name && isAgreed),
      useErrorBoundary: true, // 고속도로 미납통행은 공통 에러를 사용하지 않음
    },
  )
}

export { useGetUnpaidTolls }