import React, { useCallback, useEffect, useRef, useState } from 'react'
import { DialogBackground, DialogCancelButton, DialogFooter, DialogOkButton } from './Elements'
import { DialogBaseProps } from './types'
import { classNames, getSearchParams } from '@tmap-web-lib/utils'
import { CSSTransition } from 'react-transition-group'
import { useLogBox } from '../../hooks'
import { BENEFIT_REMINDER_LOG_PRESET } from '../../hooks/logbox/preset'
import { PageIdSearchParams } from '../../providers/page-id-context'
import isEmpty from 'lodash-es/isEmpty'

interface Props extends DialogBaseProps {
  alarmImage: string | null
  alarmChecked: boolean
}

function DialogAlarm(props: Props) {
  const { alarmImage, alarmChecked, onCancel, onOk } = props

  const [eventLog, exposeLog] = useLogBox(['event', 'expose'], {
    attributesPreset: BENEFIT_REMINDER_LOG_PRESET.ATTRIBUTES,
    customFieldsPreset: BENEFIT_REMINDER_LOG_PRESET.CUSTOM_FIELDS,
    withEuk: true
  })

  const ref = useRef<HTMLDivElement | null>(null)
  const backgroundRef = useRef(null)
  const [modalIsOpen, setModalIsOpen] = useState(true)

  const handleCancel = useCallback(() => {
    eventLog().set('action_id', 'tap.close').send()
    setModalIsOpen(false)
    onCancel?.()
  }, [onCancel, eventLog])

  const handleConfirm = useCallback((checked: boolean) => {
    eventLog().set('action_id', checked ? 'tap.on_reminder' : 'tap.off_reminder').send()
    // api 연동
    setModalIsOpen(false)
    onOk?.(checked)
  }, [onOk, eventLog])

  useEffect(() => {
    const searchParams = getSearchParams<PageIdSearchParams>()
    if (!isEmpty(searchParams) && searchParams.extra) {
      const extraObject = JSON.parse(searchParams.extra as string)
      const extraCustomFields = extraObject.logbox

      if (extraCustomFields) {
        Object.keys(extraCustomFields).forEach(key => {
          BENEFIT_REMINDER_LOG_PRESET.CUSTOM_FIELDS.push([key, extraCustomFields[key]])
        })
      }
    } else {
      BENEFIT_REMINDER_LOG_PRESET.CUSTOM_FIELDS.push(['type', 'tab'],['origin', 'tab'])
    }

    exposeLog().send()
  }, [exposeLog])

  return (
    <CSSTransition
      classNames="dimmed-fade-in"
      unmountOnExit={true}
      timeout={300}
      in={modalIsOpen}
      nodeRef={backgroundRef}
      appear
    >
      <DialogBackground ref={backgroundRef}>
        <CSSTransition
          classNames="bottom-slide-up"
          unmountOnExit={true}
          timeout={200}
          in={modalIsOpen}
          nodeRef={ref}
          appear
        >
          <div
            ref={ref}
            className={classNames('popup_wrap', 'popup_bottom', 'popup_alarm')}
          >
            <div className="popup_cont">
              {alarmImage && (
                <p className="popup_alarm_view">
                  <img
                    src={alarmImage}
                    className="popup_alarm_view_img"
                    alt=""
                  />
                </p>
              )}
            </div>
            <DialogFooter
              cancelButton={<DialogCancelButton onClick={handleCancel}>닫기</DialogCancelButton>}
              okButton={
                <DialogOkButton
                  variant={alarmChecked ? 'default' : 'primary'}
                  gap={'8px'}
                  styleFontWeight={alarmChecked ? 'normal' : 'medium'}
                  onClick={() => handleConfirm(!alarmChecked)}
                >
                  {alarmChecked ? '알림 끄기' : '알림 받기'}
                </DialogOkButton>
              }
            />
          </div>
        </CSSTransition>
      </DialogBackground>
    </CSSTransition>
  )
}

export default DialogAlarm
export { DialogAlarm }
