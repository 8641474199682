import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { deleteCarMaintenance } from '@tmap-web-lib/remote-api-client/frontman'

interface Params {
  carProfileId: string
}

function useDeleteCarMaintenance() {
  return useMutation<AxiosResponse, AxiosError, Params> (
    ({ carProfileId }) => deleteCarMaintenance(carProfileId),
    {
      useErrorBoundary: true
    }
  )
}

export { useDeleteCarMaintenance }
