import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import { classNames } from '@tmap-web-lib/utils'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'
import { useHandleHeaderBack } from '../../hooks'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { HeaderBackButton } from './HeaderBackButton'
import { store } from '../../store'
import { setHeaderHeight } from '../../store/settings'

interface Props extends PropsWithChildren<{}> {
  title?: string
  closeOnFirstHistoryState?: boolean
  isWhiteIcon?: boolean
  isDefaultType?: boolean
  isForLowSpec?: boolean
  isShow?: boolean
  className?: string
  eventLog?: () => TmapLogBuilder
}

function Header(props: Props) {
  const {
    title,
    closeOnFirstHistoryState = false,
    className = '',
    isWhiteIcon,
    isDefaultType = true,
    isForLowSpec = false,
    isShow = true,
    children,
    eventLog
  } = props

  const ref = useRef<HTMLHeadingElement | null>(null)

  const [isScroll, setScroll] = useState(false)

  const handleHeaderBack = useHandleHeaderBack()
  const handleBackClick = useCallback(() => {
    eventLog?.().set('action_id', 'tap.back').send()
    if (closeOnFirstHistoryState) {
      handleHeaderBack(closeOnFirstHistoryState)
    } else {
      TmapApp.onBackKeyPressed()
    }

  }, [handleHeaderBack, closeOnFirstHistoryState, eventLog])

  useEffect(() => {
    if (!isDefaultType) return

    const onScroll = () => setScroll(window.scrollY > 0)

    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [isDefaultType])

  useEffect(() => {
    if (ref.current && children) {
      store.dispatch(setHeaderHeight(ref.current?.clientHeight || 0))
    }
  }, [children])

  return (
    <header
      ref={ref}
      className={classNames('header', isScroll ? 'sticky' : '', className)}
    >
      {isShow && (
        <HeaderBackButton
          isWhiteIcon={isWhiteIcon}
          onClick={handleBackClick}
          isForLowSpec={isForLowSpec}
        >
          이전 페이지
        </HeaderBackButton>
      )}

      {title && <h1 className='header_title'>{title}</h1>}

      {children}
    </header>
  )
}

export { Header }
