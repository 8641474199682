import { classNames } from '@tmap-web-lib/utils'

export enum ESize {
  LARGE = 'large'
}

interface Props {
  checked: boolean
  size?: ESize
  handleChange: (checked: boolean) => void
}

function Switch(props: Props) {
  const { size, checked = true, handleChange } = props

  return (
    <label className={classNames('form_switch', size)}>
      <input
        type="checkbox"
        className="form_switch_checkbox"
        checked={checked}
        onChange={(e) => handleChange(e.target.checked)}
      />
    </label>
  )
}

export { Switch }
