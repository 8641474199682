import { ForwardedRef, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { SectionCard, ButtonTapHighlight, Tabs, Tab, Button } from '../../components'
import {
  CharanchaMediaList, CharanchaMediaListItem,
  CharanchaMediaListItemType,
} from '@tmap-web-lib/remote-api-client/frontman'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'
import { useOpenService } from '../../hooks'
import { groupBy, isEmpty } from 'lodash-es'
import { gte } from 'semver'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { useTabActivity } from '../Benefit/hooks'

interface Props {
  data?: CharanchaMediaList
  eventLog: () => TmapLogBuilder
}

const MainCharanchaMedia = forwardRef(({ data, eventLog }: Props, ref: ForwardedRef<HTMLElement>) => {
  const openService = useOpenService()
  const { tabRef, handleTabActivity } = useTabActivity()

  const [mediaType, setMediaType] = useState<CharanchaMediaListItemType | null>(null)

  const wrapRef = useRef<HTMLUListElement | null>(null)

  const handleClickTab = useCallback((type: CharanchaMediaListItemType, actionId: string, index: number) => {
    eventLog().set('action_id', actionId).send()
    setMediaType(type)
    handleTabActivity(index)
  }, [eventLog, handleTabActivity])

  const handleClickDetail = useCallback((item: CharanchaMediaListItem) => {
    if (!item.url) return
    eventLog().set('action_id', `tap.content_${item.mediaType.toLowerCase()}`).addCustomField('content_id', item.id.toString()).send()
    openService(item.url)
  }, [openService, eventLog])

  const handleClickMore = useCallback((link: string, actionId: string) => {
    eventLog().set('action_id', actionId).send()
    return openService(link)
  }, [openService, eventLog])

  const mediaGroup = useMemo(() => {
    const media = groupBy(data?._embedded.media, 'mediaType')
    const result = { type: '', title: '', link: '', viewAllActionId: '', categoryActionId: '', list: [] }
    return Object.keys(media).map((key) => {
      if (key === 'TEST_DRIVE') {
        result.title = '시승기'
        if (gte(TmapApp.env.appVersion, '10.5.1')) {
          result.link = 'tmap://webview?service=carsale&id=9645585&params=page%3D1%26categoryCd%3D02'
        } else {
          result.link = 'https://charancha.com/media/list?categoryCd=02'
        }
        result.viewAllActionId = 'tap.view_all_test_drive'
        result.categoryActionId = 'tap.content_category_test_drive'
      } else if (key === 'ISSUE') {
        result.title = '이슈&테마'
        if (gte(TmapApp.env.appVersion, '10.5.1')) {
          result.link = 'tmap://webview?service=carsale&id=9645585&params=page%3D1%26categoryCd%3D03'
        } else {
          result.link = 'https://charancha.com/media/list?categoryCd=03'
        }
        result.viewAllActionId = 'tap.view_all_issue'
        result.categoryActionId = 'tap.content_category_issue'
      } else if (key === 'NEWS') {
        result.title = '뉴스'
        if (gte(TmapApp.env.appVersion, '10.5.1')) {
          result.link = 'tmap://webview?service=carsale&id=9645585&params=page%3D1%26categoryCd%3D01'
        } else {
          result.link = 'https://charancha.com/media/list?categoryCd=01'
        }
        result.viewAllActionId = 'tap.view_all_news'
        result.categoryActionId = 'tap.content_category_news'
      } else if (key === 'COLUMN') {
        result.title = '칼럼'
        if (gte(TmapApp.env.appVersion, '10.5.1')) {
          result.link = 'tmap://webview?service=carsale&id=9645585&params=page%3D1%26categoryCd%3D04'
        } else {
          result.link = 'https://charancha.com/media/list?categoryCd=04'
        }
        result.viewAllActionId = 'tap.view_all_column'
        result.categoryActionId = 'tap.content_category_column'
      }
      return { ...result, type: key, list: media[key] }
    })
  }, [data])

  const media = useMemo(() => {
    return mediaGroup.find((item) => item.type === mediaType)
  }, [mediaGroup, mediaType])

  useEffect(() => {
    if (!isEmpty(mediaGroup) && !mediaType) {
      setMediaType(mediaGroup[0].type as CharanchaMediaListItemType)
    }
  }, [mediaGroup, mediaType])

  useEffect(() => {
    if (!mediaType || !wrapRef.current) return
    wrapRef.current?.scrollTo(0, 0)
  }, [mediaType, wrapRef])

  if (!media || !mediaType) return null

  return (
    <SectionCard
      title={'카라이프 미디어'}
      id="main_charancha_media"
      ref={ref}
    >
      <Tabs
        ref={tabRef}
        variant={'capsule'}
      >
        {mediaGroup.map((item, index) => {
          return (
            <Tab
              key={item.type}
              isActive={item.type === mediaType}
              onClick={() => handleClickTab(item.type as CharanchaMediaListItemType, item.categoryActionId, index)}
            >
              {item.title}
            </Tab>
          )
        })}
      </Tabs>
      <ul
        className="main_charancha"
        ref={wrapRef}
      >
        {media.list.map((item) => {
          return (
            <li
              key={item.id}
              className="main_charancha_item"
            >
              <ButtonTapHighlight onClick={() => handleClickDetail(item)}>
                <div
                  className="main_charancha_item_thumb"
                  style={{ background: `url(${item.thumbnailImg}) no-repeat 50% / cover` }}
                />
                <p className="main_charancha_item_title">{item.title}</p>
              </ButtonTapHighlight>
            </li>
          )
        })}
      </ul>
      <Button
        variant={'default'}
        styleWidth={`calc(100% - 40px)`}
        styleHeight={52}
        styleMargin={'0 20px 20px'}
        borderRadius={'6px'}
        onClick={() => handleClickMore(media.link, media?.viewAllActionId)}
      >
        {media.title} 전체보기
      </Button>
    </SectionCard>
  )
})

export { MainCharanchaMedia }
