import React from 'react'
import styled from '@emotion/styled/dist/emotion-styled.cjs'

function MainHeaderSkeleton() {
  return (
    <div className="main_header">
      <div className="main_score">
        <Skeleton style={{ width: '72px', height: '16px', marginBottom: '4px' }}/>
        <Skeleton style={{ width: '72px', height: '54px', marginBottom: '4px' }}/>
        <Skeleton style={{ width: '80px', height: '36px', marginBottom: '4px' }}/>
      </div>
      <div className="main_vehicle">
        <ImageSkeleton src={require('@/assets/images/img_top_car_skeleton.svg').default} className="main_vehicle_detail" alt=""/>
      </div>
      <div className="main_menu">
        <Skeleton className="main_menu_item"/>
        <Skeleton className="main_menu_item"/>
        <Skeleton className="main_menu_item"/>
        <Skeleton className="main_menu_item"/>
      </div>
    </div>
  )
}

export { MainHeaderSkeleton }

const Skeleton = styled.div`
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.04) 100%);
  animation: blink 2s ease-in-out infinite;
  transition: opacity ease-in-out 0.3s;

  @keyframes blink {
    50% {
      opacity: 0.3;
    }
  }
`

const ImageSkeleton = styled.img`
  animation: blink 2s ease-in-out infinite;
  transition: opacity ease-in-out 0.3s;

  @keyframes blink {
    50% {
      opacity: 0.3;
    }
  }
`
