import { PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'

type Props = PropsWithChildren<{
  rootElementSelector: string;
}>

const containerId = 'snackbar-portal'

let containerElement: HTMLElement | null

function ToastPortal(props: Props) {
  const { rootElementSelector, children } = props

  if (!containerElement) {
    const rootElement = document.querySelector(rootElementSelector)
    containerElement = document.createElement('div')
    containerElement.id = containerId
    document.body.insertBefore(containerElement, rootElement)
  }

  return createPortal(children, containerElement)
}

export { ToastPortal }
