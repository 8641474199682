import React, { Suspense, useLayoutEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { RoutePath } from './paths'
import { lazyRetry } from '../utils/lazy-retry'
import { store } from '../store'
import { setIsSkeleton } from '../store/settings'

const History = lazyRetry(() => import('../pages/History/History'))
const HistoryDetail = lazyRetry(() => import('../pages/History/detail/Detail'))

function SwitchHistoryPages() {

  useLayoutEffect(() => {
    if (window.location.href.indexOf(RoutePath.History) !== -1) {
      store.dispatch(setIsSkeleton(true))
    }
  }, [])

  return (
    <Suspense fallback={''}>
      <Switch>
        <Route path={RoutePath.History} exact>
          <History/>
        </Route>
        <Route path={RoutePath.History_detail} exact>
          <HistoryDetail/>
        </Route>
        <Redirect to={RoutePath.Home} from="*"/>
      </Switch>
    </Suspense>
  )
}

export { SwitchHistoryPages }
