import { SectionCard } from '../../components/Section'
import { SkeletonContent } from '../../components'
import React from 'react'

function MainSkeleton() {
  return (
    <>
      <SectionCard minHeight={64}>
        <div className="main_skeleton">
          <SkeletonContent style={{ width: '100px', height: '20px' }}/>
        </div>
      </SectionCard>
      <SectionCard minHeight={187}>
        <div className="main_skeleton">
          <SkeletonContent style={{ width: '160px', height: '20px' }}/>
          <SkeletonContent style={{ width: '100px', height: '20px', marginTop: '16px' }}/>
        </div>
      </SectionCard>
      <SectionCard minHeight={187}>
        <div className="main_skeleton">
          <SkeletonContent style={{ width: '160px', height: '20px' }}/>
          <SkeletonContent style={{ width: '100px', height: '20px', marginTop: '16px' }}/>
        </div>
      </SectionCard>
      <SectionCard minHeight={187}>
        <div className="main_skeleton">
          <SkeletonContent style={{ width: '160px', height: '20px' }}/>
          <SkeletonContent style={{ width: '100px', height: '20px', marginTop: '16px' }}/>
        </div>
      </SectionCard>
      <SectionCard minHeight={187}>
        <div className="main_skeleton">
          <SkeletonContent style={{ width: '160px', height: '20px' }}/>
          <SkeletonContent style={{ width: '100px', height: '20px', marginTop: '16px' }}/>
        </div>
      </SectionCard>
    </>
  )
}

export { MainSkeleton }
