import { useCallback } from 'react'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { store } from '../store'
import { selectSettingsAccessKey, setAccessKey, setDevToolsAccessKey } from '../store/settings'
import { useSelector } from 'react-redux'

function useGetNewAccessKey() {
  const oldAccessKey = useSelector(selectSettingsAccessKey)

  return useCallback(async () => {
    return await TmapApp.getAccessKey()
      .then(newAccessKey => {
        // 새로 받아온 ak가 빈 값일 경우 갱신하지 않도록 적용
        if (newAccessKey) {
          if (store.getState().settings.accessKey !== newAccessKey) {
            TmapApp.updateAccessKey({ key: newAccessKey })
            store.dispatch(setAccessKey(newAccessKey))
          }
          if (store.getState().settings.devToolsAccessKey) {
            store.dispatch(setDevToolsAccessKey(newAccessKey))
          }
          return newAccessKey
        } else {
          return oldAccessKey
        }
      })
  }, [oldAccessKey])
}

export { useGetNewAccessKey }
