import React, { forwardRef, HTMLAttributes, memo, PropsWithChildren, useEffect } from 'react'
import { classNames, fixScroll, unfixScroll } from '@tmap-web-lib/utils'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'

interface Props extends HTMLAttributes<HTMLDivElement> {}

const DialogBackground = memo(forwardRef<HTMLDivElement, Props>(
  function DialogBackground({ children, className, ...attrs }: PropsWithChildren<Props>, ref) {
    useEffect(() => {
      if (TmapApp.env.isIOS) {
        fixScroll('fix_scroll')
      }
      return () => unfixScroll('fix_scroll')
    }, [])

    return <div ref={ref} className={classNames("layer_wrap", className)} {...attrs}>{children}</div>
  }
))

export { DialogBackground }
