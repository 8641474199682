import { useGetLoginMethod } from '../../../hooks/use-get-login-method'
import { useMemo } from 'react'

function useTidLogin() {
  const { loginMethod } = useGetLoginMethod()

  return useMemo(() => {
    return loginMethod === 'TID'
  }, [loginMethod])
}

export default useTidLogin
