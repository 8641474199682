import React, { memo, ReactNode, useEffect } from 'react'
import { fixScroll, unfixScroll } from '@tmap-web-lib/utils'
import { classNames } from '@tmap-web-lib/utils'
import { DialogBackground } from './DialogBackground'
import { DialogType } from "../types";

interface Props {
  type?: DialogType
  content: ReactNode;
  buttonSet?: ReactNode;
  className?: string;
}

const DialogFrame = memo<Props>(function Popup(props) {
  const { content, buttonSet, className='', type = 'CENTER' } = props

  useEffect(() => {
    fixScroll('fix_scroll')
    return () => unfixScroll('fix_scroll')
  }, [])

  return (
    <DialogBackground>
      <div className={classNames('popup_wrap', type === 'FULL' ? 'popup_full' : type === 'BOTTOM' ? 'popup_bottom' : '', className)}>
        <div className={classNames('popup_cont', type === 'FULL' ? 'cont_centered' : '')}>
          {content}
          {buttonSet}
        </div>
      </div>
    </DialogBackground>
  )
})

export { DialogFrame }
