import { ButtonHTMLAttributes, PropsWithChildren, useMemo } from 'react'
import { classNames } from '@tmap-web-lib/utils'
import { ButtonTapHighlight } from '../../Button'

interface Props extends PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> {
  variant?: 'stroke' | 'stroke-small' | 'solid' | 'solid-small'
  isActive: boolean
  onClick: () => void
}

function Tab(props: Props) {
  const {
    variant = 'stroke',
    isActive,
    children,
    onClick,
  } = props

  const radius = useMemo(() => {
    return variant.indexOf('small') ? 34 : 40
  }, [variant])

  return (
    <li className="tmds-tab_panel">
      <ButtonTapHighlight
        borderRadius={radius}
        className={classNames('tmds-tab_button', variant, isActive ? 'active' : undefined)}
        onClick={onClick}
      >
        {children}
      </ButtonTapHighlight>
    </li>
  )
}

export { Tab }