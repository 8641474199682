import React, { ButtonHTMLAttributes, PropsWithChildren } from 'react'
import { Button } from '../../Button'

interface Props extends PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> {
  variant?: 'default' | 'primary' | 'simple'
  gap?: string
  borderRadius?: string
  styleFontWeight?: 'normal' | 'medium' | 'bold'
}

function DialogOkButton(props: Props) {
  const { variant, gap, borderRadius, styleFontWeight, children, ...restProps } = props
  return (
    <Button
      {...restProps}
      variant={variant || 'primary'}
      styleHeight={52}
      borderRadius={borderRadius}
      gap={gap}
      styleFontWeight={styleFontWeight}
    >
      {children ?? '확인'}
    </Button>
  )
}

export { DialogOkButton }
