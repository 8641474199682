import styled from '@emotion/styled'
import { ButtonHTMLAttributes, PropsWithChildren } from 'react'
import { css } from '@emotion/react'
import { ButtonTapHighlight } from '../Button'
import IcoArrow from '../../assets/images/ico_arrow_right_bold.svg'

type BandBannerStyleType = {
  maxHeight?: number
  marginTop?: number
  borderRadius?: number
  isArrow?: boolean
  bgColor?: string
  fontColor?: string
  fontWeight?: string
  align?: 'flex-end' | 'center' | 'space-between'
  direction?: 'row' | 'row-reverse'
}

interface BandBannerType extends BandBannerStyleType, PropsWithChildren<ButtonHTMLAttributes<HTMLDivElement>> {
  title: string
}

function BandBanner(props: BandBannerType) {
  const {
    maxHeight,
    marginTop = 32,
    borderRadius = 8,
    isArrow,
    bgColor,
    fontColor,
    fontWeight,
    align = 'space-between',
    direction = 'row',
    title,
    children,
    ...attrs
  } = props

  return (
    <Banner
      maxHeight={maxHeight}
      marginTop={marginTop}
      borderRadius={borderRadius}
      isArrow={isArrow}
      bgColor={bgColor}
      fontColor={fontColor}
      fontWeight={fontWeight}
      align={align}
      direction={direction}
      {...attrs}
    >
      <ButtonTapHighlight
        borderRadius={borderRadius}
      >
        {title}
        {children}
      </ButtonTapHighlight>
    </Banner>
  )
}

export { BandBanner }

const Banner = styled.div<BandBannerStyleType>`
  margin-top: ${({ marginTop }) => marginTop}px;
  
  button {
    display: flex;
    flex-direction: ${({ direction }) => direction};
    align-items: center;
    justify-content: ${({ align }) => align};
    position: relative;
    ${({ maxHeight }) => maxHeight && css`max-height: ${maxHeight}px`};
    padding: 16px 20px;
    border-radius: ${({ borderRadius }) => borderRadius}px;
    ${({ bgColor }) => bgColor && css`background: ${bgColor}`};
    ${({ fontColor }) => fontColor && css`color: ${fontColor}`};
    ${({ fontWeight }) => fontWeight && css`fontWeight: ${fontWeight}`}
    
    ${({ isArrow }) => isArrow && css`
      &::before {
        content: '';
        position: absolute;
        top: calc(50% - 8px);
        right: 20px;
        width: 16px;
        height: 16px;
        background: url(${IcoArrow}) no-repeat 0 0 / 100%;
      }
    `}
    .ico {
      margin-right: 16px;
    }
  }
`
