import React, { ReactNode } from 'react'

interface Props {
  title: ReactNode;
  content?: ReactNode;
}

function DialogContent({ title, content }: Props) {
  return (
    <>
      <div className="popup_title">
        {title}
      </div>
      {content &&
        <div className="popup_content">
          {content}
        </div>
      }
    </>
  )
}

export { DialogContent }
