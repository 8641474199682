import React, { ButtonHTMLAttributes, PropsWithChildren } from 'react'
import { Button } from '../../Button'

interface Props extends PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> {
}

function DialogRefreshButton(props: Props) {
  const { children, ...restProps } = props
  return (
    <Button
      {...restProps}
      styleWidth={'auto'}
      styleHeight={44}
      variant={'simple'}
    >
      {children ?? '새로고침'}
    </Button>
  )
}

export { DialogRefreshButton }
