import React, { ReactNode } from 'react'
import { classNames } from '@tmap-web-lib/utils'

interface Props {
  title?: ReactNode;
  content?: ReactNode;
  className?: string;
}

function DialogCustom({ title, content, className }: Props) {
  return (
    <div className={classNames('popup_subject', className)}>
      {title && <div className="popup_message">{title}</div>}
      {content &&
        <div className="popup_content">
          {content}
        </div>
      }
    </div>
  )
}

export { DialogCustom }
