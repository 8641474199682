import { useMutation } from '@tanstack/react-query'
import {
  updateEventJoinStatus,
  UpdateEventJoinStatusRequestType,
} from '@tmap-web-lib/remote-api-client/frontman'
import { AxiosError, AxiosResponse } from 'axios'

function useUpdateEventJoinStatus() {
  return  useMutation<AxiosResponse, AxiosError, UpdateEventJoinStatusRequestType>(
    (data) => updateEventJoinStatus(data)
  )
}

export { useUpdateEventJoinStatus }
