import { useQuery } from '@tanstack/react-query'
import { UserRanks, getUserRanks } from '@tmap-web-lib/remote-api-client/frontman'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'
import { mockHost } from '../../api'
import { useSelector } from 'react-redux'
import { selectSettingsMockEnabled } from '../../store/settings'

interface Options {
  enabled?: boolean
  useErrorBoundary?: boolean
}

function useGetUserRanks(options?: Options) {
  const isMockEnabled = useSelector(selectSettingsMockEnabled)

  return useQuery<UserRanks, AxiosError>([KEYS.USER_RANKS()],
    async () => {
      if (JSON.parse(isMockEnabled as string)) {
        const { data } = await mockHost.get<UserRanks>('/driving-score/report/ranks/$userKey')
        return data
      } else {
        const { data } = await getUserRanks()
        return data
      }
    },
    {
      ...options,
      refetchOnMount: 'always',
    },
  )
}

export { useGetUserRanks }
