import { useCallback } from 'react'
import { formatFloorKilometers } from '../../../utils'

interface Props {
  score: number
  totalTripDistance: number
  minimumScore: number
  minimumTotalMileage: number
}

function useAccessibleDetailsConditions({ score, totalTripDistance, minimumScore, minimumTotalMileage }: Props) {
  return useCallback(() => {
    if (!minimumScore) return null
    if ((minimumScore > score) || (minimumTotalMileage > totalTripDistance)) {
      let description = ''
      if ((minimumScore > score) && (minimumTotalMileage <= totalTripDistance)) {
        description = `${minimumScore - score}점 올리면 혜택을 받을 수 있어요.`
      } else if ((minimumScore <= score) && (minimumTotalMileage > totalTripDistance)) {
        description = `${formatFloorKilometers(minimumTotalMileage - totalTripDistance)} 더 운전을 하면 혜택을 받을 수 있어요.`
      } else if ((minimumScore > score) && (minimumTotalMileage > totalTripDistance)) {
        description = `${minimumScore - score}점 올리고, ${formatFloorKilometers(minimumTotalMileage - totalTripDistance)} 더 운전을 하면 혜택을 받을 수 있어요.`
      }
      return description
    }
  }, [score, totalTripDistance, minimumScore, minimumTotalMileage])
}

export { useAccessibleDetailsConditions }
