import { SectionCard } from '../../components'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'
import { ButtonTapHighlight } from '../../components'
import React, { useCallback, useEffect } from 'react'
import { useOpenService } from '../../hooks'
import { formatCommaNumber } from '@tmap-web-lib/utils'
import isEmpty from 'lodash-es/isEmpty'
import { formatMileage } from '../../utils'
import {
  CharanchaCar,
  CharanchaCarResData,
  CharanchaPopularCar,
  CharanchaPopularCarResData,
} from '@tmap-web-lib/remote-api-client/frontman'
import { useHorizontalScrollingLogs } from './hooks'
import { useInView } from 'react-intersection-observer'

const STG_POPULAR_MORE_URL = 'tmap://webview?service=carsale&id=1&params=redirectUri%3DL2J1L2V4aC92aWV3P2V4aGliaXRpb25Obz0xMDImc29ydD1TRUxMX1NUQVJUX0RULERFU0MmaXNGcm9tVG1vYmlMaXN0PXRydWU%3D&top=true'
const PRD_POPULAR_MORE_URL = 'tmap://webview?service=carsale&id=1&params=redirectUri%3DL2J1L2V4aC92aWV3P2V4aGliaXRpb25Obz04MSZzb3J0PVNFTExfU1RBUlRfRFQsREVTQyZpc0Zyb21UbW9iaUxpc3Q9dHJ1ZQ%3D%3D&top=true'

type GoodsType = 'RECENT' | 'RECOMMEND'

interface Props {
  data: CharanchaCarResData | CharanchaPopularCarResData | undefined
  type: GoodsType
  inactive?: boolean
  eventLog: () => TmapLogBuilder
}

function MainCharanchaCarList({ data, type, inactive, eventLog }: Props) {
  const openService = useOpenService()
  const { ref, inView, entry } = useInView({
    threshold: 1,
    triggerOnce: true,
  })
  const handleHorizontalScroll = useHorizontalScrollingLogs({
    eventLog,
    actionId: type === 'RECENT' ? 'swipe.car_recent_view' : 'swipe.car_recommended',
    isMainPageExposureArea: true
  })

  const handleClickDetail = useCallback((item: CharanchaCar | CharanchaPopularCar) => {
    if (!item.carDetailViewUrl) return
    if (type === 'RECENT') {
      eventLog().set('action_id', 'tap.car_recent_view').addCustomField('sellNo', item.sellNo).send()
    } else {
      eventLog().set('action_id', 'tap.car_recommended').send()
    }
    openService(item.carDetailViewUrl)
  }, [type, openService, eventLog])

  const handleClickMore = useCallback(() => {
    openService(process.env.REACT_APP_BUILD_ENV === 'PRD' ? PRD_POPULAR_MORE_URL : STG_POPULAR_MORE_URL)
    eventLog().set('action_id', 'tap.car_recommended_view_all').send()
  }, [openService, eventLog])

  useEffect(() => {
    if (inView) {
      const actionId = entry?.target.getAttribute('data-log') || ''
      eventLog().set('action_id', actionId).send()
    }
  }, [inView, entry, eventLog])

  if (isEmpty(data) || inactive) return null

  return (
    <SectionCard
      title={type === 'RECENT' ? '최근 본 매물' : '인기 매물'}
      isMore={type === 'RECOMMEND'}
      handleClickMore={() => (type === 'RECOMMEND') && handleClickMore()}
      data-log={type === 'RECENT' ? 'view.car_recent_view' : 'view.car_recommended'}
      ref={ref}
    >
      <ul
        className="main_charancha car"
        onScroll={(el) => handleHorizontalScroll(el)}
      >
        {data?._embedded.cars.map((item) => {
          return (
            <li
              key={item.sellNo}
              className="main_charancha_item"
            >
              <ButtonTapHighlight onClick={() => handleClickDetail(item)}>
                <div
                  className="main_charancha_item_thumb"
                  style={{ background: `url(${item.carImg}) no-repeat 50% / cover` }}
                />
                <p className="main_charancha_item_title">{item.carNm}</p>
                <p className="main_charancha_item_detail">
                  {item.releaseDt.slice(0, 4)}년식•{formatMileage(item.mileage)}
                </p>
                <em className="main_charancha_item_price">{formatCommaNumber(item.sellPrice)}만원</em>
              </ButtonTapHighlight>
            </li>
          )
        })}
      </ul>
    </SectionCard>
  )
}

export { MainCharanchaCarList }
