import React, { ForwardedRef, forwardRef, HTMLAttributes, PropsWithChildren } from 'react'
import { CSSTransition } from 'react-transition-group'
import { ToastPortal } from '../ToastPortal'


interface SnackbarProps extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  isToastVisible: boolean;
}

const Snackbar = forwardRef((props: SnackbarProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { isToastVisible, children } = props

  return (
    <ToastPortal rootElementSelector={'#root'}>
      <CSSTransition
        classNames="slide-up"
        unmountOnExit={true}
        timeout={400}
        in={isToastVisible}
        nodeRef={ref}
        appear
      >
        <div ref={ref} className="toast_wrap snackbar">
          <div className="snackbar_cont">
            <div className="snackbar_subject">
              {children}
            </div>
          </div>
        </div>
      </CSSTransition>
    </ToastPortal>
  )
  }
)
export { Snackbar }