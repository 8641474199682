import { LogBoxAttributes, TmapLogBuilder, TmapLogType } from '@tmap-web-lib-close/logbox-client'
import { useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { selectSettingsEuk } from '../../store/settings'
import mixpanel from 'mixpanel-browser'

class TmapMixLog extends TmapLogBuilder {
  send() {
    const action_id = this.get('action_id')
    const event = `${this.get('service_id')}:${this.get('page_id')}${action_id ? ':'+ action_id : ''}`
    const customs = this.get('customFieldAllJSONObject')
    mixpanel.track(event, customs)
    return super.send()
  }
}

function useLogBox<Types extends TmapLogType[]>(
  types: Types,
  options: {
    attributesPreset?: { [Prop in LogBoxAttributes]?: string },
    customFieldsPreset?: [name: string, value: string][],
    withEuk?: boolean
  }
) {
  const typesRef = useRef(types)
  const optionsRef = useRef(options)
  const euk = useSelector(selectSettingsEuk)

  return useMemo(() => {
    const loggers = []

    for (const type of typesRef.current) {
      const factory = () => {
        const logger = new TmapMixLog(type)
        if (optionsRef.current.attributesPreset) {
          Object.keys(optionsRef.current.attributesPreset).forEach(key => {
            logger.set(key as LogBoxAttributes, optionsRef.current.attributesPreset![key as LogBoxAttributes])
          })
        }
        optionsRef.current.customFieldsPreset?.forEach(field => {
          logger.add('customField', field)
        })
        if (optionsRef.current.withEuk) {
          logger.setEuk(euk)
        }
        return logger
      }
      loggers.push(factory)
    }

    return loggers
  }, [euk])
}

export { useLogBox }
