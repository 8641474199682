import styled from '@emotion/styled'
import { HTMLAttributes } from 'react'

interface Props extends HTMLAttributes<HTMLDivElement> {}

function SkeletonContent(props: Props) {
  const { style } = props
  return <Skeleton style={style} />
}

export { SkeletonContent }

const Skeleton = styled.div`
  background: linear-gradient(to right, var(--C-wb-transparent-300), var(--C-wb-transparent-100));
  animation: blink 2s ease-in-out infinite;
  transition: opacity ease-in-out 0.3s;

  @keyframes blink {
    50% {
      opacity: 0.3;
    }
  }
`
