import { isLocalEnv } from '@tmap-web-lib/utils'
import {
  STORAGE_KEY_LIFE_DEV_TOOL_ACCESS_KEY,
  STORAGE_KEY_LIFE_STORE_ACCESS_KEY,
} from './storage-keys'

export function pickAccessKey(defaultAccessKey?: string) {
  let accessKey = defaultAccessKey

  if (localStorage.getItem(STORAGE_KEY_LIFE_DEV_TOOL_ACCESS_KEY)) {
    accessKey = localStorage.getItem(STORAGE_KEY_LIFE_DEV_TOOL_ACCESS_KEY) || accessKey
  }

  if (isLocalEnv() && !accessKey) {
    accessKey = localStorage.getItem(STORAGE_KEY_LIFE_STORE_ACCESS_KEY) || accessKey
  }
  return accessKey || ''
}
