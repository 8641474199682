import { lazyRetry } from '../utils/lazy-retry'
import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { RoutePath } from './paths'

const Settings = lazyRetry(() => import('../pages/Settings/Settings'))
const Otp = lazyRetry(() => import('../pages/Settings/Otp'))
const TermsWithdrawal = lazyRetry(() => import('../pages/Settings/TermsWithdrawal'))

function SwitchSettingsPages() {
  return (
    <Suspense fallback="">
      <Switch>
        <Route path={RoutePath.Settings} exact>
          <Settings/>
        </Route>
        <Route path={RoutePath.Settings_otp} exact>
          <Otp/>
        </Route>
        <Route path={RoutePath.Settings_withdrawal} exact>
          <TermsWithdrawal/>
        </Route>
        <Redirect to={RoutePath.Home} from="*"/>
      </Switch>
    </Suspense>
  )

}

export { SwitchSettingsPages }