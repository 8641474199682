import { useCallback, useEffect, useRef } from 'react'

type IntersectHandler = (
  entry: IntersectionObserverEntry,
  observer: IntersectionObserver
) => void

function useIntersectionObserver(onIntersect: IntersectHandler, options?: IntersectionObserverInit, isDisconnect?: boolean) {
  const ref = useRef<HTMLElement[]>([])
  const disconnect = isDisconnect || true

  const callback = useCallback((entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        onIntersect(entry, observer)
      }
    })
  }, [onIntersect])

  useEffect(() => {
    if (!ref.current || !ref.current[0]) return
    const observer = new IntersectionObserver(callback, options)
    ref.current.forEach((el) => {
      observer.observe(el)
    })
    return () => disconnect && observer.disconnect()
  }, [ref, options, disconnect, callback])

  return { ref }
}

export { useIntersectionObserver }
