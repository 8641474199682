import { AxiosPromise } from 'axios'
import { UbPayUnpaidTolls, UbPayUnpaidTollsEncData } from '../api/types'
import {
  getUbpayDecryptData,
  getUbpayEncryptData,
  getUbpayUnpaidTollData,
} from '@tmap-web-lib/remote-api-client/frontman'

function getUnpaidTolls({
  car_no,
  user_name,
} :{
  car_no: string;
  user_name: string;
}): AxiosPromise<UbPayUnpaidTolls> {
  return getUbpayEncryptData({
    car_no,
    user_name,
    req_type: '0011',
    hid: process.env.REACT_APP_UBPAY_CLIENT_ID as string,
  }).then(res => {
    return getUbpayUnpaidTollData({
      c_id: 'EXTMAP-001',
      var: '01',
      encData: res.data.result,
      })
  }).then(res => {
    const resData: UbPayUnpaidTollsEncData = JSON.parse(decodeURIComponent(res.data))
    return getUbpayDecryptData(resData.encData)
  })
}

export { getUnpaidTolls }
