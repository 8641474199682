import { ButtonTapHighlight } from '../../components'
import React, { useCallback } from 'react'
import { CARPROFILE_SCHEME } from '../../utils'
import { useOpenService } from '../../hooks'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'

interface Props {
  eventLog: () => TmapLogBuilder
}

function MainCarInfoNone({ eventLog }: Props) {
  const openService = useOpenService()

  const handleRegisterClick = useCallback((actionId: string) => {
    const extra = {
      serviceName: 'life',
      logbox: {
        type: 'tmap',
        origin: 'life'
      },
      action: {
        actionType: 'close'
      },
      pageType: [
        'manual-input-off'
      ]
    }
    const url = `${CARPROFILE_SCHEME}?pageid=register_car_profile&extra=${encodeURIComponent(JSON.stringify(extra))}`
    eventLog().set('action_id', actionId).send()
    openService(url)
  }, [openService, eventLog])

  return (
    <>
      <div className="main_vehicle">
        <ButtonTapHighlight
          className="main_vehicle_detail"
          isFullWidth={false}
          onClick={() => handleRegisterClick('tap.register_myCar_image')}
        >
          <img
            src={require('../../assets/images/img_top_car_add.svg').default}
            width="200"
            height="120"
            alt=""
          />
        </ButtonTapHighlight>
      </div>
      <div className="main_vehicle_desc">
        <p>차량을 등록하면 정보를 불러와요</p>
        <ButtonTapHighlight
          className="main_vehicle_desc_btn"
          onClick={() => handleRegisterClick('tap.register_myCar')}
          isFullWidth={false}
        >
          <img
            src={require('../../assets/images/ico_plus.svg').default}
            width="16"
            height="16"
            alt=""
          />
          내 차 등록하기
        </ButtonTapHighlight>
      </div>
    </>
  )
}

export { MainCarInfoNone }
