import React, { memo, useMemo } from 'react'
import { formatFloorKilometers, formatTripTimeDiff, tmapDateFormatter } from '../../utils'
import { classNames } from '@tmap-web-lib/utils'
import styles from '../../styles/modules/Driving.list.module.scss'
import { shuffle } from 'lodash-es'
import { safeDrivingFeedback } from '../../pages/History/static/driving'
import { TripsListTripType } from '@tmap-web-lib/remote-api-client/frontman'

interface Props {
  tripSafeDrivingInfo: TripsListTripType
  simple?: boolean // 목록 하나만 노출하는 경우 (예: 메인 등)
}

const Trip = memo((props: Props) => {
  const { simple = false, tripSafeDrivingInfo } = props
  const {
    startDateTime,
    endDateTime,
    mileageMeters,
    destination,
    violation,
  } = tripSafeDrivingInfo

  const {name} = destination
  const {speedingMileageMeters, rapidDecelerationCount, rapidAccelerationCount} = violation

  const renderUnsafeDriving = useMemo(() => {
    const arr = []
    if (speedingMileageMeters > 0) arr.push(`과속 ${formatFloorKilometers(speedingMileageMeters)}`)
    if (rapidAccelerationCount > 0) arr.push(`급가속 ${rapidAccelerationCount}회`)
    if (rapidDecelerationCount > 0) arr.push(`급감속 ${rapidDecelerationCount}회`)
    return `${arr.join(', ')}`
  }, [speedingMileageMeters, rapidAccelerationCount, rapidDecelerationCount])

  return (
    <div className={classNames(styles.item_info, simple ? styles.simple : undefined)}>
      {simple ? (
        <>
          <p className={styles.item_info_dest}>{name}</p>
          <p className={styles.item_info_detail}>{tmapDateFormatter(startDateTime).toFormat('MM월 dd일')}</p>
        </>
      ) : (
        <>
          <p className={styles.item_info_dest}>{name}</p>
          <p className={styles.item_info_detail}>
            {tmapDateFormatter(startDateTime).toFormat(simple ? 'MM월 dd일 HH:mm' : 'HH:mm')} 출발&nbsp;
            <span>|</span>&nbsp;
            {formatFloorKilometers(mileageMeters)}&nbsp;<span>|</span>&nbsp;
            {formatTripTimeDiff(startDateTime, endDateTime)} 소요
          </p>
        </>
      )}
      <p className={styles.item_info_alert}>
        <em className={classNames(styles.item_info_alert_msg, !!renderUnsafeDriving ? styles.warning : undefined)}>
          {renderUnsafeDriving ? `${renderUnsafeDriving}` : shuffle(safeDrivingFeedback)[0]}
        </em>
      </p>
    </div>
  )
})

export { Trip }
